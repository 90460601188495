// Import packages
import React, {Component} from "react";
import debounce from 'lodash.debounce';

// Import styles
import "../../assets/styles/components/uiElements/tabs.scss";
import {ReactComponent as ArrowIcon} from '../../assets/images/icons/ic_white_right_arrow.svg';

class CustomTabs extends Component {

    constructor() {
        super();
        this.state = {
            transfer: 0,
            hideNextButton: true,
            hidePrevButton: true,
            stateAllTabActive: false,
        };
        this.scrollSize = 300;
        this.tabsWrapperRef = React.createRef();
        this.tabsList = React.createRef();
        this.nextTab = this.nextTab.bind(this);
        this.prevTab = this.prevTab.bind(this);
        this.initTabs = this.initTabs.bind(this);
        this.initTabsDebounced = debounce(this.initTabs, 250);
    }

    componentDidMount() {
        this.listWrapperWidth = this.tabsWrapperRef?.current?.clientWidth;
        this.listWidth = this.tabsList?.current?.clientWidth;
        // console.log("this.listWidth",this.listWidth)
        // console.log("this.listWrapperWidth",this.listWrapperWidth)
        if (this.listWidth > this.listWrapperWidth) {
            this.setState({
                hideNextButton: false,
            })
            this.scrollWith = this.listWidth - this.listWrapperWidth;
            window.addEventListener('resize', this.initTabsDebounced)
        }
        // console.log(this.listWrapperWidth)
        // console.log(this.listWidth)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.initTabsDebounced);
        this.initTabsDebounced.cancel();
    }

    initTabs() {
        this.listWrapperWidth = this.tabsWrapperRef?.current?.clientWidth;
        this.listWidth = this.tabsList?.current?.clientWidth;
        this.tabsList && this.tabsList.current && (this.tabsList.current.style.transform = `translateX(0px)`);
        if (this.listWidth > this.listWrapperWidth) {
            this.setState({
                hideNextButton: false,
                hidePrevButton: true
            })
            this.scrollWith = this.listWidth - this.listWrapperWidth;
        }
        // console.log(this.listWrapperWidth)
        // console.log(this.listWidth)
    }

    nextTab() {
        const {transfer} = this.state;
        if (transfer + this.scrollSize < this.scrollWith) {
            const newTransfer = transfer + this.scrollSize;
            this.tabsList.current.style.transform = `translateX(${-newTransfer}px)`;
            this.setState({
                transfer: newTransfer,
                hidePrevButton: false,
            })
        } else {
            const newTransfer = this.scrollWith;
            this.tabsList.current.style.transform = `translateX(${-newTransfer}px)`;
            this.setState({
                transfer: newTransfer,
                hideNextButton: true,
                hidePrevButton: false,
            })
        }
    }

    prevTab() {
        const {transfer} = this.state;
        if (transfer - this.scrollSize > 0) {
            const newTransfer = transfer - this.scrollSize;
            this.tabsList.current.style.transform = `translateX(${-newTransfer}px)`;
            this.setState({
                transfer: newTransfer,
                hideNextButton: false,
            })
        } else {
            const newTransfer = 0;
            this.tabsList.current.style.transform = `translateX(${-newTransfer}px)`;
            this.setState({
                transfer: newTransfer,
                hideNextButton: false,
                hidePrevButton: true,
            })
        }
    }

    onMouseDown = (event) => {
        // console.log(event.clientX, 'onMouseDown')
        if (this.scrollWith && event.clientX) {
            this.mouseDown = true;
            this.mousePosX = event.clientX;
            // console.log('onMouseDown')
            this.tabsList.current.style.transition = 'transform 0s linear';
        }
    };

    onMouseUp = (event) => {
        if (this.scrollWith) {
            this.mouseDown = false;
            this.mousePosX = 0;
            this.tabsList.current.style.transition = 'transform 0.2s linear';
            this.setState({
                transfer: this.newTransfer
            })
            this.newTransfer = 0
        }
    };

    onMouseLeave = (event) => {
        // console.log('onMouseLeave')
        if (this.mouseDown && this.scrollWith && event.clientX) {
            this.mouseDown = false;
            this.mousePosX = 0;
            this.tabsList.current.style.transition = 'transform 0.2s linear';
            this.setState({
                transfer: this.newTransfer
            })
            this.newTransfer = 0
        }
    };

    onMouseMove = (event) => {
        if (this.mouseDown && this.scrollWith && event.clientX) {
            const {transfer, hideNextButton, hidePrevButton} = this.state;
            const x = event.clientX;
            const delta = this.mousePosX - x;
            let newTransfer = transfer + delta;
            if (newTransfer > this.scrollWith) {
                this.setState({
                    hideNextButton: true,
                    hidePrevButton: false,
                })
                newTransfer = this.scrollWith;
            } else if (newTransfer < 0) {
                newTransfer = 0;
                this.setState({
                    hideNextButton: false,
                    hidePrevButton: true,
                })
            } else if (hideNextButton || hidePrevButton) {
                this.setState({
                    hideNextButton: false,
                    hidePrevButton: false,
                })
            }
            this.newTransfer = newTransfer
            this.tabsList.current.style.transform = `translateX(${-newTransfer}px)`;

            // console.log(x, y)
        }
    };

    render() {
        const {changeTab, tabsList, currentTab, allTab, allTabActive} = this.props;
        const {hideNextButton, hidePrevButton, stateAllTabActive} = this.state;

        return <div className="custom-tabs-wrapper" ref={this.tabsWrapperRef}>
            <div className={`tabs-slider-btn prev-btn ${hidePrevButton ? 'hide' : ""}`}
                 onClick={this.prevTab}>
                <ArrowIcon className="slider-btn-img"/>
            </div>
            <ul className={'tabs-list'} ref={this.tabsList}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                onMouseMove={this.onMouseMove}
                onMouseLeave={this.onMouseLeave}>
                {
                    allTab && <li className={`tab-item ${(allTabActive || stateAllTabActive) ? 'active' : ''}`}
                                  key="allTab"
                                  onClick={() => {
                                      changeTab();
                                      this.setState({stateAllTabActive: true})
                                  }}>
                        All
                    </li>
                }
                {
                    Object.keys(tabsList).map((tab, index) => {
                        const item = tabsList[tab];
                        return <li
                            className={`tab-item ${currentTab === item?.id ? 'active' : ''}`}
                            key={index}
                            onClick={() => {
                                changeTab(tab)
                                this.setState({stateAllTabActive: false})
                            }}
                        >
                            {item?.name || item?.title}
                        </li>
                    })
                }
            </ul>
            <div className={`tabs-slider-btn next-btn ${hideNextButton ? 'hide' : ""}`}
                 onClick={this.nextTab}>
                <ArrowIcon className="slider-btn-img"/>
            </div>
        </div>
    }
}

export default CustomTabs;