import React from "react";
import '../../assets/styles/components/uiElements/skelaton-item.scss';

export const SkeletonItem = ({half}) => {
    return <div className={`skeleton-item ${half ? "half" : ""}`}/>
}
export const SkeletonGroup = ({children, count, square}) => {
    const createSkeletonItems = () => {
        let skeletonItems = [];
        for (let index = 0; index < count; index++) {
            skeletonItems.push(
                !square ? <div key={index} className={`skeleton-group-item`}>
                {children}
            </div> : children)
        }
        return skeletonItems
    }

    return createSkeletonItems()

}

// export const SkeletonTrack = ({loading}) => {
//     return <div className={' track-card skelton-item-track'}>
//         <Skeleton loading={true}
//                   shape={'square'}
//                   paragraph={false}
//                   avatar
//                   active
//         />
//     </div>
// }
