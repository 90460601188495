// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

//Import components
import TrackCard from "../../components/cards/TrackCard";
import SkeletonTrackCard from "../../components/skeletonCards/SkeletonTrackCard";

//import utils
import {GetSections, ClearTracks} from "../../redux/actions";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {SeeAll} from "../../components/uIElements/buttons";


function TracksTab(props) {
    const {data, loading} = props;
    const [isFetching, setFetching] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

    }, []);

    const loadMoreSections = () => {
        console.log('loadMoreSections')
        if (!isFetching) {
            setFetching(true);
            props.GetSections({
                reset: false,
                offset: data?.list?.length
            })
                .finally(() => {
                    setFetching(false);
                });
        }
    };
console.log('data?.hasMore',data?.hasMore)
    return <div className="homepage-tracks-wrapper">

        {!loading ? (!!data?.list?.length &&
                <InfiniteScroll
                    hasMore={data?.hasMore}
                    loadMore={loadMoreSections}
                    className={'section-tracks'}
                    pageStart={0}
                    useWindow={true}
                    initialLoad={false}>
                    {
                        data?.list?.map(section => {
                            return <div className="section-item-wrapper" key={section.slug}>
                                <div className="section-title">
                                    <span>{section.title}</span>
                                    {<SeeAll link={`/section/${section.slug}`}/>}
                                </div>
                                <div className="tracks-list tab-tracks">
                                    {
                                        section?.tracks?.map((track, index) => {
                                            return <TrackCard key={track.id}
                                                              track={track}
                                                              playerTracksList={data}
                                                              indexInPlayerList={index}
                                                              toggleFavoriteState={props.toggleFavoriteState}
                                            />
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </InfiniteScroll>) :
            <SkeletonTrackCard count={5}/>}
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [])
};

const mapDispatchToProps = {
    GetSections,
    ClearTracks
};

export default connect(mapStateToProps, mapDispatchToProps)(TracksTab);
