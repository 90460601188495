import {_urlReports, _urlTracks, _urlUsers, request,} from "../api";
import {TRACKS_CONSTS} from "../constants";
import {TRACK_TYPES} from "../../constants/constants";

export const GetTracks = ({
                              offset = 0,
                              limit = 20,
                              reset = true,
                              text = '',
                              artist = null,
                              genreIds = null,
                              album = null,
                              isTop = false,
                              type = TRACK_TYPES.TRACK,
                              getResponse = false,
                          } = {}) => {

    const requestData = {
        url: `${_urlTracks}?offset=${offset}&limit=${limit}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };
    type && (requestData.url += `&type=${type}`);
    text && (requestData.url += `&text=${text}`);
    artist && (requestData.url += `&artist=${artist}`);
    album && (requestData.url += `&album=${album}`);
    genreIds && (requestData.url += `&genreIds=${genreIds}`);
    isTop && (requestData.url += `&isTop=${isTop}`);

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                if (!getResponse) {
                    data && dispatch({
                        type: TRACKS_CONSTS.GET_TRACKS,
                        payload: {
                            data: data,
                            reset,
                            text,
                            artist,
                            album,
                            hasMore: data?.length === limit
                        }
                    })
                    return data
                } else {
                    const data = res && res.data;
                    return {
                        data,
                        hasMore: data?.length === limit,
                        reset
                    }
                }
            })
    };
};

export const ClearTracks = () => {
    return async dispatch => {
        await dispatch({
            type: TRACKS_CONSTS.CLEAR_TRACKS
        })
    };
};

export const GetFavoriteTracks = ({offset = 0, limit = 10, reset = true, getResponse = false} = {}) => {
    const requestData = {
        url: `${_urlTracks}/favorites?offset=${offset}&limit=${limit}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                if (!getResponse) {
                    data && dispatch({
                        type: TRACKS_CONSTS.GET_FAVORITE_TRACKS,
                        payload: {
                            data,
                            reset,
                            hasMore: data?.length === limit
                        }
                    })
                } else {
                    const data = res && res.data;
                    return {
                        data,
                        hasMore: data?.length === limit,
                    }
                }
            })
    };
};

export const AddToFavorites = (track) => {
    const requestData = {
        url: `${_urlTracks}/${track.id}/add-to-favorites`,
        method: "POST",
        token: true,
    };

    return (dispatch) => {
        return request(requestData).then(async () => {
            const likeState = true;
            await dispatch({
                type: TRACKS_CONSTS.TOGGLE_TRACK_FAVORITE,
                payload: {track, id: track.id, likeState}
            });
        })
    };
};
export const AddTrackReport = (trackId, data) => {
    const requestData = {
        url: `${_urlReports}/${trackId}`,
        method: "POST",
        token: true,
        data
    };

    return (dispatch) => {
        return request(requestData)
    };
};
export const RemoveFromFavorites = (id) => {
    const requestData = {
        url: `${_urlTracks}/${id}/remove-from-favorites`,
        method: "DELETE",
        token: true,
    };
    return (dispatch,) => {
        return request(requestData).then(async () => {
            const likeState = false;
            await dispatch({
                type: TRACKS_CONSTS.TOGGLE_TRACK_FAVORITE,
                payload: {id, likeState}
            });
        })
    };
};

export const GetListenedTracks = ({offset = 0, limit = 20, reset = true, getResponse = false} = {}) => {
    const requestData = {
        url: `${_urlTracks}/listened?offset=${offset}&limit=${limit}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                if (!getResponse) {
                    data && dispatch({
                        type: TRACKS_CONSTS.GET_LISTENED_TRACKS,
                        payload: {
                            data,
                            reset,
                            hasMore: data?.length === limit
                        }
                    })
                } else {
                    const data = res && res.data;
                    return {
                        data,
                        hasMore: data?.length === limit,
                    }
                }
            })
    };
};

export const AddToListened = (id) => {
    const requestData = {
        url: `${_urlUsers}/track-listened/${id}`,
        method: "POST",
        token: true,
    };

    return (dispatch) => {
        return request(requestData)
    };
};

export const removeFromListened = (id) => {
    const requestData = {
        url: `${_urlUsers}/track-listened/${id}`,
        method: "DELETE",
        token: true,
    };

    return (dispatch) => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: TRACKS_CONSTS.REMOVE_TRACK_FROM_LISTENED,
                    payload: id
                });
            })

    };
};

export const GetTrackById = (id) => {
    const requestData = {
        url: `${_urlTracks}/${id}`,
        method: "GET",
        languageFlag: true,
        // token: true
    };
    return () => {
        return request(requestData)
    };
};
