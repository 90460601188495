// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link, useParams, useLocation} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {FacebookShareButton} from "react-share";

//import Assets
import '../assets/styles/containers/album-details.scss';
import {ReactComponent as PlayIcon} from "../assets/images/playerBar-icons/ic_play_white.svg"
import {ReactComponent as FacebookIcon} from "../assets/images/icons/ic_facebook_share.svg";
import {ReactComponent as ShareSongIcon} from "../assets/images/playerBar-icons/ic_share.svg";
import {ReactComponent as FillHeart} from "../assets/images/playerBar-icons/ic_heart_fill.svg";
import {ReactComponent as EmptyHeart} from "../assets/images/playerBar-icons/ic_heart_empty.svg";
import {ReactComponent as CopyIcon} from "../assets/images/icons/ic_copy_link.svg";

//import components
import TrackCard from "../components/cards/TrackCard";
import MetaTags from "../components/MetaTags";
import {LinkButton} from "../components/uIElements/buttons";
import {SkeletonItem} from "../components/uIElements/SkelatonItem";
import SkeletonTrackCard from "../components/skeletonCards/SkeletonTrackCard";
import {PageSkeleton} from "./PageSkeleton";

//import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {
    GetTracks,
    ClearTracks,
    setCurrentPlayedTrack,
    togglePlayState,
    GetTrackById,
    GetPodcastsListTrack, GetPodcastById
} from "../redux/actions";
import {RemoveAlbumFromFavorites, AddAlbumToFavorites} from "../redux/actions";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import {Image} from "../components/uIElements/Image";
import {ReactComponent as PauseIcon} from "../assets/images/playerBar-icons/ic_pause_white.svg";
import {ALBUM_TYPES} from "../constants/constants";
import {shuffle} from "../utils/helperFunctions";


function PodcastDetails(props) {
    const params = useParams();
    const location = useLocation();
    const [shareWrapperIsOpen, setShareWrapperOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [podcast, setPodcast] = useState({
        ...location.state?.podcast || {},
    });
    const [artistsLoading, setArtistsLoading] = useState(true);
    const [loading, setLoading] = useState(true);

    const {isLoggedIn, podcastsListTracks, tracks} = props;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        props.GetPodcastById(params?.slug)
            .then((res) => {
                const data = res && res.data;
                setPodcast(data);

                setArtistsLoading(false);
                props.GetPodcastsListTrack(params?.slug).then(() => {
                    setLoading(false);
                });

            });


        window.addEventListener('click', onClicked);
        return () => {
            window.removeEventListener('click', onClicked)
            props.ClearTracks();
        }

    }, [params?.slug]);

    function onClicked() {
        setShareWrapperOpen(false);
    }

    function toggleFavorite() {
        if (isLoggedIn) {
            if (!isFetching) {
                setFetching(true);
                const favoritePromise = podcast.isFavorite ? props.RemoveAlbumFromFavorites(podcast.id) : props.AddAlbumToFavorites(podcast.id);
                favoritePromise.finally(() => {
                    setFetching(false);
                    setPodcast({
                        ...podcast,
                        isFavorite: !podcast.isFavorite,
                        likeCount: podcast.isFavorite ? podcast.likeCount - 1 : podcast.likeCount + 1
                    })
                })
            }
        }
    }

    const loadMoreItems = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetPodcastsListTrack({
                reset: false,
                offset: podcastsListTracks?.list?.length,
                podcast: podcast?.id
            }).finally(() => {
                setFetching(false);
            });
        }
    };

    function toggleShareWrapper(e) {
        e && e.preventDefault();
        e && e.stopPropagation();
        setShareWrapperOpen(!shareWrapperIsOpen);

    }

    function setCurrentTrack() {
        if (podcastsListTracks?.list?.length) {
            if (location?.pathname.includes(props?.type)) {
                togglePlayState(!props.playState)
            } else {
                setCurrentPlayedTrack(podcastsListTracks?.list[0], podcastsListTracks?.list, location?.pathname, 0, shuffle(podcastsListTracks?.list));
                togglePlayState(true)
            }
        }
    }

    // console.log(podcast, 'podcast');
    // console.log(podcastsListTracks, 'podcastsListTracks');
    return <PageSkeleton withPadding>
        <MetaTags title={podcast?.title}
                  image={generateImageMediaUrl(podcast?.coverImage?.path)}/>
        <div className="album-header">
            <div className="img-wrapper">
                <Image url={podcast?.coverImage?.path} loading={loading}/>
            </div>
            <div className="album-description-content">
                <div style={{width: "100%"}}>
                    <div
                        className="page-name">PODCAST</div>
                    <div className="album-name">{!loading ? podcast?.name :
                        <div style={{width: "100%", height: "30px", marginTop: "15px"}}>
                            <SkeletonItem/>
                        </div>}
                    </div>

                    <div className="album-description artists">
                        {!loading ? <div className="artists-item active-artist">
                            {podcast?.owner?.firstName || ''}&nbsp;
                            {podcast?.owner?.lastName || ''}
                            {/*{index + 1 < podcast?.artists?.length && <span>,&nbsp;</span>}*/}
                        </div> :
                            <div style={{width: "50%", height: "20px", marginTop: "0", marginBottom: "5px"}}>
                                <SkeletonItem/>
                            </div>
                        }
                        {!loading ? (!!podcast?.artists?.length && podcast?.artists?.map((item, index) => {
                                return !item?.isHidden ? <div className="artists-item active-artist"
                                                               key={item.id}>
                                        {item?.fullName}
                                        {index + 1 < podcast?.artists?.length && <span>,&nbsp;</span>}
                                    </div>
                                    : <span className="artists-item "
                                            key={item.id}>
                            {item?.fullName}
                                        {index + 1 < podcast?.artists?.length && <span>,&nbsp;</span>}
                        </span>
                            })) :
                            <div style={{width: "50%", height: "20px", marginTop: "0", marginBottom: "5px"}}>
                                <SkeletonItem/>
                            </div>
                        }</div>
                </div>
                <div className="btns-wrapper">
                    <span className="play-all" onClick={setCurrentTrack}>
                        {location.pathname.includes(props?.type) && props?.playState ?
                            <PauseIcon title={''} className="play-icon"/> :
                            <PlayIcon title={''} className="play-icon"/>}
                        Play
                    </span>
                    <div className="heart-dropdown-wrapper" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleFavorite()
                    }
                    }>
                        <div className={`heart-wrapper ${podcast?.likeCount > 0 ? "have-likes" : ""}`}>
                            {podcast?.isFavorite ?
                                <FillHeart className='card-heart fill-heart'/>
                                : <EmptyHeart className='card-heart' title=''/>}
                            {!!podcast?.likeCount && <div className="like-count">{podcast?.likeCount}</div>}
                        </div>
                        {!isLoggedIn && <div className={`heart-dropdown-content`}>
                            <span>Please sign in to save your favorites</span>
                            <LinkButton title="Sign in"
                                        link={"/sign-in"}
                            />
                        </div>
                        }
                    </div>
                    <div className="share-btn" onClick={toggleShareWrapper}>
                        <ShareSongIcon title=''/>
                        {shareWrapperIsOpen && <div className="dropdown-content">
                            <div className="share-wrapper">

                                <CopyToClipboard text={`http://youtop.app/podcast/${podcast?.id}`}
                                                 className={"copy-wrapper"}
                                                 onCopy={() => setCopied(true)}
                                                 onMouseLeave={() => setCopied(false)}>
                                    <button onClick={e => {
                                        e.stopPropagation()
                                    }}>
                                        <CopyIcon/> Copy url
                                        <div className="copy-text">{copied ? "Copied" : 'Copy'}</div>
                                    </button>
                                </CopyToClipboard>

                                <FacebookShareButton url={`http://youtop.app/podcast/${podcast?.id}`}
                                    // quote={`${podcast.title}`}
                                >
                                    <FacebookIcon/> Facebook
                                </FacebookShareButton>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        <div className="section-header"><span>All podcasts</span></div>
        {!loading ?
            (!!podcastsListTracks?.list?.length ? <div className="artist-top-song-list">
                    <InfiniteScroll
                        hasMore={podcastsListTracks?.hasMore}
                        loadMore={loadMoreItems}
                        className={'tracks-list'}
                        pageStart={0}
                        useWindow={true}
                        initialLoad={false}>
                        {
                            podcastsListTracks?.list?.map((item, index) => {
                                return <TrackCard key={item.id}
                                                  track={item}
                                                  playerTracksList={podcastsListTracks?.list}
                                                  indexInPlayerList={index}
                                                  podcastId={podcast?.id}
                                                  path={podcast?.type !== ALBUM_TYPES.PLAYLIST.key && podcast?.coverImage?.path}
                                                  index={index + 1}
                                />
                            })
                        }
                    </InfiniteScroll>
                </div> :
                <div className="no-result">
                    <span>This podcast have no tracks</span>
                </div>) :
            <SkeletonTrackCard count={3}/>

        }
    </PageSkeleton>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isLoggedIn',
        'tracks',
        'requestLoading',
        'type',
        'playState',
        'shuffled',
        'shuffledTracksList',
        'podcastsListTracks'
    ])
};

const mapDispatchToProps = {
    GetTracks,
    ClearTracks,
    AddAlbumToFavorites,
    RemoveAlbumFromFavorites,
    togglePlayState,
    GetTrackById,
    GetPodcastsListTrack,
    GetPodcastById
};

export default connect(mapStateToProps, mapDispatchToProps)(PodcastDetails);
