import {
    _urlAlbums,
    _urlArtists,
    _urlGenres,
    _urlLanguage,
    _urlPodcasts,
    _urlSections,
    _urlTracks,
    request,
} from "../api";
import {GENERAL_CONSTS, UTIL_CONSTS} from "../constants";
import {ALBUM_TYPES, TRACK_TYPES} from "../../constants/constants";

export const getBrowserLanguage = () => {
    return navigator.language.split('-')[0] || null;
};


export const GetLanguages = () => {
    const requestData = {
        url: `${_urlLanguage}`,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_LANGUAGES,
                    payload: data
                })
            })
    };
};

export const ChangeLanguage = (data) => {
    return dispatch => {
        dispatch({
            type: GENERAL_CONSTS.CHANGE_LANGUAGE,
            payload: data
        })
    }
};

export const GetTracksSuggestions = (text) => {
    const requestData = {
        url: `${_urlTracks}/?text=${text}&limit=${4}&type=${TRACK_TYPES.TRACK}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_TRACKS_SUGGESTIONS,
                    payload: data
                });
            })
    };
};

export const GetArtistSuggestions = (text) => {
    const requestData = {
        url: `${_urlArtists}/?text=${text}&limit=${4}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_ARTISTS_SUGGESTIONS,
                    payload: data
                });
            })
    };
};

export const GetAlbumsSuggestions = (text) => {
    const requestData = {
        url: `${_urlAlbums}/?text=${text}&limit=${4}&types=${ALBUM_TYPES.MAIN.key},${ALBUM_TYPES.SINGLE.key}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_ALBUMS_SUGGESTIONS,
                    payload: data
                });
            })
    };
};

export const GetPlaylistsSuggestions = (text) => {
    const requestData = {
        url: `${_urlAlbums}/?text=${text}&limit=${4}&types=${ALBUM_TYPES.PLAYLIST.key}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_PLAYLIST_SUGGESTIONS,
                    payload: data
                });
            })
    };
};

export const GetPodcastsSuggestions = (text) => {
    const requestData = {
        url: `${_urlPodcasts}/all?text=${text}&limit=${4}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_PODCAST_SUGGESTIONS,
                    payload: data
                });
            })
    };
};

export const ClearSuggestions = () => {
    return async dispatch => {
        await dispatch({
            type: GENERAL_CONSTS.CLEAR_SUGGESTIONS
        })
    }
}

export const GetVideoList = () => {
    return async dispatch => {
        await dispatch({
            type: GENERAL_CONSTS.GET_VIDEO_LIST
        })
    };
};

export const GetGenres = (reset = true) => {

    const requestData = {
        url: `${_urlGenres}`,
        method: "GET",
        languageFlag: true,
    };

    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_GENRES,
                    payload: {
                        data,
                        reset
                    }
                });
                dispatch({type: UTIL_CONSTS.END_LOADING});
            })
            .catch(err => {
            })
    };
};

export const GetGenreById = (id) => {

    const requestData = {
        url: `${_urlGenres}/${id}`,
        method: "GET",
        languageFlag: true,
    };

    return dispatch => {
        return request(requestData)
    };
};
