// import packages
import React from "react";
import moment from "moment";

// Import styles
import "../../assets/styles/components/cards/notification-card.scss"
import {ReactComponent as CloseIcon} from "../../assets/images/icons/ic_close_gray.svg";

// Import Utils
import {history} from "../../configs/history";
import {DeleteNotification} from "../../socket/emitters";
import {GetNotifications, GetTrackById, ToggleNotification} from "../../redux/actions";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {NOTIFICATION_TYPES} from "../../constants/constTypes";
import {connect} from "react-redux";
import {getPropsFromState} from "../../redux/mapStateToProps";


function NotificationCard(props) {
    const {data} = props;

    async function navigateToPage() {
        let url = `${NOTIFICATION_TYPES[data?.type]?.value}/${data?.resourceId}`;
        data.type === NOTIFICATION_TYPES.ARTIST.key && (url = `${url}/songs`);
        data.type === NOTIFICATION_TYPES.INFORMATION.key && (url = ``);

        if (data.type === NOTIFICATION_TYPES.TRACK.key) {
            await props.GetTrackById(data?.resourceId).then((res) => {
                if (res.data.albums[0]?.slug) {
                    url = `${NOTIFICATION_TYPES[data?.type]?.value}/${res.data.albums[0]?.slug}`;
                } else {
                    url = ''
                }
            })
        }

        history.push({
            pathname: url,
            state: {
                fromNotification: true
            }
        })
    }

    function deleteNotification(e, id) {
        e.preventDefault();
        e.stopPropagation();
        DeleteNotification(id)
    }

    return <div
        onClick={navigateToPage}
        className="notification-item"
        key={data?.id}>
        <div className="img-wrapper">
            <img
                src={generateImageMediaUrl(data?.image?.path)}
                className="card-img"
                alt="slider"/>
        </div>
        <div className="notification-item-content">
            <div>
                <div className="notification-item-header">
                    <div className="item-title">{data?.title}</div>
                    <CloseIcon title={''} className="close-btn" onClick={(e) => deleteNotification(e,data.id)}/>
                </div>
                <div className="notification-item-description">
                    {data?.description}</div>
            </div>
            <div className="notification-item-date">{moment(data.createdAt).format("DD.MM.YYYY")}</div>
        </div>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'requestLoading',
]);

const mapDispatchToProps = {
    GetTrackById
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCard)