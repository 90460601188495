// import packages
import React from "react";

// import assets
import '../assets/styles/containers/no-result.scss'

// import components

function NotFound(props) {

    return <div className="no-result-empty-view">
            <p>Page is not found</p>
        </div>
}

export default NotFound;
