// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link, useParams} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';

//import Assets
import '../assets/styles/containers/track-details.scss'

//import components
import PageWrapper from "./PageWrapper";
import TrackCard from "../components/cards/TrackCard";

//import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetSectionTracks, ClearTracks} from "../redux/actions";
import YouTubePlayer from "../components/uIElements/YouTubePlayer";
import {getYoutubeId} from "../utils/helperFunctions";
import MetaTags from "../components/MetaTags";
import {SkeletonItem} from "../components/uIElements/SkelatonItem";
import SkeletonTrackCard from "../components/skeletonCards/SkeletonTrackCard";


function SectionTracks(props) {
    const [isFetching, setFetching] = useState(false);
    const [videoIdList, setVideoIdList] = useState([]);
    const [sectionTracks, setSectionTracks] = useState({});
    const [paused, setPaused] = useState(false);
    const [loading, setLoading] = useState(true);
    const params = useParams();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        props.GetSectionTracks({
            id: params?.slug,
            getResponse: true
        }).then((res) => {
            setSectionTracks(res);
            let videoList = [];
            res.data.length && res.data.forEach(item => {
                videoList.push({
                    videoId: getYoutubeId(item?.youtubeUrl),
                    trackId: item?.id
                });
            });
            setVideoIdList(videoList)
            setLoading(false)
        });

    }, [params?.slug]);

    const toggleFavoriteState = (id, favorite) => {
        let list = sectionTracks?.data?.map(track => track.id === id ?
            {
                ...track,
                isFavorite: favorite
            } : track
        );
        setSectionTracks({data: list})
    };
    const loadMoreItems = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetSectionTracks({
                reset: false,
                offset: sectionTracks?.data?.length,
                id: params?.id,
            }).finally(() => {
                setFetching(false);
            });
        }
    };

    const setRedirectUrl = (id) => {
        return `/section/${params?.slug}/track/${id}`
    };

    let videoIndex = 0;
    videoIdList.forEach((item, index) => {
        if (item.trackId === params.trackId) {
            videoIndex = index;
        }
    });

    return <PageWrapper>
        <MetaTags title={sectionTracks?.data?.[videoIndex]?.title}
                  youTubeUrl={sectionTracks?.data?.[videoIndex]?.youtubeUrl}/>
        <div className="main-img-wrapper">
            {!!videoIdList?.length &&
            <YouTubePlayer videoIds={videoIdList}
                           setRedirectUrl={setRedirectUrl}
                           paused={paused}
                           pausedVideo={setPaused}/>}
        </div>
        {!loading ? (sectionTracks?.data && <>
            <div className="track-name">{sectionTracks?.data[videoIndex]?.title}</div>
            {
                !!sectionTracks?.data[videoIndex]?.artists?.length ? sectionTracks?.data[videoIndex]?.artists?.map(item => {
                        return !item?.isHidden ?
                            <Link to={`/artist/${item?.slug}/songs`}
                                  key={item.id}>
                                <div className="track-artist-name track-artist">{item?.fullName}</div>
                            </Link>
                            : <div className="track-artist-name" key={item.id}>{item?.fullName}</div>
                    })
                    : <div className="track-artist-name">{sectionTracks?.data[videoIndex]?.description}</div>
            }
        </>) : <div className="track-name" style={{marginBottom: "20px"}}><SkeletonItem/></div>}

        {!loading ?
            (!!sectionTracks?.data?.length && <div className="artist-top-song-list">
                <div className="section-header"><span>All songs</span></div>
                <InfiniteScroll
                    hasMore={sectionTracks?.hasMore}
                    loadMore={loadMoreItems}
                    className={'tracks-list'}
                    pageStart={0}
                    useWindow={true}
                    initialLoad={false}>
                    {
                        sectionTracks?.data?.map(item => {
                            return <TrackCard key={item.id}
                                              redirectUrl={`/section/${params?.slug}/track/${item.id}`}
                                              track={item}
                                              playerTracksList={sectionTracks?.data}
                                              toggleFavoriteState={toggleFavoriteState}
                                              paused={paused}
                            />
                        })
                    }
                </InfiniteScroll>
            </div>) :
            <SkeletonTrackCard count={3}/>
        }
    </PageWrapper>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'sectionTracks',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    GetSectionTracks,
    ClearTracks
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionTracks);
