import {GENERAL_CONSTS, TRACKS_CONSTS} from "../constants";

export const initialState = {
    languages: [],
    selectedLanguage: '',
    videoList: [],
    suggestions: {
        tracks: [],
        artists: [],
        albums: [],
        sections: [],
        podcasts: [],
    },
    genres: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GENERAL_CONSTS.GET_LANGUAGES:
            const mainLanguage = action.payload.find(lg => lg.isMain);
            const selectedLanguageIsExist = state.selectedLanguage && state.selectedLanguage.id
                && action.payload.find(lg => lg.id === state.selectedLanguage.id);
            const newSelectedLanguage = selectedLanguageIsExist ? state.selectedLanguage :
                (mainLanguage ? mainLanguage : action.payload[0]);

            const newState = {
                ...state,
                languages: action.payload,
                selectedLanguage: newSelectedLanguage,
            };

            return newState;

        case GENERAL_CONSTS.CHANGE_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload,
            };

        case GENERAL_CONSTS.GET_VIDEO_LIST:
            return {
                ...state,
                videoList: action.payload,
            };

        case GENERAL_CONSTS.GET_TRACKS_SUGGESTIONS:
            return {
                ...state,
                suggestions: {
                    ...state.suggestions,
                    tracks: action.payload,
                }
            };

        case GENERAL_CONSTS.GET_ARTISTS_SUGGESTIONS:
            return {
                ...state,
                suggestions: {
                    ...state.suggestions,
                    artists: action.payload,
                }
            };

        case GENERAL_CONSTS.GET_ALBUMS_SUGGESTIONS:
            return {
                ...state,
                suggestions: {
                    ...state.suggestions,
                    albums: action.payload,
                }
            };

        case GENERAL_CONSTS.GET_PLAYLIST_SUGGESTIONS:
            return {
                ...state,
                suggestions: {
                    ...state.suggestions,
                    sections: action.payload,
                }
            };
        case GENERAL_CONSTS.GET_PODCAST_SUGGESTIONS:
            return {
                ...state,
                suggestions: {
                    ...state.suggestions,
                    podcasts: action.payload,
                }
            };

        case GENERAL_CONSTS.CLEAR_SUGGESTIONS:
            return {
                ...state,
                suggestions: {...initialState.suggestions},
            };
        case TRACKS_CONSTS.TOGGLE_TRACK_FAVORITE:
            return {
                ...state,
                suggestions: {
                    ...state.suggestions,
                    tracks: state.suggestions.tracks.map(item => item.id === action.payload.id ?
                        {
                            ...item,
                            isFavorite: action.payload.likeState
                        } : item
                    )
                }
            };
        case GENERAL_CONSTS.GET_GENRES:
            return {
                ...state,
                genres: action.payload.reset ? action.payload.data : [...state.genres, ...action.payload.data],
            };

        default:
            return state;
    }
}
