import {_urlArtists, _urlTracks, request,} from "../api";
import {ARTISTS_CONSTS, TRACKS_CONSTS, UTIL_CONSTS} from "../constants";

export const GetArtists = ({
                               offset = 0,
                               limit = 20,
                               reset = true,
                               text = '',
                               artist = null,
                               album = null,
                               genreIds = null,
                               getResponse = false,
                           } = {}) => {

    const requestData = {
        url: `${_urlArtists}?offset=${offset}&limit=${limit}`,
        method: "GET",
        languageFlag: true,
    };
    text && (requestData.url += `&text=${text}`);
    artist && (requestData.url += `&artist=${artist}`);
    album && (requestData.url += `&album=${album}`);
    genreIds && (requestData.url += `&genreIds=${genreIds}`);

    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                if (!getResponse) {
                    data && dispatch({
                        type: ARTISTS_CONSTS.GET_ARTISTS,
                        payload: {
                            data: data,
                            reset,
                            text,
                            artist,
                            album,
                            hasMore: data?.length === limit
                        }
                    });
                } else {
                    return {
                        data,
                        hasMore: data?.length === limit,
                        reset
                    }
                }
                dispatch({type: UTIL_CONSTS.END_LOADING});
                return data
            })
            .catch(err => {
            })
    };
};

export const CleaArtists = () => {
    return async dispatch => {
        await dispatch({
            type: ARTISTS_CONSTS.CLEAR_ARTISTS
        })
    };
};

export const GetArtistsById = (id, limit = 20) => {
    const requestData = {
        url: `${_urlArtists}/${id}/?limit=${limit}`,
        method: "GET",
        languageFlag: true,
        token: true
    };
    return () => {
        return request(requestData)
    };
};

export const GetFavoriteArtists = ({
                                       offset = 0,
                                       limit = 10,
                                       reset = true,
                                       getResponse = false,
                                   } = {}) => {
    const requestData = {
        url: `${_urlArtists}/favorites?offset=${offset}&limit=${limit}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };

    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                if (!getResponse) {
                    data && dispatch({
                        type: ARTISTS_CONSTS.GET_FAVORITE_ARTISTS,
                        payload: {
                            data,
                            reset,
                            hasMore: data?.length === limit
                        }
                    })
                } else {
                    const data = res && res.data;
                    return {
                        data,
                        hasMore: data?.length === limit,
                    }
                }
                dispatch({type: UTIL_CONSTS.END_LOADING});
            })
    };
};

export const AddArtistToFavorites = (id) => {
    const requestData = {
        url: `${_urlArtists}/${id}/add-to-favorites`,
        method: "POST",
        token: true,
    };

    return (dispatch) => {
        return request(requestData).then(async () => {
            const likeState = true;
            await dispatch({
                type: ARTISTS_CONSTS.TOGGLE_ARTIST_FAVORITE,
                payload: {id, likeState}
            });
        })
    };
};

export const RemoveArtistFromFavorites = (id) => {
    const requestData = {
        url: `${_urlArtists}/${id}/remove-from-favorites`,
        method: "DELETE",
        token: true,
    };
    return (dispatch,) => {
        return request(requestData).then(async () => {
            const likeState = false;
            await dispatch({
                type: ARTISTS_CONSTS.TOGGLE_ARTIST_FAVORITE,
                payload: {id, likeState}
            });
        })
    };
};
