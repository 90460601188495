// import packages
import React from "react";

// Import assets
import '../assets/styles/containers/apps.scss'
import Main from "./Main";
import {androidUrl, iosUrl} from "../configs/appUrls";
import {ReactComponent as AppStore} from "../assets/images/download-app-store-badge.svg";
import {ReactComponent as GooglePlay} from "../assets/images/download-google-play-badge.svg";


function About() {

    return <div className="apps-wrapper">
        <Main/>
        <div className="apps-content">
            <div className="info-wrapper">
                <div className="apps-title">YouTop for ios</div>
                <div className="description">
                    You can now identify all the music that you want with our less than 1MB to download app.
                    You can also Shazam music while offline, save your most recent results and share results
                    with your friends - all regardless of network coverage.You can now identify
                    all the music that you want with our less than 1MB to download app.
                    You can also Shazam music while offline, save your most recent results
                    and share results with your friends - all regardless of network coverage
                </div>
                <a href={iosUrl} target={'_blank'} rel="noopener noreferrer"><AppStore className={"app-btn"}/></a>
            </div>
            <div className="img-wrapper">
                <div className="img-content">
                    <picture>
                        <source media="(max-width:600px)" srcSet={require("../assets/images/apps_ios_sm.jpg")}/>
                        <img src={require("../assets/images/apps_ios.jpg")} alt={""}
                             className="login-bg"/>
                    </picture>
                </div>
            </div>
        </div>
        <div className="apps-content">
            <div className="img-wrapper">
                <div className="img-content bottom-image">
                    <picture>
                        <source media="(max-width:600px)" srcSet={require("../assets/images/apps_android_sm.jpg")}/>
                        <img src={require("../assets/images/apps_android.jpg")} alt={""}
                             className="login-bg"/>
                    </picture>
                </div>
            </div>
            <div className="info-wrapper">
                <div className="apps-title">YouTop for android</div>
                <div className="description">
                    You can now identify all the music that you want with our less than 1MB to download app.
                    You can also Shazam music while offline, save your most recent results and share results
                    with your friends - all regardless of network coverage.You can now identify
                    all the music that you want with our less than 1MB to download app.
                    You can also Shazam music while offline, save your most recent results
                    and share results with your friends - all regardless of network coverage
                </div>
                <a href={androidUrl} target={'_blank'} rel="noopener noreferrer"><GooglePlay className={"app-btn"}/></a>
            </div>
        </div>
    </div>
}

export default About;
