import { _urlPlaylists, request } from "../api";
import { PLAYLIST_CONSTS } from "../constants";

export const GetPlaylists = () => {
    const requestData = {
        url: `${_urlPlaylists}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: PLAYLIST_CONSTS.GET_PLAYLISTS,
                    payload: data,
                });
            })
            .catch(err => {
            })
    };
};

export const AddPlaylist = (data) => {
    const requestData = {
        url: `${_urlPlaylists}`,
        method: "POST",
        token: true,
        data
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: PLAYLIST_CONSTS.ADD_PLAYLIST,
                    payload: data,
                });
                return data
            })
    };
};
export const DeletePlaylist = (id) => {
    const requestData = {
        url: `${_urlPlaylists}/${id}`,
        method: "DELETE",
        token: true,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                console.log("action", id)
                dispatch({
                    type: PLAYLIST_CONSTS.DELETE_PLAYLIST,
                    payload: id,
                });
            })
    };
};


export const GetPlaylistTracks = (id) => {
    const requestData = {
        url: `${_urlPlaylists}/${id}/tracks`,
        method: "GET",
        token: true,
        languageFlag: true,
    };

    return dispatch => {
        return request(requestData)

    };
};

export const AddTrackToPlaylist = (id, trackId, trackImage = null) => {
    const requestData = {
        url: `${_urlPlaylists}/${id}/tracks/${trackId}`,
        method: "POST",
        token: true,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                dispatch({
                    type: PLAYLIST_CONSTS.ADD_TRACK_TO_PLAYLIST,
                    payload: {
                        id,
                        trackImage
                    }
                });
            })
    };
};

export const RemoveTrackFromPlaylist = (id, trackId, index) => {
    const requestData = {
        url: `${_urlPlaylists}/${id}/tracks/${trackId}?index=${index}`,
        method: "DELETE",
        token: true,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                dispatch({
                    type: PLAYLIST_CONSTS.REMOVE_TRACK_FROM_PLAYLIST,
                    payload: {
                        id
                    }
                });
            })
    };
};

export const UpdatePlaylistTracksPositions = (id, data) => {
    const requestData = {
        url: `${_urlPlaylists}/${id}`,
        token: true,
        method: "PATCH",
        data: {
            tracks: data,
        },
    };
    return dispatch => {
        return request(requestData)
    }
};

export const UpdatePlaylist = (id, data) => {
    const requestData = {
        url: `${_urlPlaylists}/${id}`,
        token: true,
        method: "PATCH",
        data
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: PLAYLIST_CONSTS.UPDATE_PLAYLIST,
                    payload: {
                        id,
                        data,
                    }
                });
            })
    }
};
