// Import packages
import React, { PureComponent } from "react";

// Import components
import { LinkButton } from "../../../components/uIElements/buttons";

// Import styles
import "./song-uploader.scss";
import { ReactComponent as ArtistIcon } from "../../../assets/images/icons/ic_artist_white.svg";

//import utils
import { hasSongExtension } from "../../../utils/hasExtension";
import { SONG_ACCEPT_TYPES, ACCEPT_SONG_SIZE } from "../../../constants/acceptedConsts";


export default class SongUploader extends PureComponent {
    constructor (props) {
        super(props);
        this.state = {
            fileError: false,
            errorMessage: "",
        };
        this.imageSelector = React.createRef();
    }

    readFile (file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Read the image via FileReader API and save image result in state.
            reader.onload = function(e) {
                let dataURL = e.target.result;
                resolve(dataURL);
            };
            reader.onerror = function(e) {
                //console.log(e);
                reject(e);
            };
            reader.readAsDataURL(file);
        });
    }

    handleFileChange (e) {
        let file = e && e.target && e.target.files && e.target.files[0];

        if (file) {
            if (!hasSongExtension(file.name)) {
                this.setState({
                    fileError: true,
                    errorMessage: `Accepted: ${SONG_ACCEPT_TYPES.join('| ')}`
                });
                return;
            }
            if (file.size > ACCEPT_SONG_SIZE) {
                this.setState({
                    fileError: true,
                    errorMessage: `File size is too big ${Math.floor(ACCEPT_SONG_SIZE / 1048576)}mb`
                });
                return;
            }
            this.readFile(file)
                .then(dataURL => {
                    this.setState({
                        fileError: false,
                        errorMessage: "",
                    }, () => this.props.onChange(file))
                });
        }
    }

    render () {
        let { songError, songFile, disabled, progress } = this.props;
        let { errorMessage, fileError } = this.state;
        return <div className={`podcast-song-uploader`} onClick={() => this.imageSelector.current.click()}>
            <LinkButton title={
                songFile ?
                    <span>
                    <ArtistIcon/>
                        {songFile?.name?.length > 5 ? songFile?.name?.slice(0, 5) + "..." : songFile?.name}
                </span> : "Upload audio"
            }
                        progress={progress}
                        disabled={disabled}
                        className={`song-upload-button ${songError? 'error': ''}`}
            />
            {
                fileError && <div className={'error-message'}>
                    {errorMessage}
                </div>
            }
            <input type="file" accept="audio/*" ref={this.imageSelector}
                   onChange={(e) => this.handleFileChange(e)}/>
        </div>
    }
}
