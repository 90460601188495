import {AUTH_CONSTS, TRACKS_CONSTS} from "../constants";

export const initialState = {
    tracks: {
        list: [],
        text: '',
        hasMore: false,
    },
    userFavoriteTracks: {
        list: [],
        hasMore: false,
    },
    userListenedTracks: {
        list: [],
        hasMore: false,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {

        case TRACKS_CONSTS.GET_TRACKS:
            const newTracksList = {
                list: action.payload.reset ? action.payload.data : [...state.tracks.list, ...action.payload.data],
                text: action.payload.text,
                hasMore: action.payload.hasMore
            };
            return {
                ...state,
                tracks: newTracksList,
            };

        case TRACKS_CONSTS.CLEAR_TRACKS:
            return {
                ...state,
                tracks: {...initialState.tracks},
            };
        case TRACKS_CONSTS.GET_FAVORITE_TRACKS:
            return {
                ...state,
                userFavoriteTracks: {
                    list: action.payload.reset ? action.payload.data : [...state.userFavoriteTracks.list, ...action.payload.data],
                    hasMore: action.payload.hasMore
                },
            };
        case TRACKS_CONSTS.TOGGLE_TRACK_FAVORITE:
            const newFavList = [...state.userFavoriteTracks?.list];
            const favInList = newFavList.find(item => item?.id === action.payload?.id)
            let favTrack = {};
            !favInList && (favTrack = {
                ...action.payload.track,
                isFavorite: action.payload.likeState
            });

            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    list: state.tracks.list.map(item => item.id === action.payload.id ?
                        {
                            ...item,
                            isFavorite: action.payload.likeState
                        } : item
                    )
                },
                userListenedTracks: {
                    ...state.userListenedTracks,
                    list: state.userListenedTracks.list.map(item => item.id === action.payload.id ?
                        {
                            ...item,
                            isFavorite: action.payload.likeState
                        } : item
                    )
                },
                userFavoriteTracks: {
                    ...state.userFavoriteTracks,
                    list: !favInList ? [...newFavList, favTrack] : newFavList.filter(item => item.id !== favInList.id)
                },
            };
        case TRACKS_CONSTS.GET_LISTENED_TRACKS:
            return {
                ...state,
                userListenedTracks: {
                    list: action.payload.reset ? action.payload.data : [...state.userListenedTracks.list, ...action.payload.data],
                    hasMore: action.payload.hasMore
                },
            };
        case TRACKS_CONSTS.REMOVE_TRACK_FROM_LISTENED:
            return {
                ...state,
                userListenedTracks: {
                    ...state.userListenedTracks,
                    list: state.userListenedTracks.list.filter(item => item.id !== action.payload)
                },
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
