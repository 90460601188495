// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link, useParams} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';

//import Assets
import '../assets/styles/containers/track-details.scss'

//import components
import PageWrapper from "./PageWrapper";
import TrackCard from "../components/cards/TrackCard";
import YouTubePlayer from "../components/uIElements/YouTubePlayer";
import MetaTags from "../components/MetaTags";

//import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetTracks, ClearTracks} from "../redux/actions";
import {getYoutubeId} from "../utils/helperFunctions";
import SkeletonTrackCard from "../components/skeletonCards/SkeletonTrackCard";
import {SkeletonItem} from "../components/uIElements/SkelatonItem";


function AlbumTracks(props) {
    const [isFetching, setFetching] = useState(false);
    const [videoIdList, setVideoIdList] = useState([]);
    const [tracksList, setTracksList] = useState([]);
    const [paused, setPaused] = useState(false);
    const [loading, setLoading] = useState(true);
    const {tracks} = props;
    const params = useParams();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        props.GetTracks({
            album: props?.location?.state?.album?.id,
            getResponse: true
        }).then(res => {
            setTracksList(res.data);
            let videoList = [];
            res.data.length && res.data.forEach(item => {
                videoList.push({
                    videoId: getYoutubeId(item?.youtubeUrl),
                    trackId: item?.id
                });
            });
            setVideoIdList(videoList)
            setLoading(false);
        });

    }, [params?.id]);

    const toggleFavoriteState = (id, favorite) => {
        setTracksList(tracksList.map(track => track.id === id ?
            {
                ...track,
                isFavorite: favorite
            } : track
        ))
    }

    const loadMoreItems = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetTracks({
                reset: false,
                offset: tracks?.list?.length,
                album: props?.location?.state?.album?.id,
            }).finally(() => {
                setFetching(false);
            });
        }
    };

    const setRedirectUrl = (id) => {
        return `/album/${params?.id}/track/${id}`
    };

    let videoIndex = 0;
    videoIdList.forEach((item, index) => {
        if (item.trackId === params.trackId) {
            videoIndex = index;
        }
    });

    return <PageWrapper>
        <MetaTags title={tracksList[videoIndex]?.title}
                  youTubeUrl={tracksList[videoIndex]?.youtubeUrl}/>
        <div className="main-img-wrapper">
            {!!videoIdList.length &&
            <YouTubePlayer videoIds={videoIdList}
                           setRedirectUrl={setRedirectUrl}
                           paused={paused}
                           pausedVideo={setPaused}/>}
        </div>

        <div className="track-name">{!loading ? tracksList[videoIndex]?.title : <SkeletonItem/>}</div>


        {!!tracksList[videoIndex]?.artists?.length ? tracksList[videoIndex]?.artists?.map(item => {
                return !item?.isHidden ?
                    <Link to={`/artist/${item?.slug}/songs`} key={item?.id}>
                        <div className="track-artist-name track-artist">{item?.fullName}</div>
                    </Link>
                    : <div className="track-artist-name" key={item?.id}>{item?.fullName}</div>
            })
            : <div className="track-artist-name">{tracksList[videoIndex]?.description}</div>


        }
        {!loading ?
            (!!tracksList?.length && <div className="artist-top-song-list">
                <div className="section-header"><span>Top songs</span></div>
                <InfiniteScroll
                    hasMore={tracksList?.hasMore}
                    loadMore={loadMoreItems}
                    className={'tracks-list'}
                    pageStart={0}
                    useWindow={true}
                    initialLoad={false}>
                    {
                        tracksList?.map(item => {
                            return <TrackCard key={item.id}
                                              redirectUrl={`/album/${params?.slug}/track/${item.id}`}
                                              track={item}
                                              playerTracksList={tracksList}
                                              paused={paused}
                                              toggleFavoriteState={toggleFavoriteState}/>

                        })
                    }
                </InfiniteScroll>
            </div>) : <SkeletonTrackCard count={3}/>
        }
    </PageWrapper>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'tracks',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    GetTracks,
    ClearTracks
};

export default connect(mapStateToProps, mapDispatchToProps)(AlbumTracks);
