// import packages
import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {history} from "../../configs/history";
import {useLocation, useParams} from "react-router-dom";
import {FacebookShareButton} from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';

// import assets
import '../../assets/styles/components/cards/track-more-card.scss'
import {ReactComponent as AddPlaylistIcon} from "../../assets/images/icons/ic_add_playlist.svg";
import {ReactComponent as EmptyHeart} from "../../assets/images/icons/ic_heart.svg";
import {ReactComponent as AddToPlaylistIcon} from "../../assets/images/icons/ic_addplaylist.svg";
import {ReactComponent as RemoveFromPlaylistIcon} from "../../assets/images/icons/ic_remove.svg";
import {ReactComponent as ShareSongIcon} from "../../assets/images/icons/Ic_share.svg";
import {ReactComponent as GoToArtistIcon} from "../../assets/images/icons/ic_artist.svg";
import {ReactComponent as GoToAlbumIcon} from "../../assets/images/icons/ic_album.svg";
import {ReactComponent as FacebookIcon} from '../../assets/images/icons/ic_facebook_share.svg';
import {ReactComponent as CopyIcon} from '../../assets/images/icons/ic_copy_link.svg';
import {ReactComponent as ReportIcon} from '../../assets/images/icons/ic_report.svg';

//import components
import UserPlayListCard from "./UserPlaylistCard";
import {InputGroup} from "../uIElements/inputGroup";
import {LinkButton} from "../uIElements/buttons";
import ReportModal from "../uIElements/ReportModal";

//import utils
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {
    AddToFavorites,
    RemoveFromFavorites,
    GetPlaylists,
    AddTrackToPlaylist,
    AddPlaylist,
    removeFromListened
} from "../../redux/actions";
import {getPropsFromState} from "../../redux/mapStateToProps";


function TrackMoreCard(props) {
    const {track, userPlaylists, isLoggedIn, trackMoreCardOpen, podcastId} = props;
    const [playlistsIsOpen, setPlaylistsOpen] = useState(false);
    const [shareWrapperIsOpen, setShareWrapperOpen] = useState(false);
    const [newPlaylist, setNewPlaylist] = useState("");
    const [isOpenAddPlaylistWrapper, setOpenAddPlaylistWrapper] = useState(false);
    const [errors, setErrors] = useState({});
    const [copied, setCopied] = useState(false);
    const [isReportVisible, setIsReportVisible] = useState(false);
    const [mouseEnter, setMouseEnter] = useState(false);

    //Hooks
    const ref = useRef();
    const params = useParams();
    const location = useLocation();

    useEffect(() => {
        !userPlaylists?.list?.length && props.GetPlaylists()
        window.addEventListener('click', onClicked)
        return () => {
            window.removeEventListener('click', onClicked)
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (trackMoreCardOpen && mouseEnter) {
                props.toggleTrackMoreCard()
            }
        });
    }, [trackMoreCardOpen, mouseEnter])

    //functions
    function onClicked() {
        props.toggleTrackMoreCard();
    }

    function addToPlaylist(e) {
        e.preventDefault();
        e.stopPropagation();
        setPlaylistsOpen(true);
    }

    function shareSong(e) {
        e.preventDefault();
        e.stopPropagation();
        setShareWrapperOpen(true);
    }

    function removeFromPlaylist() {
        props.removeFromPlaylist(track.id, props.indexInPlayerList)
        onClicked()
    }

    function removeFromListened() {
        props.removeFromListened(track.id)
        onClicked()
    }


    function navigateToPage(e, route) {
        e.preventDefault();
        e.stopPropagation();
        history.push({
            pathname: route,
        })
    }

    function prevent(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    function openAddPlaylistWrapper() {
        setOpenAddPlaylistWrapper(!isOpenAddPlaylistWrapper)
    }

    function backToActions() {
        setOpenAddPlaylistWrapper(false)
        setPlaylistsOpen(false);
        setShareWrapperOpen(false);
        setNewPlaylist("")
    }

    function createPlaylistAndAddTrack() {
        const requiredFields = ['newPlaylist'];
        const reqData = {}
        let isValid = true;

        requiredFields.forEach(key => {
            if (!newPlaylist) {
                isValid = false;
                setErrors({
                    ...errors,
                    [key]: true,
                })
            }
        });
        if (isValid) {
            reqData.name = newPlaylist;
            reqData.name && props.AddPlaylist(reqData).then((res) => {

                props.AddTrackToPlaylist(res.id, track.id, track.coverImage)
                setNewPlaylist("")
                setOpenAddPlaylistWrapper(false)
                props.toggleTrackMoreCard();
            })
        }

    }

    const getInputValues = (e) => {
        setNewPlaylist(e.target.value)
    };
    const goToArtist = (e) => {
        artist && navigateToPage(e, `/artist/${artist?.slug}/songs`)
        onClicked()
    };
    const goToAlbum = (e) => {
        track?.albums && !!track?.albums?.length && track?.albums.find(item => !item.isHidden) &&
        navigateToPage(e, `/album/${track?.albums.find(item => !item.isHidden)?.slug}`)
        onClicked()
    };
    const toggleReport = () => {
        setIsReportVisible(!isReportVisible)
    };
    console.log(track, 'track');
    const artist = track?.artists.find(item => !item.isHidden)
    const albumSlug = track?.albums.length && track?.albums.find(item => item?.slug === params?.slug) ? params?.slug : track?.albums.find(item => !item.isHidden)?.slug
    const shearLink = !podcastId ? `http://youtop.app/album/${albumSlug}/track/${track.slug}`
        : `https://youtop-stage.4steps.am/podcast/${podcastId}`
    return <div className={`track-dropdown-menu ${playlistsIsOpen ? "dropdown-playlists" : ""}`}
                ref={ref}
                onMouseLeave={() => setMouseEnter(true)}
                onMouseEnter={() => setMouseEnter(false)}
                onClick={prevent}>
        {!playlistsIsOpen && !shareWrapperIsOpen ? <div>
                <div className="track-dropdown-description">
                    <img src={generateImageMediaUrl(track?.coverImage?.path)} alt=""/>
                    <div>
                        <div className="track-item-title">{track?.title}</div>
                        <div className="track-item-description ">
                            {!!track?.artists?.length && track?.artists?.map((item, index) => {
                                return !item?.isHidden ?
                                    <span onClick={(e) => navigateToPage(e, `/artist/${item?.slug}/songs`)}
                                          className="track-artist"
                                          key={item.id}>
                            {item?.fullName}
                                        {index + 1 < track?.artists?.length && <span>,&nbsp;</span>}
                    </span>

                                    : <span className="track-item-description"
                                            key={item.id}>
                            {item?.fullName}
                                        {index + 1 < track?.artists?.length && <span>,&nbsp;</span>}
                        </span>
                            })}</div>
                    </div>
                </div>
                <div className="track-dropdown-links">
                    {isLoggedIn ? (track?.isFavorite ?
                            <div className="menu-item" onClick={props.toggleFavorite}>
                                <EmptyHeart className='card-heart' title=''/>
                                Remove from favorites
                            </div>
                            : <div className="menu-item" onClick={props.toggleFavorite}>
                                <EmptyHeart className='card-heart' title=''/>
                                Move to favorites
                            </div>) :
                        <div className="menu-item" onClick={(e) => {
                            navigateToPage(e, '/sign-in')
                        }}>
                            <EmptyHeart className='card-heart' title=''/>
                            Move to favorites
                        </div>
                    }

                    <div className="menu-item" onClick={isLoggedIn ?
                        addToPlaylist :
                        (e) => {
                            navigateToPage(e, '/sign-in')
                        }}>
                        <AddToPlaylistIcon className='card-heart' title=''/>
                        Add to playlist
                    </div>
                    {isLoggedIn && location.pathname.startsWith('/profile/playlist') &&
                        <div className="menu-item" onClick={removeFromPlaylist}>
                            <RemoveFromPlaylistIcon className='card-heart' title=''/>
                            Remove from playlist
                        </div>}
                    <div className="menu-item" onClick={shareSong}>
                        <ShareSongIcon className='card-heart' title=''/>
                        Share song
                    </div>
                    <div onClick={goToArtist}
                         className={`menu-item ${!artist || artist.slug === params?.slug ? "not-active" : ""}`}>
                        <GoToArtistIcon className='card-heart' title=''/>Go to artist
                    </div>
                    <div onClick={goToAlbum}
                         className={`menu-item ${!track?.albums?.length || track?.albums?.[0]?.slug === params?.slug || !track?.albums.find(item => !item.isHidden) ? "not-active" : ""}`}>
                        <GoToAlbumIcon className='card-heart' title=''/>Go to album
                    </div>
                    {isLoggedIn && location.pathname.includes("profile/listened") &&
                        <div className="menu-item" onClick={removeFromListened}>
                            <RemoveFromPlaylistIcon className='card-heart' title=''/>Remove from Listened
                        </div>}
                    <div className="menu-item" onClick={toggleReport}>
                        <ReportIcon className='ReportIcon' title=''/>
                        Report
                    </div>
                </div>
            </div>
            : (playlistsIsOpen && !shareWrapperIsOpen ?
                    <div className="dropdown-playlists-content">
                        <div className="dropdown-playlists-header">
                            <div className="back-wrapper">
                                <img src={require("../../assets/images/icons/ic_arrow_down_dark.svg")} alt=""
                                     onClick={backToActions}/>
                                {!isOpenAddPlaylistWrapper ? <span>Playlists</span> :
                                    <InputGroup
                                        inputType={'input'}
                                        type={"text"}
                                        name={'playlist'}
                                        value={newPlaylist}
                                        placeholder={"Type playlist name"}
                                        error={errors.newPlaylist}
                                        maxLength={100}
                                        onChange={getInputValues}
                                    />
                                }
                            </div>
                            <div className="add-playlist-wrapper">
                                {!isOpenAddPlaylistWrapper ?
                                    <AddPlaylistIcon title={''} onClick={openAddPlaylistWrapper}/> :
                                    <LinkButton title="Save"
                                                cb={createPlaylistAndAddTrack}
                                    />
                                }
                            </div>
                        </div>
                        <div className="playlist-items-wrapper">
                            {userPlaylists && !!userPlaylists?.list?.length && userPlaylists?.list.map(playlist => {
                                return <UserPlayListCard checkPlaylist
                                                         playlist={playlist}
                                                         track={track}
                                                         trackImage={track.coverImage}
                                                         key={playlist.id}
                                                         toggleTrackMoreCard={props.toggleTrackMoreCard}
                                                         addToPlaylist={props.addToPlaylist}/>

                            })}
                        </div>
                    </div> :
                    <div className="dropdown-playlists-content">
                        <div className="dropdown-playlists-header">
                            <div className="back-wrapper" onClick={backToActions}>
                                <img src={require("../../assets/images/icons/ic_arrow_down_dark.svg")} alt=""/>
                                <span>Back</span>
                            </div>
                        </div>
                        <div className="share-wrapper">

                            <CopyToClipboard
                                text={shearLink}
                                className={"copy-wrapper"}
                                onCopy={() => setCopied(true)}
                                onMouseLeave={() => setCopied(false)}>
                                <button onClick={e => {
                                    e.stopPropagation()
                                }}>
                                    <CopyIcon/> Copy url
                                    <div className="copy-text">{copied ? "Copied" : 'Copy'}</div>
                                </button>
                            </CopyToClipboard>

                            <FacebookShareButton url={shearLink}
                                                 quote={`${track.title}`}
                            >
                                <FacebookIcon/> Facebook
                            </FacebookShareButton>
                        </div>
                    </div>
            )
        }
        <ReportModal isReportVisible={isReportVisible}
                     toggleReport={toggleReport}
                     trackId={track.id}/>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'isLoggedIn',
    'userPlaylists'
]);

const mapDispatchToProps = {
    AddToFavorites,
    RemoveFromFavorites,
    GetPlaylists,
    AddTrackToPlaylist,
    AddPlaylist,
    removeFromListened
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackMoreCard);