// import packages
import React from "react";

//Import Images
import '../assets/styles/containers/upload-app.scss';

// Import utils
import {androidUrl, iosUrl} from "../configs/appUrls";
import {getMobileOS} from "../utils/getMobileOS";
import {getBrowserType} from "../utils/getBrowserType";

function UploadApp() {

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    function getAppUrl() {
        let url = androidUrl;
        const mobileOs = getMobileOS();
        if (mobileOs === 'unknown') {
            const browserType = getBrowserType();
            if (browserType === 'Safari') {
                url = iosUrl;
            }
        } else if (mobileOs === 'iOS') {
            url = iosUrl;
        }
        return url;
    }

    return <div className="upload-app-wrapper" style={{height: `calc(var(--vh, 1vh) * 100)`}}>
        <div className="upload-app-content">
            <div className="logo-wrapper">
                <img className={"header-logo"} src={require('../assets/images/logo.svg')} alt=""/>
                <h1 className={"brand-name"}>YouTop</h1>
            </div>
            <div className="upload-app-description">
                Download YouTop for mobile and discover top songs and artists, for free.
            </div>
            <div className="download-btn-wrapper">
                <a href={getAppUrl()}
                   target={'_blank'}
                   rel="noopener noreferrer">
                    <button className={"download-btn"}>
                        Download
                    </button>
                </a>
            </div>
        </div>
        <picture>
            <source media="(max-width:600px)" srcSet={require("../assets/images/login_sm.webp")}/>
            <img src={require("../assets/images/login_lg.webp")} alt={""}
                 className="login-bg"/>
        </picture>
    </div>
}


export default UploadApp;
