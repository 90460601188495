// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link, useHistory, useParams, useLocation} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {FacebookShareButton} from "react-share";

//import Assets
import '../assets/styles/containers/artist-details.scss';
import {ReactComponent as PlayIcon} from "../assets/images/playerBar-icons/ic_play_white.svg";
import {ReactComponent as FillHeart} from "../assets/images/playerBar-icons/ic_heart_fill.svg";
import {ReactComponent as EmptyHeart} from "../assets/images/playerBar-icons/ic_heart_empty.svg";
import {ReactComponent as FacebookIcon} from "../assets/images/icons/ic_facebook_share.svg";
import {ReactComponent as CopyIcon} from "../assets/images/icons/ic_copy_link.svg";
import {ReactComponent as ShareSongIcon} from "../assets/images/playerBar-icons/ic_share.svg";

//import components
import CustomTabs from "../components/uIElements/Tabs";
import TrackCard from "../components/cards/TrackCard";
import MetaTags from "../components/MetaTags";
import {LinkButton} from "../components/uIElements/buttons";
import SkeletonTrackCard from "../components/skeletonCards/SkeletonTrackCard";
import SquareSkeletonCard from "../components/skeletonCards/SquareSkeletonCard";
import {SkeletonItem} from "../components/uIElements/SkelatonItem";
import {PageSkeleton} from "./PageSkeleton";
import {CardComponents} from "../components/cards/BaseCard";

//import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetTracks, ClearTracks, setCurrentPlayedTrack, togglePlayState} from "../redux/actions";
import {GetArtistsById, AddArtistToFavorites, RemoveArtistFromFavorites} from "../redux/actions/artistsActions";
import {GetAlbums} from "../redux/actions/albumsActions";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import {artistsDetailsTabs} from "../constants/constants";
import {Image} from "../components/uIElements/Image";
import {ReactComponent as PauseIcon} from "../assets/images/playerBar-icons/ic_pause_white.svg";
import {resizeImage} from "../utils/resizeImage";

function ArtistDetails(props) {
    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const {isLoggedIn} = props;
    const [artist, setArtist] = useState({
        ...location.state?.track || {},
    });
    const [artistTracks, setArtistTracks] = useState({
        list: [],
        hasMore: false
    });
    const [artistAlbums, setArtistAlbums] = useState({
        list: [],
        hasMore: false
    });
    let {tab: currentTab} = params;
    const [isFetching, setFetching] = useState(false);
    const [artistsLoading, setArtistsLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [shareWrapperIsOpen, setShareWrapperOpen] = useState(false);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        props.GetArtistsById(params?.slug)
            .then((res) => {
                const data = res && res.data;
                setArtist(data);
                setArtistAlbums({
                    list: data.albums,
                    hasMore: data?.albums.length === 20
                });
                setArtistsLoading(false);
                props.GetTracks({artist: data?.id, getResponse: true, isTop: true})
                    .then((res) => {
                        const tracks = res && res.data;
                        setArtistTracks({
                            list: tracks,
                            hasMore: tracks?.length === 20
                        });
                        setLoading(false)
                    });

            });
        window.addEventListener('click', onClicked);
        return () => {
            window.removeEventListener('click', onClicked)
        }

    }, [params.slug]);

    function onClicked() {
        setShareWrapperOpen(false);
    }

    function toggleShareWrapper(e) {
        e && e.preventDefault();
        e && e.stopPropagation();
        setShareWrapperOpen(!shareWrapperIsOpen);

    }

    function toggleFavorite() {
        if (isLoggedIn) {
            if (!isFetching) {
                setFetching(true);
                const favoritePromise = artist.isFavorite ? props.RemoveArtistFromFavorites(artist.id) : props.AddArtistToFavorites(artist.id);
                favoritePromise.finally(() => {
                    setFetching(false);
                    setArtist({
                        ...artist,
                        isFavorite: !artist.isFavorite,
                        likeCount: artist.isFavorite ? artist.likeCount - 1 : artist.likeCount + 1
                    })
                })
            }
        }
    }

    const loadMoreTracks = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetTracks({
                artist: artist.id,
                reset: false,
                offset: artistTracks?.list?.length,
                getResponse: true,
                isTop: true
            }).then((res) => {
                setArtistTracks({
                    list: artistTracks?.list.concat(res.data),
                    hasMore: res.data?.length === 20
                })
            }).finally(() => {
                setFetching(false);
            });
        }
    };

    const loadMoreAlbums = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetAlbums({
                artist: artist.id,
                reset: false,
                offset: artistAlbums?.list?.length,
                getResponse: true
            }).then((res) => {
                setArtistAlbums({
                    list: artistAlbums?.list.concat(res.data),
                    hasMore: res.data?.length === 20
                })
            }).finally(() => {
                setFetching(false);
            });
        }
    };

    const toggleFavoriteState = (id, favorite) => {
        setArtistTracks({
            ...artistTracks,
            list: artistTracks?.list?.map(track => track.id === id ?
                {
                    ...track,
                    isFavorite: favorite,
                    likeCount: favorite ? track?.likeCount - 1 : track?.likeCount + 1
                } : track
            )
        })
    }

    const changeTab = (tab) => {
        history.push(`/artist/${params?.slug}/${tab}`)
    };

    const setSkeletonCount = () => {
        if (window.innerWidth > 550) {
            return 4
        } else if (window.innerWidth > 400) {
            return 3
        } else {
            return 2
        }
    }

    function setTrack() {
        if (artistTracks?.list?.length) {
            if (location?.pathname.includes(props?.type)) {
                togglePlayState(!props.playState)
            } else {
                setCurrentPlayedTrack(artistTracks?.list[0], artistTracks?.list, location?.pathname, 0)
                togglePlayState(true)
            }
        }
    }

    return <PageSkeleton withPadding>
        <MetaTags title={artist?.fullName}
                  image={resizeImage(generateImageMediaUrl(artist?.profileImage?.path, "user"), 200, 200)}/>
        <div className="artist-header">
            <div className="img-wrapper">
                <Image url={artist?.profileImage?.path} loading={artistsLoading} circle type={"user"}/>
            </div>
            <div className="artist-description-content">
                <div style={{width: "100%"}}>
                    <div className="page-name">ARTIST</div>
                    <div className="artist-name">{!artistsLoading ? artist?.fullName :
                        <div style={{width: "100%", height: "35px", marginTop: "15px"}}>
                            <SkeletonItem/>
                        </div>}
                    </div>
                    <div
                        className="artist-description">{!artistsLoading ? (artist?.tracksCount > 0 ? artist?.tracksCount : "no") + " songs" :
                        <div style={{width: "50%", height: "25px", marginTop: "15px", marginBottom: "5px"}}>
                            <SkeletonItem/>
                        </div>}
                    </div>
                </div>
                <div className="btns-wrapper">
                    <span className="play-all" onClick={setTrack}>
                        {location.pathname.includes(props?.type) && props?.playState ?
                            <PauseIcon title={''} className="play-icon"/> :
                            <PlayIcon title={''} className="play-icon"/>}
                        Play
                    </span>
                    <div className="heart-dropdown-wrapper" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleFavorite()
                    }
                    }>
                        <div className={`heart-wrapper ${artist?.likeCount > 0 ? "have-likes" : ""}`}>
                            {artist?.isFavorite ?
                                <FillHeart className='card-heart fill-heart' title=''/>
                                : <EmptyHeart className='card-heart' title=''/>}
                            {artist?.likeCount !== 0 && <div className="like-count">{artist?.likeCount}</div>}
                        </div>
                        {!isLoggedIn && <div className={`heart-dropdown-content`}>
                            <span>Please sign in to save your favorites</span>
                            <LinkButton title="Sign in"
                                        link={"/sign-in"}
                            />
                        </div>
                        }
                    </div>
                    <div className="share-btn" onClick={toggleShareWrapper}>
                        <ShareSongIcon title=''/>
                        {shareWrapperIsOpen && <div className="dropdown-content">
                            <div className="share-wrapper">

                                <CopyToClipboard text={`http://youtop.app/artist/${artist?.slug}/songs`}
                                                 className={"copy-wrapper"}
                                                 onCopy={() => setCopied(true)}
                                                 onMouseLeave={() => setCopied(false)}>
                                    <button onClick={e => {
                                        e.stopPropagation()
                                    }}>
                                        <CopyIcon/> Copy url
                                        <div className="copy-text">{copied ? "Copied" : 'Copy'}</div>
                                    </button>
                                </CopyToClipboard>

                                <FacebookShareButton url={`http://youtop-front.4steps.am/artist/${artist?.slug}/songs`}
                                    // quote={`${artist.fullName}`}
                                >
                                    <FacebookIcon/> Facebook
                                </FacebookShareButton>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        <CustomTabs tabsList={artistsDetailsTabs}
                    changeTab={changeTab}
                    currentTab={currentTab}
        />

        {currentTab === artistsDetailsTabs.songs.id &&
        (!loading ?
                (!!artistTracks?.list?.length ? <div className="search-tracks-wrapper">
                        <InfiniteScroll
                            hasMore={artistTracks?.hasMore}
                            loadMore={loadMoreTracks}
                            className={'tracks-list'}
                            pageStart={0}
                            useWindow={true}
                            initialLoad={false}>
                            {
                                artistTracks?.list?.map((track, index) => {
                                    return <TrackCard track={track}
                                                      playerTracksList={artistTracks?.list}
                                                      indexInPlayerList={index}
                                                      key={track.id}
                                                      toggleFavoriteState={toggleFavoriteState}/>
                                })
                            }
                        </InfiniteScroll>

                    </div>
                    : <div className="no-result">
                        <span>This artist have no tracks</span>
                    </div>) : <SkeletonTrackCard count={3}/>
        )}
        {currentTab === artistsDetailsTabs.albums.id &&
        (!loading ?
            (!!artistAlbums?.list?.length ? <>
                    <InfiniteScroll
                        hasMore={artistAlbums?.hasMore}
                        loadMore={loadMoreAlbums}
                        className={'base-cards-list'}
                        pageStart={0}
                        useWindow={true}
                        initialLoad={false}>
                        {
                            artistAlbums?.list?.map(album => {
                                return <CardComponents.AlbumCard album={album} key={album.id}/>
                            })
                        }
                    </InfiniteScroll>
                </>
                : <div className="no-result">
                    <span>This artist have no albums</span>
                </div>) : <div className={'base-cards-list'}>
                <SquareSkeletonCard count={setSkeletonCount()}/>
            </div>)
        }
    </PageSkeleton>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'requestLoading',
        'isLoggedIn',
        'tracks',
        'albums',
        'type',
        'playState'
    ])
};

const mapDispatchToProps = {
    GetAlbums,
    GetTracks,
    GetArtistsById,
    ClearTracks,
    AddArtistToFavorites,
    RemoveArtistFromFavorites,
    togglePlayState
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtistDetails);
