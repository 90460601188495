// import packages
import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
//Import components
import {SeeAll} from "../../components/uIElements/buttons";

//import utils
import {GetAlbums, CleaAlbums} from "../../redux/actions/albumsActions";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";
import SquareSkeletonCard from "../../components/skeletonCards/SquareSkeletonCard";
import {CardComponents} from "../../components/cards/BaseCard";


function Albums(props) {
    const location = useLocation();
    const params = useParams();
    const inGenres = location.pathname.includes('genres');
    const path = inGenres && location.pathname.replace('genres', 'albums/genres');

    useEffect(() => {
    }, []);

    const setSkeletonCount = () => {
        if (window.innerWidth > 800) {
            return 4
        }else {
            return 3
        }
    };

    const {data, loading} = props;
    const albumsList = window.innerWidth > 800 ? data?.slice(0,4) : data?.slice(0,3)

    return <div className="homepage-albums-wrapper">
        <div className='section-title'>
            <span>New Albums</span>
            {data?.length > 4 && <SeeAll link={`${inGenres ? path : 'home/albums'}`}/>}
        </div>
        {!loading ? (!!albumsList?.length && <div className="base-cards-list">
            {albumsList?.map(album => {
                return <CardComponents.AlbumCard album={album} key={album.id}/>
            })}
        </div>) : <div className={'base-cards-list'}>
            <SquareSkeletonCard count={setSkeletonCount()} withoutDescription/>
        </div>}
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'albums',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    GetAlbums,
    CleaAlbums
};

export default connect(mapStateToProps, mapDispatchToProps)(Albums);
