// import packages
import React from "react";

// import assets
import '../../assets/styles/components/cards/base-card.scss'
import {SkeletonGroup, SkeletonItem} from "../uIElements/SkelatonItem";

function SquareSkeletonCard(props) {
    const {count, withoutTitle, withoutDescription} = props;
    return <SkeletonGroup count={count} square>
        <div className="card-link">
            <div className="card-item">
                <div className="img-wrapper">
                    <div style={{width: "100%", height: "100%", position: "absolute", top: "0"}}>
                        <SkeletonItem/>
                    </div>
                </div>
                {!withoutTitle && <div className="card-item-content">
                    <div className="card-title-wrapper">
                        <SkeletonItem/>
                    </div>
                </div>}
                {withoutDescription && <div className="card-item-content">
                    <div className="card-item-description"><SkeletonItem/></div>
                </div>}
            </div>
        </div>
    </SkeletonGroup>
}

export default SquareSkeletonCard;