// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";

//Import components
import SquareSkeletonCard from "../components/skeletonCards/SquareSkeletonCard";
import {CardComponents} from "../components/cards/BaseCard";
import {PageSkeleton} from "./PageSkeleton";

//import utils
import {GetAlbums, CleaAlbums} from "../redux/actions/albumsActions";
import {GetTracks} from "../redux/actions";
import {GetArtists} from "../redux/actions/artistsActions";
import {getPropsFromState} from "../redux/mapStateToProps";
import {homepageTabs} from "../constants/constants";
import TrackCard from "../components/cards/TrackCard";
import SkeletonTrackCard from "../components/skeletonCards/SkeletonTrackCard";
import InfiniteScroll from "react-infinite-scroller";

function GenreTypes(props) {
    const {data, genres} = props;
    const params = useParams();
    const [isFetching, setFetching] = useState(false);
    const [list, setList] = useState({
        tracks: {
            list: [],
            hasMore: false
        },
        artists: {
            list: [],
            hasMore: false
        },
        albums: {
            list: [],
            hasMore: false
        }
    });

    const [loading, setLoading] = useState({
        tracks: true,
        artists: true,
        albums: true,
    });
    const genreId = genres.find(item => item?.slug === params?.slug).id;
    let currentTab = params?.subGenreId ? params?.subGenreId : genreId;
    let currentType = params?.type ? params?.type : "all";

    useEffect(() => {
        getGenreItems(currentTab);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    const setSkeletonCount = () => {
        if (window.innerWidth > 800) {
            return 8
        } else {
            return 6
        }
    };

    const getGenreItems = (genre) => {
        const genreIds = [];
        genreIds.push(genre);
        const genreById = genres.find(item => item?.id === genre);
        if (genreById) {
            genreById.subGenres && genreById.subGenres.length && genreById.subGenres.forEach(item => genreIds.push(item?.id))
        }
        currentType === homepageTabs.tracks.id && props.GetTracks({genreIds: genreIds.join(","), getResponse: true}).then((res) => {
            setList((list) => {
                return {
                    ...list,
                    tracks: {
                        ...list.tracks,
                        list: res.data,
                        hasMore: res.hasMore,
                    }
                }
            })
            setLoading((loading) => {
                return {
                    ...loading,
                    tracks: false
                }
            })
        });
        currentType === homepageTabs.artists.id && props.GetArtists({genreIds: genreIds.join(","), getResponse: true}).then((res) => {
            setList((list) => {
                return {
                    ...list,
                    artists: {
                        ...list.artists,
                        list: res.data,
                        hasMore: res.hasMore,
                    }
                }
            })
            setLoading((loading) => {
                return {
                    ...loading,
                    artists: false
                }
            })
        });
        currentType === homepageTabs.albums.id && props.GetAlbums({genreIds: genreIds.join(","), getResponse: true}).then((res) => {
            setList((list) => {
                return {
                    ...list,
                    albums: {
                        ...list.albums,
                        list: res?.data,
                        hasMore: res?.hasMore,
                    }
                }
            })
            setLoading((loading) => {
                return {
                    ...loading,
                    albums: false
                }
            })
        })
    };

    const toggleFavoriteState = (id, favorite) => {
        setList((list) => {

            return {
                ...list,
                tracks: list.tracks.map(track => track.id === id ? {
                    ...track,
                    isFavorite: favorite
                } : track),
            }
        })
    };

    const loadMoreItems = () => {
        const genreIds = [];
        genreIds.push(genreId);
        const genreById = genres.find(item => item?.id === genreId);
        if (genreById) {
            genreById.subGenres && genreById.subGenres.length && genreById.subGenres.forEach(item => genreIds.push(item?.id))
        }
        if (!isFetching) {
            setFetching(true);
            currentType === homepageTabs.albums.id && props.GetAlbums({
                    text: props.text,
                    genreIds: genreIds.join(","),
                    reset: false,
                    getResponse: true,
                    offset: list?.albums?.list?.length
                }).then((res) => {
                    setList((list) => {
                        return {
                            ...list,
                            albums: {
                                ...list.albums,
                                list: res.data.reset ? res.data : [...list.albums.list, ...res.data],
                                hasMore: res.hasMore,
                            }
                        }
                    })
                }).finally(() => {
                    setFetching(false);
                });
            currentType === homepageTabs.tracks.id && props.GetTracks({
                text: props.text,
                genreIds: genreIds.join(","),
                reset: false,
                getResponse: true,
                offset: list?.tracks?.list?.length
            }).then((res) => {
                setList((list) => {
                    return {
                        ...list,
                        tracks: {
                            ...list.tracks,
                            list: res.data.reset ? res.data : [...list.tracks.list, ...res.data],
                            hasMore: res.hasMore,
                        }
                    }
                })
            }).finally(() => {
                setFetching(false);
            });
            currentType === homepageTabs.artists.id && props.GetArtists({
                text: props.text,
                genreIds: genreIds.join(","),
                reset: false,
                getResponse: true,
                offset: list?.artists?.list?.length
            }).then((res) => {
                setList((list) => {
                    return {
                        ...list,
                        artists: {
                            ...list.artists,
                            list: res.data.reset ? res.data : [...list.artists.list, ...res.data],
                            hasMore: res.hasMore,
                        }
                    }
                })
            }).finally(() => {
                setFetching(false);
            });
        }
    };

    return <PageSkeleton withPadding>
        <div className='section-title'>
            {params?.slug && <span>{params?.slug}</span>}
        </div>
        {currentType === homepageTabs.albums.id && <>
            {!loading?.albums ? (!!list?.albums?.list?.length &&
                <InfiniteScroll
                        hasMore={list?.albums?.hasMore}
                        loadMore={loadMoreItems}
                        className={'base-cards-list'}
                        pageStart={0}
                        useWindow={true}
                        initialLoad={false}>
                        {list?.albums?.list?.map(album => {
                            return <CardComponents.AlbumCard album={album} key={album.id}/>
                        })
                        }
                    </InfiniteScroll>)
                : <div className={'base-cards-list'}>
                    <SquareSkeletonCard count={setSkeletonCount()} withoutDescription/>
                </div>
            }</>}
        {currentType === homepageTabs.tracks.id && <>
            {!loading?.tracks ? (!!list?.tracks?.list?.length &&
                <div className="base-cards-list">
                    <InfiniteScroll
                        hasMore={list?.tracks?.hasMore}
                        loadMore={loadMoreItems}
                        className={'tracks-list'}
                        pageStart={0}
                        useWindow={true}
                        initialLoad={false}>
                        {list?.tracks?.list?.map((track, index) => {
                            return <TrackCard key={track.id}
                                              track={track}
                                              playerTracksList={data}
                                              indexInPlayerList={index}
                                              toggleFavoriteState={toggleFavoriteState}
                            />
                        })
                        }
                    </InfiniteScroll>
                </div>)
                : <SkeletonTrackCard count={setSkeletonCount()}/>
            }</>}
        {currentType === homepageTabs.artists.id && <>{
            !loading?.artists ? (!!list?.artists?.list?.length &&
                <InfiniteScroll
                    hasMore={list?.artists?.hasMore}
                    loadMore={loadMoreItems}
                    className={'base-cards-list'}
                    pageStart={0}
                    useWindow={true}
                    initialLoad={false}>
                    {list?.artists?.list?.map(artist => {
                        return <CardComponents.ArtistCard artist={artist} key={artist.id}/>
                    })
                    }
                </InfiniteScroll>)
                : <div className={'base-cards-list circle-cards'}>
                    <SquareSkeletonCard count={setSkeletonCount()}/>
                </div>
        }</>}
    </PageSkeleton>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'albums',
        'requestLoading',
        'genres',
    ])
};

const mapDispatchToProps = {
    GetAlbums,
    CleaAlbums,
    GetArtists,
    GetTracks,
};

export default connect(mapStateToProps, mapDispatchToProps)(GenreTypes);