import { PODCAST_CONSTS, TRACKS_CONSTS } from "../constants";

export const initialState = {
    userPodcasts: {
        list: [],
        text: '',
        hasMore: false,
    },
   podcastTracks: {
        list: [],
        text: '',
        hasMore: false,
    },
    podcasts: {
        list: [],
        text: '',
        hasMore: false,
    },
    podcastsListTracks: {
        list: [],
        text: '',
        hasMore: false,
    },
};
export default (state = initialState, action) => {
    switch (action.type) {
        case PODCAST_CONSTS.GET_PODCASTS:
            return {
                ...state,
                userPodcasts: {
                    list: action.payload,
                }
            };
        case PODCAST_CONSTS.GET_PODCASTS_LIST:
            return {
                ...state,
                podcasts: {
                    list: action.payload,
                }
            };
        case PODCAST_CONSTS.GET_PODCASTS_LIST_TRACK:
            return {
                ...state,
                podcastsListTracks: {
                    list: action.payload,
                }
            };
        case PODCAST_CONSTS.ADD_PODCAST:
            const updatedList = {
                list: [action.payload].concat(state.userPodcasts.list)
            };
            return {
                ...state,
                userPodcasts: updatedList,
            };
        case PODCAST_CONSTS.ADD_TRACK:
            return {
                ...state,
                podcastTracks: {
                    ...state.podcastTracks,
                    list: [action.payload.data, ...state.podcastTracks.list]
                },
                userPodcasts: state.userPodcasts.list.map(item => item.id === action.payload?.podcastId ?{
                    ...item,
                    podcastsCount: item.podcastsCount + 1
                } : item)
            };
        case PODCAST_CONSTS.UPDATE_PODCAST:
            const updatedTracksLists = {
                list: state.userPodcasts.list.map(item => item.id === action.payload?.id ? action.payload.data : item)
            };
            return {
                ...state,
                userPodcasts: updatedTracksLists,
            };
        case PODCAST_CONSTS.DELETE_PODCAST:
            const updatedPodcasts = {
                list: state.userPodcasts.list.filter(podcast => podcast.id !== action.payload)
            };
            return {
                ...state,
                userPodcasts: updatedPodcasts,
            };



            //////////////////////TRACKS///////////////////


        case PODCAST_CONSTS.GET_PODCAST_TRACKS:
            return {
                ...state,
                podcastTracks: {
                    ...state.podcastTracks,
                    list: action.payload,
                }
            };
        case TRACKS_CONSTS.TOGGLE_TRACK_FAVORITE:
            return {
                ...state,
                podcastsListTracks: {
                    ...state.podcastsListTracks,
                    list: state.podcastsListTracks?.list?.map(item => item.id === action.payload.id ?
                        {
                            ...item,
                            isFavorite: action.payload.likeState
                        } : item
                    )
                },
            };
        case PODCAST_CONSTS.REMOVE_TRACK_FROM_PODCAST:
            return {
                ...state,
                podcastTracks: {
                    ...state.podcastTracks,
                    list: state.podcastTracks.list.filter(track => track.id !== action.payload.trackId)
                },
                userPodcasts: state.userPodcasts.list.map(item => item.id === action.payload?.podcastId ?{
                    ...item,
                    podcastsCount: item.podcastsCount - 1
                } : item)
            };

        default:
            return state;
    }
}
