// import packages
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {connect} from "react-redux";

// Import styles
import "../assets/styles/containers/homepage.scss"

// Import Components
import {PageSkeleton} from "./PageSkeleton";
import Categories from "./Categories";
import Playlists from "./homepage/Playlists";
import Tracks from "./homepage/Tracks";
import Albums from "./homepage/Albums";
import Artists from "./homepage/Artists";
import PlaylistsTab from "./homepageTabs/PlaylistsTab";
import TracksTab from "./homepageTabs/TracksTab";
import ArtistsTab from "./homepageTabs/ArtistsTab";
import AlbumsTab from "./homepageTabs/AlbumsTab";

// Import utils
import {homepageTabs} from "../constants/constants";
import {GetTracks, GetSections, GetAlbums, GetAdminPlaylists, GetArtists, GetPodcastsList,} from "../redux/actions";
import {getPropsFromState} from "../redux/mapStateToProps";
import tracks from "../redux/reducers/tracks";
import PodcastTab from "./homepageTabs/PodcastTab";
import Podcasts from "./homepage/Podcasts";

function Homepage(props) {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState({
        tracks: true,
        playlists: true,
        artists: true,
        albums: true,
        sections: true,
        podcasts: true,
    });

    useEffect(() => {
        props.GetSections({sectionsLimit: 10})
            .then(() => {
                setStateLoading("sections")
            });
        props.GetAdminPlaylists()
            .then(() => {
                setStateLoading("playlists")
            })
        props.GetTracks({getResponse: true}).then((res) => {
            setList(res.data)
            setStateLoading("tracks")
        });
        props.GetAlbums({ newReleased: true})
            .then(() => {
                setStateLoading("albums")
            });
        props.GetArtists().then(() => {
            setStateLoading("artists")
        })
        /*      props.GetPodcastsList()
            .then(() => {
                setStateLoading("podcasts")
            })*/
    }, []);

    /*
    * todo
    *  see toggleFavoriteState function
    * */
    const toggleFavoriteState = (id, favorite) => {
        setList((list) => {
            return list.map(track => track.id === id ? {
                ...track,
                isFavorite: favorite
            } : track)
        })
    };


    const setStateLoading = (name) => {
        setLoading((loading) => {
            return {
                ...loading,
                [name]: false
            }
        })
    }

    const params = useParams();
    let currentTab = params?.tab ? params?.tab : "all";
    const {albums, artists, sections, adminPlaylists, podcasts} = props
    // console.log(podcasts, 'podcasts');
    return <div className="homepage-wrapper">
        <div>
            <Categories/>
        </div>
        <PageSkeleton
            rightContent={
                <ins className={"adsbygoogle"}
                     style={{display: "block", width: 200}}
                     data-ad-format={"auto"}
                     data-ad-client={"ca-pub-4622888816810460"}
                     data-ad-slot={"2546696159"}
                     data-full-width-responsive={true}/>
                // <ins className={"adsbygoogle"}
                // style={{ display: "block" }}
                // data-ad-format={"fluid"}
                // data-ad-layout-key={"-gw-15+18-5s+ft"}
                // data-ad-client={"ca-pub-4622888816810460"}
                // data-ad-slot={"3662630413"}/>
            }
        >


            {currentTab === homepageTabs.all.id && <>
                {/*<SliderBox/>*/}
                {/*<Podcasts data={podcasts?.list}
                          loading={loading.podcasts}/>*/}
                <Playlists data={adminPlaylists?.list}
                           loading={loading.playlists}/>
                <Tracks data={list}
                        loading={loading.tracks}
                        toggleFavoriteState={toggleFavoriteState}/>
                <Albums data={albums?.list}
                        loading={loading.albums}/>
                <Artists data={artists?.list}
                         loading={loading.artists}/>
            </>}
            {currentTab === homepageTabs.playlists.id &&
                <PlaylistsTab data={adminPlaylists}
                              loading={loading.playlists}/>
            }
            {currentTab === homepageTabs.tracks.id &&
                <TracksTab data={sections}
                           loading={loading.sections}
                           toggleFavoriteState={toggleFavoriteState}/>
            }
            {currentTab === homepageTabs.artists.id &&
                <ArtistsTab data={artists}
                            loading={loading.artists}/>
            }
            {currentTab === homepageTabs.albums.id &&
                <AlbumsTab data={albums}
                           loading={loading.albums}/>
            }
            {/*{currentTab === homepageTabs.podcasts.id &&
                <PodcastTab data={podcasts}
                           loading={loading.podcasts}/>
            }*/}
        </PageSkeleton>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'genres',
    'artists',
    'albums',
    'adminPlaylists',
    'sections',
    'podcasts',
]);

const mapDispatchToProps = {
    GetTracks,
    GetSections,
    GetAlbums,
    GetAdminPlaylists,
    GetArtists,
    GetPodcastsList
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
