// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

// Import styles
import "../assets/styles/containers/notifications.scss"

// Import Components
import {PageSkeleton} from "./PageSkeleton";
import NotificationCard from "../components/cards/NotificationCard";
import SkeletonTrackCard from "../components/skeletonCards/SkeletonTrackCard";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetNotifications, ToggleNotification} from "../redux/actions";
import {SetAllNotificationsAsOpened, DeleteAllNotifications} from "../socket/emitters";

function Notifications(props) {
    const {notificationsList, requestLoading, notificationHasMore} = props;
    const [isFetching, setFetching] = useState(false);

    useEffect(() => {
        props.GetNotifications();
        SetAllNotificationsAsOpened();
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    const setSkeletonCount = () => {
        if (window.innerWidth > 800) {
            return 16
        } else {
            return 12
        }
    };

    const loadMoreItems = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetNotifications({
                reset: false,
                offset: notificationsList?.length
            }).finally(() => {
                setFetching(false);
            });
        }
    };

    return <PageSkeleton>
        <div className="notifications">
            <div className='section-title'>
                <span>Notifications</span>
                <div className="delete-all" onClick={DeleteAllNotifications}>Clear All</div>
            </div>
            <div className="notifications-list">
                {!requestLoading ? (!!notificationsList?.length ? <InfiniteScroll
                    hasMore={notificationHasMore}
                    loadMore={loadMoreItems}
                    pageStart={0}
                    useWindow={true}
                    initialLoad={false}>
                    {
                        notificationsList?.map(item => {
                            return <NotificationCard data={item} key={item?.id}/>
                        })
                    }
                </InfiniteScroll> : <div className="no-result">
                    <span>You do not have notifications yet</span>
                </div>) : <div className={'base-cards-list'} style={{flexDirection: "column"}}>
                    <SkeletonTrackCard count={setSkeletonCount()}/>
                </div>}
            </div>
        </div>
    </PageSkeleton>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'requestLoading',
    'notificationsList',
    'notificationHasMore',
]);

const mapDispatchToProps = {
    GetNotifications,
    ToggleNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);