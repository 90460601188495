// import packages
import React from "react";
import {Link} from "react-router-dom";

//Import components
import {SeeAll} from "../uIElements/buttons";
import PlayListCard from "../cards/PlayListCard";
import TrackCard from "../cards/TrackCard";

//import utils
import {CardComponents} from "../cards/BaseCard";


function All(props) {
    const {albums, tracks, sections, artists,podcasts, className, text} = props;

    return <section className={className}>
        {!!podcasts?.length && <div className="search-albums-wrapper">
            <div className={'section-header'}>
                <span>Podcasts</span>
                {podcasts?.length > 3 && <SeeAll link={`/search/podcasts/${text}`}/>}
            </div>
            <div className="base-cards-list">
                {podcasts?.map(podcast => {
                    return <CardComponents.PodcastCard podcast={podcast} key={podcast.id}/>
                })
                }</div>
        </div>}
        {!!albums?.length && <div className="search-albums-wrapper">
            <div className={'section-header'}>
                <span>Albums</span>
                {albums?.length > 3 && <SeeAll link={`/search/albums/${text}`}/>}
            </div>
            <div className="base-cards-list">
                {albums?.map(album => {
                    return <CardComponents.AlbumCard album={album} key={album.id}/>
                })
                }</div>
        </div>}

        {!!tracks?.length && <div className="search-tracks-wrapper">
            <div className={'section-header'}>
                <span>Songs</span>
                {tracks?.length > 3 && <SeeAll link={`/search/songs/${text}`}/>}
            </div>
            <div className="tracks-list">
                {
                    tracks?.map((track, index) => {
                        return <TrackCard key={track.id}
                                          playerTracksList={tracks}
                                          indexInPlayerList={index}
                                          track={track}/>
                    })
                }
            </div>
        </div>}

        {!!sections?.length && <div className="search-playlist-wrapper">
            <div className={'section-header'}>
                <span>Playlists</span>
                {sections?.length > 3 && <SeeAll link={`/search/playLists/${text}`}/>}
            </div>
            <div className="playlists-list">
                {
                    sections?.map(list => {
                        return <Link to={{
                            pathname: `/section/${list.id}`,
                            state: {section: list}
                        }}
                                     key={list.id}>
                            <PlayListCard section={list}/>
                        </Link>
                    })
                }
            </div>
        </div>}

        {!!artists?.length && <div className="search-artists-wrapper">
            <div className={'section-header'}>
                <span>Artists</span>
                {artists?.length > 3 && <SeeAll link={`/search/artists/${text}`}/>}
            </div>
            <div className="base-cards-list circle-cards">
                {
                    artists?.map(artist => {
                        return <CardComponents.ArtistCard artist={artist} key={artist.id}/>
                    })
                }
            </div>
        </div>}

        {!tracks?.length && !sections?.length && !artists?.length && !albums?.length &&
        <div className="no-search-result">
            There are no artists, albums and tracks with this name in the TouTop
        </div>
        }
    </section>
}

export default All