// import packages
import React, {useEffect, useRef, useState} from "react";
import {connect} from 'react-redux';
import AudioPlayer from 'react-h5-audio-player';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {FacebookShareButton} from "react-share";
import {Link} from "react-router-dom";

//import styles
import 'react-h5-audio-player/lib/styles.css';
import "../assets/styles/components/audio-player.scss";
import {ReactComponent as LoopOff} from "../assets/images/playerBar-icons/ic_loop.svg";
import {ReactComponent as Prev} from "../assets/images/playerBar-icons/ic_prev.svg";
import {ReactComponent as Next} from "../assets/images/playerBar-icons/ic_next.svg";
import {ReactComponent as Pause} from "../assets/images/playerBar-icons/ic_pause_white.svg";
import {ReactComponent as Play} from "../assets/images/playerBar-icons/ic_play_white.svg";
import {ReactComponent as Volume} from "../assets/images/playerBar-icons/ic_volume.svg";
import {ReactComponent as VolumeMute} from "../assets/images/playerBar-icons/ic_volume_mute.svg";
import {ReactComponent as Shuffle} from "../assets/images/playerBar-icons/ic_shuffle_tracks.svg";
import {ReactComponent as FacebookIcon} from "../assets/images/icons/ic_facebook_share.svg";
import {ReactComponent as FillHeart} from "../assets/images/playerBar-icons/ic_heart_fill.svg";
import {ReactComponent as EmptyHeart} from "../assets/images/playerBar-icons/ic_heart_empty.svg";
import {ReactComponent as ShareSongIcon} from "../assets/images/playerBar-icons/ic_share.svg";
import {ReactComponent as CopyIcon} from "../assets/images/icons/ic_copy_link.svg";
import {ReactComponent as CloseIcon} from "../assets/images/icons/ic_close_gray.svg";

//import components
import {LinkButton} from "./uIElements/buttons";

//import utils
import {
    GetSectionTracks,
    setCurrentPlayedTrack,
    togglePlayState,
    AddToFavorites,
    RemoveFromFavorites,
    AddToListened,
    toggleShuffle,
    getShufflesList, clearCurrentTrack
} from "../redux/actions";
import {mapStateToProps} from "../redux/mapStateToProps";
import {generateFileMediaUrl} from "../utils/generateMediaUrl";


function AudioPlayerLib(props) {
    const [shareWrapperIsOpen, setShareWrapperOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [volume, setVolume] = useState(1);
    const [isFetching, setFetching] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const {
        currentPlayedTrack,
        playerTracksList,
        playState,
        isLoggedIn,
        type,
        currentTrackIndex,
        shuffledTracksList
    } = props;
    let currentPlayedTrackInfo = currentPlayedTrack?.song;
    const audioUrl = currentPlayedTrackInfo?.path ? generateFileMediaUrl(currentPlayedTrackInfo?.path + "?index=" + currentTrackIndex) : '';
    const playerRef = useRef();
    let interval = null;


    useEffect(() => {
        if (currentPlayedTrackInfo) {
            if (playState) {
                playerRef.current.audio.current.play();
                interval = setInterval(() => {
                    setSeconds(seconds => seconds + 1);
                }, 1000);
            } else {
                playerRef.current.audio.current.pause();
                clearInterval(interval);
            }
            if (seconds === 6 && isLoggedIn) {
                clearInterval(interval);
                props.AddToListened(currentPlayedTrack.id);
            }
        }
        return () => {
            clearInterval(interval);
        }
    }, [playState, seconds]);

    useEffect(() => {
        window.addEventListener('click', onClicked);
        return () => {
            window.removeEventListener('click', onClicked)
        }
    }, []);

    useEffect(() => {
        setSeconds(0);
    }, [currentPlayedTrack]);

    useEffect(() => {
        props.shuffled && props.getShufflesList();
    }, [playerTracksList]);


    function onClicked() {
        setShareWrapperOpen(false);
    }

    function onPlay() {
        !playState && togglePlayState(true);
    }

    function onPause() {
        playState && togglePlayState(false);
    }

    function onEnded() {
        onClickNext();
    }

    function onClickNext() {
        if (currentPlayedTrackInfo) {
            if (props.shuffled) {
                let nextTrackIndex = (currentTrackIndex + 1) % shuffledTracksList?.length;
                if (currentTrackIndex === -1) {
                    nextTrackIndex = 0;
                }
                if (!shuffledTracksList[nextTrackIndex]?.song) {
                    nextTrackIndex = nextTrackIndex + 1
                }
                shuffledTracksList[nextTrackIndex]?.song &&
                setCurrentPlayedTrack(shuffledTracksList[nextTrackIndex], playerTracksList, type, nextTrackIndex, shuffledTracksList);
            } else {
                let nextTrackIndex = (currentTrackIndex + 1) % playerTracksList?.length;
                if (currentTrackIndex === -1) {
                    nextTrackIndex = 0;
                }
                if (!playerTracksList[nextTrackIndex]?.song) {
                    nextTrackIndex = nextTrackIndex + 1
                }
                playerTracksList[nextTrackIndex]?.song &&
                setCurrentPlayedTrack(playerTracksList[nextTrackIndex], playerTracksList, type, nextTrackIndex, shuffledTracksList);
            }
        }
        onPlay()
    }

    function onClickPrevious() {
        if (currentPlayedTrackInfo && shuffledTracksList?.length) {
            if (props.shuffled) {
                let prevTrackIndex = '';
                if (currentTrackIndex === -1) {
                    prevTrackIndex = 0;
                } else if (currentTrackIndex === 0) {
                    prevTrackIndex = shuffledTracksList?.length - 1;
                } else {
                    prevTrackIndex = currentTrackIndex - 1;
                }
                if (!shuffledTracksList[prevTrackIndex]?.song) {
                    prevTrackIndex = prevTrackIndex - 1
                }
                shuffledTracksList[prevTrackIndex]?.song &&
                setCurrentPlayedTrack(shuffledTracksList[prevTrackIndex], playerTracksList, type, prevTrackIndex, shuffledTracksList);
            } else {
                let prevTrackIndex = '';
                if (currentTrackIndex === -1) {
                    prevTrackIndex = 0;
                } else if (currentTrackIndex === 0) {
                    prevTrackIndex = playerTracksList?.length - 1;
                } else {
                    prevTrackIndex = currentTrackIndex - 1;
                }
                if (!playerTracksList[prevTrackIndex]?.song) {
                    prevTrackIndex = prevTrackIndex - 1
                }
                playerTracksList[prevTrackIndex]?.song &&
                setCurrentPlayedTrack(playerTracksList[prevTrackIndex], playerTracksList, type, prevTrackIndex, shuffledTracksList);
            }
        }
        togglePlayState(true)
    }

    function toggleShareWrapper(e) {
        e && e.preventDefault();
        e && e.stopPropagation();
        setShareWrapperOpen(!shareWrapperIsOpen);

    }

    function toggleFavorite(e) {
        e.preventDefault();
        e.stopPropagation();
        if (isLoggedIn) {
            if (!isFetching) {
                setFetching(true);
                currentPlayedTrack?.isFavorite ? props.RemoveFromFavorites(currentPlayedTrack?.id) : props.AddToFavorites(currentPlayedTrack);
                setFetching(false);

            }
        }
    }

    function toggleShuffle() {
        props.toggleShuffle(!props.shuffled);
    }

    const changeVolume = (e) => {
        setVolume(e.target.value);
        playerRef.current.audio.current.volume = e.target.value
    }

    const setVolumeMute = () => {
        if (playerRef.current.audio.current.volume > 0) {
            setVolume(0)
            playerRef.current.audio.current.volume = 0
        }
        if (volume === 0) {
            setVolume(1)
            playerRef.current.audio.current.volume = 1
        }
    };

    return <div className={"player-bar-wrapper"}>
        <div className="info-wrapper">
            <div className="img-wrap">
                <img src={generateFileMediaUrl(currentPlayedTrack?.coverImage?.path)}/>
            </div>
            <div className="song-info">
                <span className="song-name">{currentPlayedTrack?.title} <span
                    className={'song-subTitle'}> {currentPlayedTrack?.subTitle}</span></span>
                <div className="song-description">
                    {!!currentPlayedTrack?.artists?.length && currentPlayedTrack?.artists?.map((item, index) => {
                        return !item?.isHidden ?
                            <Link to={`/artist/${item?.slug}/songs`}
                                  className="song-author hovered"
                                  key={item.id}>
                                {item?.fullName}{index + 1 < currentPlayedTrack?.artists?.length &&
                                <span>,&nbsp;</span>}
                            </Link>
                            : <span className="song-author"
                                    key={item.id}>
                                {item?.fullName}
                                {index + 1 < currentPlayedTrack?.artists?.length && <span>,&nbsp;</span>}
                            </span>
                    })}
                </div>
            </div>
        </div>

        <AudioPlayer src={audioUrl}
                     ref={playerRef}
                     preload={'auto'}
                     layout="horizontal-reverse"
                     customIcons={{
                         play: <Play className="player-icons" onClick={onPlay}/>,
                         pause: <Pause className="player-icons" onClick={onPause}/>,
                         previous: <Prev className="player-icons"/>,
                         next: <Next className="player-icons"/>,
                         loop: <LoopOff className="player-icons hovered active"/>,
                         loopOff: <LoopOff className="player-icons hovered"/>
                     }}
            // onPlay={onPlay}
            // onPause={onPause}
                     onEnded={onEnded}
                     onClickPrevious={onClickPrevious}
                     onClickNext={onClickNext}
                     showSkipControls={true}
                     showJumpControls={false}
                     volumeJumpStep
                     customVolumeControls={[]}
                     defaultCurrentTime={"00:00"}
                     defaultDuration={"00:00"}
        />

        <div className="btns-wrapper">
            <div className="absolute-card-wrapper">
                <div className="heart-dropdown-wrapper" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }
                }>
                    {currentPlayedTrack?.isFavorite ?
                        <FillHeart className='card-heart fill-heart' title='' onClick={toggleFavorite}/>
                        : <EmptyHeart className='card-heart' title='' onClick={toggleFavorite}/>}
                    {!isLoggedIn && <div className={`heart-dropdown-content`}>
                        <span>Please sign in to save your favorites</span>
                        <LinkButton title="Sign in"
                                    link={"/sign-in"}
                        />
                    </div>
                    }
                </div>
            </div>
            <div className="absolute-card-wrapper">
                <ShareSongIcon className='card-heart' title='' onClick={toggleShareWrapper}/>
                {shareWrapperIsOpen && <div className="dropdown-content">
                    <div className="share-wrapper">

                        <CopyToClipboard
                            text={`http://youtop.app/album/${currentPlayedTrack?.albums.find(item => !item.isHidden).slug}/track/${currentPlayedTrack?.slug}`}
                            className={"copy-wrapper"}
                            onCopy={() => setCopied(true)}
                            onMouseLeave={() => setCopied(false)}>
                            <button onClick={e => {
                                e.stopPropagation()
                            }}>
                                <CopyIcon/> Copy url
                                <div className="copy-text">{copied ? "Copied" : 'Copy'}</div>
                            </button>
                        </CopyToClipboard>

                        <FacebookShareButton
                            url={`http://youtop.app/album/${currentPlayedTrack?.albums.find(item => !item.isHidden).slug}/track/${currentPlayedTrack?.slug}`}
                            quote={`${currentPlayedTrack?.title}`}
                        >
                            <FacebookIcon/> Facebook
                        </FacebookShareButton>
                    </div>
                </div>}
            </div>
            <div className="absolute-card-wrapper">
                <Shuffle className={`card-heart ${props?.shuffled ? 'player-icons hovered active' : ''}`} title=''
                         onClick={toggleShuffle}/>
            </div>
            <div className="absolute-card-wrapper">
                <div className="heart-dropdown-wrapper">
                    {volume > 0 ? <Volume className='card-heart' title='' onClick={setVolumeMute}/> :
                        <VolumeMute className="card-heart" onClick={setVolumeMute}/>}
                    <div className="volumeBar-wrapper">
                        <style dangerouslySetInnerHTML={{
                            __html: `.volume-range::before { width: calc(${volume} * 100%); backgroundColor: red}`
                        }}/>
                        <input type="range" min="0" max="1" step="0.01" className="volume-range" value={volume}
                               onChange={changeVolume}/>
                    </div>
                </div>
            </div>
        </div>

        <div className="close-icon-wrapper" onClick={() => props.clearCurrentTrack()}>
            <CloseIcon title={''}/>
        </div>
    </div>
}

const mapDispatchToProps = {
    GetSectionTracks,
    setCurrentPlayedTrack,
    togglePlayState,
    AddToFavorites,
    RemoveFromFavorites,
    AddToListened,
    toggleShuffle,
    getShufflesList,
    clearCurrentTrack
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioPlayerLib);