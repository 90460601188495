// import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";

//Import components
import {SeeAll} from "../../components/uIElements/buttons";
import SquareSkeletonCard from "../../components/skeletonCards/SquareSkeletonCard";

//import utils
import { CleaSections} from "../../redux/actions";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {CardComponents} from "../../components/cards/BaseCard";


function Playlists(props) {

    useEffect(() => {
    }, []);

    const setSkeletonCount = () => {
        if (window.innerWidth > 800) {
            return 4
        } else {
            return 3
        }
    }

    const {data, loading} = props;
    const playlistsList = window.innerWidth > 800 ? data?.slice(0, 4) : data?.slice(0, 3)

    return <div className="homepage-albums-wrapper">
        <div className='section-title'>
            <span>Popular Playlists</span>
            {data?.length > 4 && <SeeAll link={`home/playlists`}/>}
        </div>
        {!loading ? (!!playlistsList?.length && <div className="base-cards-list">
            {
                playlistsList?.map(item => {
                    return <CardComponents.AlbumCard album={item} key={item.id}/>

                })
            }
        </div>) : <div className={'base-cards-list'}>
            <SquareSkeletonCard count={setSkeletonCount()} withoutDescription/>
        </div>}
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'sections',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    CleaSections
};

export default connect(mapStateToProps, mapDispatchToProps)(Playlists);
