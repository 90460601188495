// import packages
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {connect} from "react-redux";

// Import styles
import "../assets/styles/containers/homepage.scss"

// Import Components
import Categories from "./Categories";
import Tracks from "./homepage/Tracks";
import Albums from "./homepage/Albums";
import Artists from "./homepage/Artists";

// Import utils
import {PageSkeleton} from "./PageSkeleton";
import {GetTracks} from "../redux/actions";
import {GetArtists} from "../redux/actions/artistsActions";
import {GetAlbums} from "../redux/actions/albumsActions";
import {getPropsFromState} from "../redux/mapStateToProps";
import SquareSkeletonCard from "../components/skeletonCards/SquareSkeletonCard";
import SkeletonTrackCard from "../components/skeletonCards/SkeletonTrackCard";


function GenreDetails(props) {
    const [list, setList] = useState({
        tracks: [],
        artists: [],
        albums: []
    });
    const [loading, setLoading] = useState({
        tracks: true,
        artists: true,
        albums: true,
    });

    const params = useParams();
    const {genres} = props;
    const genreId = genres.find(item => item?.slug === params?.slug).id;
    let currentTab = params?.subGenreId ? params?.subGenreId : genreId;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        getGenreItems(currentTab)
    }, []);

    const setSkeletonCount = () => {
        if (window.innerWidth > 800) {
            return 8
        } else {
            return 6
        }
    };

    const getGenreItems = (genre) => {
        const genreIds = []
        genreIds.push(genre)
        const genreById = genres.find(item => item?.id === genre)
        if (genreById) {
            genreById.subGenres && genreById.subGenres.length && genreById.subGenres.forEach(item => genreIds.push(item?.id))
        }
        props.GetTracks({genreIds: genreIds.join(","), getResponse: true, limit: 5}).then((res) => {
            setList((list) => {
                return {
                    ...list,
                    tracks: res.data
                }
            })
            setLoading((loading) => {
                return {
                    ...loading,
                    tracks: false
                }
            })
        })
        props.GetArtists({genreIds: genreIds.join(","), limit: 11}).then((res) => {
            setList((list) => {
                return {
                    ...list,
                    artists: res
                }
            })
            setLoading((loading) => {
                return {
                    ...loading,
                    artists: false
                }
            })
        })
        props.GetAlbums({genreIds: genreIds.join(","), getResponse: true, limit: 5}).then((res) => {
            setList((list) => {
                return {
                    ...list,
                    albums: res?.data
                }
            })
            setLoading((loading) => {
                return {
                    ...loading,
                    albums: false
                }
            })
        })
    };

    const toggleFavoriteState = (id, favorite) => {

        setList((list) => {

            return {
                ...list,
                tracks: list.tracks.map(track => track.id === id ? {
                    ...track,
                    isFavorite: favorite
                } : track),
            }
        })
    };

    return <div className="homepage-wrapper genre-details">
        <div>
            <Categories getGenreItems={getGenreItems}/>
        </div>
        <PageSkeleton>
            {!loading?.albums ? <> {!!list?.albums?.length &&
                <Albums data={list?.albums} loading={loading?.albums}/>}</>
                : <div className={'base-cards-list'}>
                    <SquareSkeletonCard count={setSkeletonCount()} withoutDescription/>
                </div>}
            {!loading?.tracks ? <> {!!list?.tracks?.length &&
                <Tracks toggleFavoriteState={toggleFavoriteState} genreTracks data={list?.tracks}
                        loading={loading?.tracks}/>}</>
                : <SkeletonTrackCard count={setSkeletonCount()}/>}
            {!loading?.artists ? <> {!!list?.artists?.length &&
                <Artists data={list?.artists} loading={loading?.artists}/>}</>
                : <div className={'base-cards-list circle-cards'}>
                    <SquareSkeletonCard count={setSkeletonCount()}/>
                </div>}
        </PageSkeleton>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'genres',
]);

const mapDispatchToProps = {
    GetTracks,
    GetArtists,
    GetAlbums
};

export default connect(mapStateToProps, mapDispatchToProps)(GenreDetails);