// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link, useHistory, useParams, useLocation} from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroller";

//Import assets
import '../assets/styles/containers/profile.scss';
import {ReactComponent as PlayIcon} from "../assets/images/playerBar-icons/ic_play_white.svg";
import {ReactComponent as PauseIcon} from "../assets/images/playerBar-icons/ic_pause_white.svg";
import {ReactComponent as AddPlaylistIcon} from "../assets/images/icons/ic_add_playlist.svg";
import {ReactComponent as EditIcon} from "../assets/images/icons/ic_edit.svg";
import {ReactComponent as PodcastIcon} from "../assets/images/icons/ic_podcast.svg";

//Import Components
import ImageUploader from "../components/uIElements/imageUploader";
import CustomTabs from "../components/uIElements/Tabs";
import AddEditPlaylistCard from "../components/cards/AddEditPlaylistCard";
import UserPlayListCard from "../components/cards/UserPlaylistCard";
import TrackCard from "../components/cards/TrackCard";
import MetaTags from "../components/MetaTags";
import {CardComponents} from "../components/cards/BaseCard";
import {LinkButton} from "../components/uIElements/buttons";
import {InputGroup} from "../components/uIElements/inputGroup";
import SkeletonTrackCard from "../components/skeletonCards/SkeletonTrackCard";
import SquareSkeletonCard from "../components/skeletonCards/SquareSkeletonCard";
import {PageSkeleton} from "./PageSkeleton";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {
    GetCurrentUser,
    GetFavoriteTracks,
    GetPlaylists,
    GetListenedTracks,
    UpdateCurrentUser,
    setCurrentPlayedTrack,
    togglePlayState,
} from "../redux/actions";
import {GetFavoriteArtists} from "../redux/actions";
import {GetFavoriteAlbums} from "../redux/actions";
import {generateFileMediaUrl} from "../utils/generateMediaUrl";
import {ALBUM_TYPES, profileTabs} from "../constants/constants";


const Profile = (props) => {
    const params = useParams();
    const history = useHistory();
    const [profilePictureFile, setProfilePictureFile] = useState(null);
    const [playlistCardOpen, setPlaylistCardOpen] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [isEditing, setEditing] = useState(false);
    const [errors, setErrors] = useState({});
    const [updatedDataMap, setUpdatedDataMap] = useState(new Map());
    const [loading, setLoading] = useState({
        favTracks: true,
        userPlaylists: true,
        userFavPlaylists: true,
        favArtists: true,
        favAlbums: true,
        listenedTracks: true,
    });
    const [albums, setAlbums] = useState({
        favAlbums: {
            list: [],
            hasMore: false,
        },
        favPlaylists: {
            list: [],
            hasMore: false,
        },
    });
    const [userInfo, setUserInfo] = useState({
            firstName: props.loggedInUser?.firstName || "",
            lastName: props.loggedInUser?.lastName || ""
        }
    );
    let {tab: currentTab} = params;
    const location = useLocation()
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        props.GetFavoriteTracks().then(() => {
            setStateLoading("favTracks")
        })
        props.GetFavoriteArtists().then(() => {
            setStateLoading("favArtists")

        })
        props.GetFavoriteAlbums({
            getResponse: true,
            types: `${ALBUM_TYPES.MAIN.key},${ALBUM_TYPES.SINGLE.key}`
        }).then((res) => {
            setAlbums((albums) => {
                return {
                    ...albums,
                    favAlbums: {
                        ...albums.favAlbums,
                        list: res.data,
                        hasMore: res.data?.length === 10
                    }
                }
            })
            setStateLoading("favAlbums")
        })
        props.GetListenedTracks().then(() => {
            setStateLoading("listenedTracks")
        })
        props.GetFavoriteAlbums({getResponse: true, types: `${ALBUM_TYPES.PLAYLIST.key}`}).then((res) => {
            setAlbums((albums) => {
                return {
                    ...albums,
                    favPlaylists: {
                        ...albums.favPlaylists,
                        list: res.data,
                        hasMore: res.data?.length === 10
                    }
                }
            })
            setStateLoading("userFavPlaylists")
        })
        props.GetPlaylists().then(() => {
            setStateLoading("userPlaylists")
        })
    }, []);

    const setStateLoading = (name) => {
        setLoading((loading) => {
            return {
                ...loading,
                [name]: false
            }
        })
    }

    function setMap(entries) {
        setUpdatedDataMap(new Map(entries))
    }

    const changeTab = (tab) => {
        history.push(`/profile/${tab}`)
    };

    const getSelectedImageFile = (file) => {
        console.log("file", file)
        updatedDataMap.set('profilePicturePath', '');
        setMap(updatedDataMap);
        setProfilePictureFile(file);
    };
    const togglePlaylistCard = () => {
        setPlaylistCardOpen(!playlistCardOpen)
    }

    const loadMoreItems = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetFavoriteTracks({
                reset: false,
                offset: userFavoriteTracks?.list?.length
            }).finally(() => {
                setFetching(false);
            });
        }
    };
    const loadMoreFavoriteArtists = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetFavoriteArtists({
                reset: false,
                offset: userFavoriteArtists?.list?.length
            }).finally(() => {
                setFetching(false);
            });
        }
    };
    const loadMoreFavoriteAlbums = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetFavoriteAlbums({
                getResponse: true,
                reset: false,
                offset: albums.favAlbums?.list?.length,
                types: `${ALBUM_TYPES.MAIN.key},${ALBUM_TYPES.SINGLE.key}`
            }).then((res) => {
                setAlbums((albums) => {
                    return {
                        ...albums,
                        favAlbums: {
                            ...albums.favAlbums,
                            list: [...albums?.favAlbums?.list].concat(res.data),
                            hasMore: res.data?.length === 10
                        }
                    }
                })
            }).finally(() => {
                setFetching(false);
            });
        }
    };
    const loadMoreFavoritePlaylists = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetFavoriteAlbums({
                getResponse: true,
                reset: false,
                offset: albums.favPlaylists?.list?.length,
                types: 'PLAYLIST'
            }).then((res) => {
                setAlbums((albums) => {
                    return {
                        ...albums,
                        favPlaylists: {
                            ...albums.favPlaylists,
                            list: [...albums?.favPlaylists?.list].concat(res.data),
                            hasMore: res.data?.length === 10
                        }
                    }
                })
            }).finally(() => {
                setFetching(false);
            });
        }
    };
    const loadMoreListenedTracks = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetListenedTracks({
                reset: false,
                offset: userListenedTracks?.list?.length
            }).finally(() => {
                setFetching(false);
            });
        }
    };

    const setSkeletonCount = () => {
        if (window.innerWidth > 800) {
            return 4
        } else if (window.innerWidth > 400) {
            return 3
        } else {
            return 2
        }
    }

    function setTrack() {
        if (location?.pathname.includes(props?.type)) {
            togglePlayState(!props.playState)
        } else {
            setCurrentPlayedTrack(userFavoriteTracks?.list[0], userFavoriteTracks?.list, location?.pathname, 0)
            togglePlayState(true)
        }

    }

    // console.log("includes", location?.pathname.includes(props?.type))
    // console.log("type", props?.type)


    const {
        loggedInUser,
        userFavoriteTracks,
        userPlaylists,
        userFavoriteArtists,
        userFavoriteAlbums,
        userListenedTracks
    } = props;

    const user = loggedInUser ?? {};
    return <PageSkeleton withPadding>
        <MetaTags title={`${props?.loggedInUser?.firstName || ''} ${props?.loggedInUser?.lastName || ''}`}
                  image={generateFileMediaUrl(user?.profilePicturePath?.path)}/>
        <div className="user-header">
            {user?.profilePicturePath && <div className="uploader-wrapper">
                <div className={`image-uploader`}>
                    <div className={'image-wrapper'}>
                        <div className="img-content">
                            <img src={generateFileMediaUrl(props?.loggedInUser?.profilePicturePath?.path)} alt=" "/>
                        </div>
                    </div>
                </div>
            </div>}
            <div className="user-description-content">
                <div>
                    <div
                        className="header-name">{props.loggedInUser.firstName + ' ' + props.loggedInUser.lastName}</div>
                    <div className="header-email">{props.loggedInUser.username}</div>
                </div>

                <div className="btns-wrapper">
                    <div className="btns-left-part">
                        {!location.pathname.includes("profile/songs") ?
                            <span className="play-all">
                            <PlayIcon title={''} className="play-icon"/>
                            Play
                        </span>
                            :
                            (userFavoriteTracks?.list.length ? <span className="play-all" onClick={setTrack}>
                        {location.pathname.includes(props?.type) && props?.playState ?
                            <PauseIcon title={''} className="play-icon"/> :
                            <PlayIcon title={''} className="play-icon"/>}
                                Play
                        </span> : <span className="play-all">
                        <PlayIcon title={''} className="play-icon"/>
                        Play
                        </span>)
                        }
                        <div className={`heart-wrapper ${isEditing ? "save-wrapper" : ""}`}>

                            <LinkButton title={<EditIcon className='card-heart' title=''/>} link={"/edit-account"}/>
                        </div>
                    </div>
                    <LinkButton title={<span className='podcast-title'><PodcastIcon title=''/> Podcast Studio</span>}
                                className='podcast-btn'
                                link={"/podcasts"}/>
                </div>
            </div>
        </div>
        <CustomTabs tabsList={profileTabs}
                    changeTab={changeTab}
                    currentTab={currentTab}
        />
        {currentTab === profileTabs.songs.id && <div className="content-wrapper">{
            !loading?.favTracks ?
                (!!userFavoriteTracks?.list?.length ? <div className="search-tracks-wrapper">
                        <InfiniteScroll
                            hasMore={userFavoriteTracks?.hasMore}
                            loadMore={loadMoreItems}
                            className={'tracks-list'}
                            pageStart={0}
                            useWindow={true}
                            initialLoad={false}>
                            {
                                userFavoriteTracks?.list?.map((track, index) => {
                                    return <TrackCard track={track}
                                                      playerTracksList={userFavoriteTracks?.list}
                                                      indexInPlayerList={index}
                                                      key={track?.id}/>
                                })
                            }
                        </InfiniteScroll>

                    </div>
                    : <div className="no-result">
                        <span>There is no tracks available</span>
                    </div>)
                : <SkeletonTrackCard count={3}/>
        }</div>}
        {currentTab === profileTabs.playlists.id && <>{
            !loading?.userPlaylists ?
                <div className="user-playlist-wrapper">

                    <div className="user-playlists-content">
                        <div className="add-playlist-wrapper">
                            <div className="playlist-content" onClick={togglePlaylistCard}>
                                <div>
                                    <div>
                                        <AddPlaylistIcon title={''}/>
                                    </div>
                                </div>
                            </div>
                            {playlistCardOpen && <AddEditPlaylistCard togglePlaylistCard={togglePlaylistCard}/>}
                        </div>
                        {userPlaylists && !!userPlaylists?.list?.length && userPlaylists?.list.map(playlist => {
                            return <UserPlayListCard playlist={playlist} key={playlist.id}/>
                        })}
                    </div>
                </div>
                : <div className={'base-cards-list'}>
                    <SquareSkeletonCard count={setSkeletonCount()}/>
                </div>
        }
            {!loading?.userFavPlaylists ? <>

                    {!!albums?.favPlaylists.list?.length && <div className='section-title'>
                        <span>Liked playlists</span>
                    </div>}
                    <InfiniteScroll
                        hasMore={albums?.favPlaylists?.hasMore}
                        loadMore={loadMoreFavoritePlaylists}
                        className={'base-cards-list'}
                        pageStart={0}
                        useWindow={true}
                        initialLoad={false}>
                        {
                            !!albums?.favPlaylists.list?.length && albums?.favPlaylists?.list?.map(album => {
                                return <CardComponents.AlbumCard album={album} key={album.id}/>
                            })
                        }
                    </InfiniteScroll>
                </>
                : <div className={'base-cards-list'}>
                    <SquareSkeletonCard count={setSkeletonCount()}/>
                </div>
            }
        </>}
        {currentTab === profileTabs.artists.id && <>{
            !loading?.favArtists ?
                (!!userFavoriteArtists?.list?.length ? <>
                        <InfiniteScroll
                            hasMore={userFavoriteArtists?.hasMore}
                            loadMore={loadMoreFavoriteArtists}
                            className={'base-cards-list'}
                            pageStart={0}
                            useWindow={true}
                            initialLoad={false}>
                            {
                                userFavoriteArtists?.list?.map(artist => {
                                    return <CardComponents.ArtistCard artist={artist} key={artist.id}/>
                                })
                            }
                        </InfiniteScroll>
                    </>
                    : <div className="no-result">
                        <span>There is no artists available</span>
                    </div>)
                : <div className={'base-cards-list circle-cards'}>
                    <SquareSkeletonCard count={setSkeletonCount()}/>
                </div>
        }</>}
        {currentTab === profileTabs.albums.id && <>{
            !loading?.favAlbums ?
                (!!albums?.favAlbums?.list?.length ? <>
                        <InfiniteScroll
                            hasMore={albums.favAlbums?.hasMore}
                            loadMore={loadMoreFavoriteAlbums}
                            className={'base-cards-list'}
                            pageStart={0}
                            useWindow={true}
                            initialLoad={false}>
                            {
                                !!albums?.favAlbums?.list?.length && albums?.favAlbums?.list?.map(album => {
                                    return <CardComponents.AlbumCard album={album} key={album.id}/>
                                })
                            }
                        </InfiniteScroll>
                    </>
                    : <div className="no-result">
                        <span>There is no album available</span>
                    </div>)
                : <div className={'base-cards-list'}>
                    <SquareSkeletonCard count={setSkeletonCount()}/>
                </div>
        }</>}
        {currentTab === profileTabs.listened.id && <>{
            !loading?.listenedTracks ?
                (!!userListenedTracks?.list?.length ? <div className="search-tracks-wrapper">
                        <InfiniteScroll
                            hasMore={userListenedTracks?.hasMore}
                            loadMore={loadMoreListenedTracks}
                            className={'tracks-list'}
                            pageStart={0}
                            useWindow={true}
                            initialLoad={false}>
                            {
                                userListenedTracks?.list?.map((track, index) => {
                                    return <TrackCard track={track}
                                                      playerTracksList={userListenedTracks?.list}
                                                      indexInPlayerList={index}
                                                      key={track.id}/>
                                })
                            }
                        </InfiniteScroll>

                    </div>
                    : <div className="no-result">
                        <span>There is no tracks available</span>
                    </div>)
                : <SkeletonTrackCard count={3}/>
        }</>}
    </PageSkeleton>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'loggedInUser',
    'userFavoriteTracks',
    'userPlaylists',
    'userFavoriteArtists',
    'userFavoriteAlbums',
    'userListenedTracks',
    'playState',
    'type'
]);

const mapDispatchToProps = {
    GetCurrentUser,
    GetFavoriteTracks,
    GetPlaylists,
    GetFavoriteArtists,
    GetFavoriteAlbums,
    GetListenedTracks,
    UpdateCurrentUser,
    togglePlayState
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);


