// import packages
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { history } from "../../configs/history";
import { useLocation, useParams } from "react-router-dom";
import { FacebookShareButton } from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard';

// import assets
import '../../assets/styles/components/cards/track-more-card.scss'
import { ReactComponent as EditIcon } from "../../assets/images/icons/ic_edit_black.svg";
import { ReactComponent as RemoveIcon } from "../../assets/images/icons/ic_remove.svg";

//import utils
import {
    RemoveTrack,
} from "../../redux/actions";
import { getPropsFromState } from "../../redux/mapStateToProps";
import {ReactComponent as ShareSongIcon} from "../../assets/images/icons/Ic_share.svg";
import {ReactComponent as CopyIcon} from "../../assets/images/icons/ic_copy_link.svg";
import {ReactComponent as FacebookIcon} from "../../assets/images/icons/ic_facebook_share.svg";


function TrackOwnerActionsCard (props) {
    const { track, podcastId, trackActionsCardOpen } = props;
    const [mouseEnter, setMouseEnter] = useState(false);
    const [shareWrapperIsOpen, setShareWrapperOpen] = useState(false);
    const [copied, setCopied] = useState(false);

    const ref = useRef();
    const params = useParams();

    useEffect(() => {
        window.addEventListener('click', onClicked)
        return () => {
            window.removeEventListener('click', onClicked)
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (trackActionsCardOpen && mouseEnter) {
                props.toggleTrackActionsCard()
            }
        });
    }, [trackActionsCardOpen, mouseEnter])

    function onClicked () {
        props.toggleTrackActionsCard();
    }

    function removeTrack () {
        props.RemoveTrack(podcastId, track.id)
        onClicked()
    }

    function prevent (e) {
        e.preventDefault();
        e.stopPropagation();
    }

    function shareSong(e) {
        e.preventDefault();
        e.stopPropagation();
        setShareWrapperOpen(true);
    }

    function backToActions() {
        setShareWrapperOpen(false);
    }

    const albumSlug = track?.albums.length && track?.albums.find(item => item?.slug === params?.slug) ? params?.slug : track?.albums.find(item => !item.isHidden)?.slug
    console.log(track, 'albumSlug');
    return <div className={`track-dropdown-menu `}
                ref={ref}
                onMouseLeave={() => setMouseEnter(true)}
                onMouseEnter={() => setMouseEnter(false)}
                onClick={prevent}>
        {!shareWrapperIsOpen ? <div className="track-dropdown-links">
            {/*<div className="menu-item not-active">*/}
            {/*    <EditIcon className='card-heart' title=''/>*/}
            {/*    Edit*/}
            {/*</div>*/}
            <div className="menu-item" onClick={shareSong}>
                <ShareSongIcon className='card-heart' title=''/>
                Share song
            </div>
            <div className="menu-item" onClick={removeTrack}>
                <RemoveIcon className='card-heart' title=''/>
                Delete
            </div>
        </div>
        : <div className="dropdown-playlists-content">
                <div className="dropdown-playlists-header">
                    <div className="back-wrapper" onClick={backToActions}>
                        <img src={require("../../assets/images/icons/ic_arrow_down_dark.svg")} alt=""/>
                        <span>Back</span>
                    </div>
                </div>
                <div className="share-wrapper">

                    <CopyToClipboard
                        text={`http://youtop.app/track/${track.slug}`}
                        className={"copy-wrapper"}
                        onCopy={() => setCopied(true)}
                        onMouseLeave={() => setCopied(false)}>
                        <button onClick={e => {
                            e.stopPropagation()
                        }}>
                            <CopyIcon/> Copy url
                            <div className="copy-text">{copied ? "Copied" : 'Copy'}</div>
                        </button>
                    </CopyToClipboard>

                    <FacebookShareButton url={`http://youtop.app/album/${albumSlug}/track/${track.slug}`}
                                         quote={`${track.title}`}
                    >
                        <FacebookIcon/> Facebook
                    </FacebookShareButton>
                </div>
            </div>}

    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'isLoggedIn'
]);

const mapDispatchToProps = {
    RemoveTrack
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackOwnerActionsCard);
