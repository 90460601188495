// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

//import Assets
import './podcast.scss';
import {ReactComponent as AddPlaylistIcon} from "../../assets/images/icons/ic_add_playlist.svg";

//import components
import AddEditPodcastCard from "./addEditPodcast/AddEditPodcastCard";
import PodcastCard from "./podcastCard/PodcastCard";

//import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetUserPodcasts} from "../../redux/actions";


function PodcastList(props) {
    //state
    const [podcastCardOpen, setPodcastCardOpen] = useState(false);

    //hooks
    useEffect(() => {
        props.GetUserPodcasts()
    }, [])
    //functions
    const togglePodcastCard = () => {
        setPodcastCardOpen(!podcastCardOpen)
    }

    const {userPodcasts} = props

    return <div className="podcast-wrapper">
        <img className={'top-img'} src={require("../../assets/images/podcast.jpg")} alt={""}/>

        <div className="podcast-list-wrapper">
            <div className="podcast-list-content">
                <div className="add-podcast-wrapper">
                    <div className="podcast-content" onClick={togglePodcastCard}>
                        <div>
                            <div>
                                <AddPlaylistIcon title={''}/>
                            </div>
                        </div>
                    </div>
                    {podcastCardOpen && <AddEditPodcastCard togglePodcastCard={togglePodcastCard}/>}
                </div>
                {userPodcasts && !!userPodcasts?.list?.length && userPodcasts?.list.map(podcast => {
                    return <PodcastCard podcast={podcast} key={podcast.id}/>
                })}
            </div>
        </div>
         <div className={'base-cards-list'}>
        {/*<SquareSkeletonCard count={setSkeletonCount()}/>*/}
    </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, ['userPodcasts'])
};

const mapDispatchToProps = {
    GetUserPodcasts
};

export default connect(mapStateToProps, mapDispatchToProps)(PodcastList);
