// import packages
import React, {Component} from "react";
import {connect} from 'react-redux';
import {history} from "../configs/history";
import {Link} from "react-router-dom";

//Import Assets
import '../assets/styles/containers/sign-in.scss';
import {ReactComponent as AppStore} from '../assets/images/download-app-store-badge.svg';
import {ReactComponent as GooglePlay} from '../assets/images/download-google-play-badge.svg';
import {ReactComponent as GoogleSignInIcon} from '../assets/images/icons/ic_google.svg';
import {ReactComponent as FbSignInIcon} from '../assets/images/icons/ic_facebook.svg';
import {ReactComponent as AppleSignInIcon} from '../assets/images/icons/ic_apple.svg';

//Import Components
import GoogleLogin from "react-google-login";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {AutorisationButton} from "../components/uIElements/buttons";

// Import utils
import {GOOGLE_CLIENT_ID, FACEBOOK_APP_ID} from "../configs/variables";
import {mapStateToProps} from "../redux/mapStateToProps";
import {SocialSignIn} from "../redux/actions";
import {androidUrl, iosUrl} from "../configs/appUrls";

class SignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                isBlocked: false,
            }
        };
        this.handleSignInErrors = this.handleSignInErrors.bind(this);
        this.responseGoogle = this.responseGoogle.bind(this);
        this.responseFacebook = this.responseFacebook.bind(this);
        this.responseApple = this.responseApple.bind(this);
    }

    componentDidMount() {
        const {location} = this.props;
        if (location?.state?.username) {
            this.setState({
                loginData: {
                    ...this.state.loginData,
                    username: location?.state?.username
                }
            });
            history.replace();
        }
    }

    handleSignInErrors(status) {
        const {staticTexts} = this.props;
        let errorText = '';
        switch (status) {
            case 'BLOCKED_ACCOUNT':
                errorText = 'This account is blocked';
                break;
        }
        this.setState({
            errors: {
                isBlocked: status === 'BLOCKED_ACCOUNT',
            }
        });
        console.log(errorText, 'errorText');
    }

    responseGoogle(response) {
        const {SocialSignIn, location} = this.props;

        const redirectTo = location?.state?.redirectTo;
        console.log("redirectTo", this.props.loggedInUser)
        console.log('google login response', response);
        if (response?.tokenId) {
            SocialSignIn(response.tokenId, 'google', redirectTo).then(() => console.log("aaaaa")).catch((error) => {
                const status = error && error.response && error.response.data && error.response.data.type;
                // console.log("error.response", error.response);
                if (status) {
                    this.handleSignInErrors(status);
                }
            })
        }
    }

    responseFacebook(response) {
        const {SocialSignIn, location} = this.props;
        const redirectTo = location?.state?.redirectTo;
        // console.log('facebook login response', response);
        //alert('facebook login response' + JSON.stringify(response));
        if (response?.accessToken) {
            SocialSignIn(response.accessToken, 'facebook', redirectTo).catch((error) => {
                const status = error && error.response && error.response.data && error.response.data.type;
                // console.log("error.response", error.response);
                if (status) {
                    this.handleSignInErrors(status);
                }
            })
        }
    }

    responseApple(response) {
        const {SocialSignIn, location} = this.props;
        const redirectTo = location?.state?.redirectTo;
        // console.log('facebook login response', response);
        //alert('facebook login response' + JSON.stringify(response));
        if (response?.authorization?.id_token) {
            SocialSignIn(response.authorization.id_token, 'apple', redirectTo).catch((error) => {
                const status = error && error.response && error.response.data && error.response.data.type;
                console.log("error.response", error.response);
                if (status) {
                    this.handleSignInErrors(status);
                }
            })
        }
    }

    render() {
        return <div className="sign-in-wrapper">
            <div className="left-side">
                <picture>
                    <source media="(max-width:600px)" srcSet={require("../assets/images/login_sm.webp")}/>
                    <img src={require("../assets/images/login_lg.webp")} alt={""}
                         className="login-bg"/>
                </picture>
                <Link to={"/"}><img className="logo" src={require("../assets/images/icons/ic_logo_white.svg")} alt=""/></Link>
                <div className="left-bottom-part">
                    <div className="left-title">YouTop for iOS & Android</div>
                    <div>
                        <a href={androidUrl} target={'_blank'} rel="noopener noreferrer"><GooglePlay className={"app-btn"}/></a>
                        <a href={iosUrl} target={'_blank'} rel="noopener noreferrer"><AppStore className={"app-btn"}/></a>
                    </div>

                </div>
            </div>
            <div className="right-side">
                <img className="ellipse" src={require("../assets/images/icons/ic_ellipse_login.svg")} alt=""/>
                <h1 className="form-title">Sign In</h1>
                <div className="right-side-main-content">
                    <div className={'social-sign-in-wrapper'}>
                        <GoogleLogin
                            clientId={GOOGLE_CLIENT_ID}
                            onSuccess={this.responseGoogle}
                            autoLoad={false}
                            cookiePolicy={'single_host_origin'}
                            prompt={'select_account'}
                            onFailure={(error) => {
                                console.log(JSON.stringify(error));
                                //alert('ERROR' + JSON.stringify(error));
                            }}
                            render={renderProps => (
                                <AutorisationButton
                                    onClick={renderProps.onClick}
                                    title={<><GoogleSignInIcon
                                        className="autorisation-button-image"/>Sign in with Google</>}
                                    className='social-sign-in-btn with-google'
                                />
                            )}
                        />
                        <FacebookLogin
                            appId={FACEBOOK_APP_ID}
                            autoLoad={false}
                            callback={this.responseFacebook}
                            render={renderProps => (
                                <AutorisationButton
                                    onClick={renderProps.onClick}
                                    title={<><FbSignInIcon
                                        className="autorisation-button-image"/>Sign in with Facebook</>}
                                    className='social-sign-in-btn with-facebook'
                                />
                            )}
                        />
                        <div className={'apple-sign-in-wrapper'}>
                            <AutorisationButton
                                onClick={() => {
                                    window.AppleID && window.AppleID.auth && window.AppleID?.auth.signIn
                                    && window.AppleID.auth.signIn().then((resData) => {
                                        console.log('resData', resData);
                                        this.responseApple(resData)
                                    })
                                }}
                                title={<><AppleSignInIcon
                                    className="autorisation-button-image"/>Sign in with Apple</>}
                                className='social-sign-in-btn with-apple'
                            />

                        </div>
                    </div>
                    <div className="right-bottom-part">
                        <div className="right-title">Discover top songs with YouTop</div>
                        <div className="right-text">Find the top songs and artist in seconds. Listen and add to Apple Music or Spotify
                            playlists. Follow along with time-synced lyrics. Watch music videos from Apple
                            Music or YouTube. Discover the most viewed tracks of the week around the world
                            with YouTop charts.</div>
                        <div className="right-link-text">Learn more about YouTop <Link to={"/privacy-policy"}>Privacy & Terms of Services</Link></div>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapDispatchToProps = {
    SocialSignIn
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
