export const GENERAL_CONSTS = {
    GET_LANGUAGES: "GET_LANGUAGES",
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",

    GET_TRACKS_SUGGESTIONS: "GET_TRACKS_SUGGESTIONS",
    GET_ARTISTS_SUGGESTIONS: "GET_ARTISTS_SUGGESTIONS",
    GET_ALBUMS_SUGGESTIONS: "GET_ALBUMS_SUGGESTIONS",
    GET_PLAYLIST_SUGGESTIONS: "GET_PLAYLIST_SUGGESTIONS",
    GET_PODCAST_SUGGESTIONS: "GET_PODCAST_SUGGESTIONS",
    CLEAR_SUGGESTIONS: "CLEAR_SUGGESTIONS",

    GET_VIDEO_LIST: "GET_VIDEO_LIST",
    GET_GENRES: "GET_GENRES",
};
