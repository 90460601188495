import {ALBUMS_CONSTS} from "../constants";

export const initialState = {
    albums: {
        list: [],
        text: '',
        hasMore: false,
    },
    adminPlaylists: {
        list: [],
        text: '',
        hasMore: false,
    },
    userFavoriteAlbums: {
        list: [],
        text: '',
        hasMore: false,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ALBUMS_CONSTS.GET_ALBUMS:
            const newAlbumsList = {
                list: action.payload.reset ? action.payload.data : [...state.albums.list, ...action.payload.data],
                text: action.payload.text,
                hasMore: action.payload.hasMore
            };
            return {
                ...state,
                albums: newAlbumsList
            };
        case ALBUMS_CONSTS.GET_ADMIN_PLAYLISTS:
            const newAdminPlaylist = {
                list: action.payload.reset ? action.payload.data : [...state.adminPlaylists.list, ...action.payload.data],
                text: action.payload.text,
                hasMore: action.payload.hasMore
            };
            return {
                ...state,
                adminPlaylists: newAdminPlaylist
            };

        case ALBUMS_CONSTS.CLEAR_ALBUMS:
            return {
                ...state,
                albums: {...initialState.albums},
            };

        case ALBUMS_CONSTS.GET_FAVORITE_ALBUMS:
            return {
                ...state,
                userFavoriteAlbums: {
                    list: action.payload.reset ? action.payload.data : [...state.userFavoriteAlbums.list, ...action.payload.data],
                    text: action.payload.text,
                    hasMore: action.payload.hasMore
                },
            };

        case ALBUMS_CONSTS.TOGGLE_ALBUM_FAVORITE:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    list: state.albums.list.map(item => item.id === action.payload.id ?
                        {
                            ...item,
                            isFavorite: action.payload.likeState
                        } : item
                    )
                },
                userFavoriteAlbums: {
                    ...state.userFavoriteAlbums,
                    list: state.userFavoriteAlbums.list.filter(item => item.id !== action.payload.id),
                    text: action.payload.text,
                    hasMore: action.payload.hasMore
                },
            };

        default:
            return state;
    }
}
