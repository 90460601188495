// Import packages
import React, {Component} from "react";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";

// Import components
import Header from "../components/Header";
import Footer from "../components/Footer";
import MetaTags from "../components/MetaTags";
import AudioPlayer from "../components/AudioPlayer";


// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {
    GetLanguages, togglePlayState, TokenValidation
} from "../redux/actions";
import {GetGenres, toggleNotificationRow} from "../redux/actions";
import {history} from "../configs/history";
import {socketNotificationConnection,} from "../socket/api/socketNotificationConnection";
import NotificationRow from "../components/NotificationRow";

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileMenuIsOpen: false,
        };
        this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    }

    componentDidMount() {
        const {isLoggedIn, loggedInUser} = this.props;
        this.props.GetLanguages();
        this.props.GetGenres();
        togglePlayState(false);
        window.addEventListener("orientationchange", this.changeOrientation);

        if (isLoggedIn) {
            const tokenIsValid = this.props.TokenValidation();
            if (tokenIsValid) {
                if (!socketNotificationConnection._instance && isLoggedIn) {
                    socketNotificationConnection.connectToSocket();
                }
            }
            if(loggedInUser?.isFirstTime){
                history.push("/preferred-genres");
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isLoggedIn, location, loggedInUser} = this.props;
        if (!prevProps.isLoggedIn && isLoggedIn) {

            if (!socketNotificationConnection._instance) {
                socketNotificationConnection.connectToSocket();
            }
        }

        if (location?.pathname !== prevProps?.location?.pathname) {
            if(loggedInUser?.isFirstTime){
                history.push("/preferred-genres");
            }
            if(window.innerWidth < 600) {
                (location?.pathname !== '/about' && location?.pathname !== '/contacts' && location?.pathname !== '/privacy-policy') &&
                history.push({
                    pathname: `/upload-app`,
                })
            }
        }

    }

    changeOrientation(event) {
        console.log("the orientation of the device is now " + event.target.screen.orientation.angle);
        if (event.target.screen.orientation.angle === 90) {
            if (window.innerHeight > 600) {
                history.push("/home")
                togglePlayState(true);
            } else {
                history.push("/upload-app")
                togglePlayState(false);
            }
        } else {
            history.push("/upload-app")
            togglePlayState(false);
        }
    };

    toggleMobileMenu() {
        this.setState({
            mobileMenuIsOpen: !this.state.mobileMenuIsOpen,
        })
    }

    render() {
        const {children, location, currentPlayedTrack, playState, notificationRowData, nonOpenedNotificationCount} = this.props;
        const hideHeaderFooter = location.pathname.startsWith('/sign-in') || location.pathname.startsWith('/preferred-genres') || location.pathname.startsWith('/upload-app')

        return <div className={`layout-wrapper 
        ${currentPlayedTrack &&
        !location.pathname.startsWith('/upload-app') &&
        !location.pathname.startsWith('/preferred-genres') &&
        !location.pathname.startsWith('/sign-in') ? "padding-bottom" : ""}`}>
            <MetaTags/>
            {!hideHeaderFooter && <Header toggleMobileMenu={this.toggleMobileMenu}/>}
            <div className={`main-content ${this.state.mobileMenuIsOpen ? 'expand_on' : ''}`}>
                {children}
            </div>
            {!this.state.mobileMenuIsOpen && !hideHeaderFooter && <Footer/>}
            {currentPlayedTrack && !location.pathname.startsWith('/upload-app') &&
            <AudioPlayer song={currentPlayedTrack}
                         playState={playState}
                         currentPlayedTrack={currentPlayedTrack}/>}
            <NotificationRow data={notificationRowData}
                             toggleNotificationRow={this.props.toggleNotificationRow}/>
        </div>
    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'notificationRowData',
        'languages',
        'isLoggedIn',
        'loggedInUser',
        'currentPlayedTrack',
        'nonOpenedNotificationCount',
    ])
};

const mapDispatchToProps = {
    GetLanguages,
    GetGenres,
    togglePlayState,
    TokenValidation,
    toggleNotificationRow
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
