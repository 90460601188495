// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

//Import components
import SquareSkeletonCard from "../../components/skeletonCards/SquareSkeletonCard";
import {CardComponents} from "../../components/cards/BaseCard";

//import utils
import {GetPodcastsList, CleaSections} from "../../redux/actions";
import {getPropsFromState} from "../../redux/mapStateToProps";

function PodcastTab(props) {
    const {data, loading} = props;
    const [isFetching, setFetching] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    const setSkeletonCount = () => {
        if (window.innerWidth > 800) {
            return 8
        }else {
            return 6
        }
    }

    const loadMorePlaylist = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetPodcastsList({
                reset: false,
                offset: data?.list?.length
            })
                .finally(() => {
                    setFetching(false);
                });
        }
    };

    return <div className="homepage-albums-wrapper">
        <div className='section-title'>
            <span>Popular Podcast</span>
        </div>
        {!loading ? (!!data?.list?.length &&
            <InfiniteScroll
                hasMore={data?.hasMore}
                loadMore={loadMorePlaylist}
                className={'base-cards-list'}
                pageStart={0}
                useWindow={true}
                initialLoad={false}>
                {
                    data?.list?.map(item => {
                        return <CardComponents.PodcastCard podcast={item} key={item.id}/>
                    })
                }
            </InfiniteScroll>)
            : <div className={'base-cards-list'}>
            <SquareSkeletonCard count={setSkeletonCount()} withoutDescription/>
        </div>}
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    GetPodcastsList,
    CleaSections
};

export default connect(mapStateToProps, mapDispatchToProps)(PodcastTab);