import {initialState as auth} from "../reducers/auth";
import {initialState as general} from "../reducers/general";
import {initialState as utils} from "../reducers/utils";
import {initialState as tracks} from "../reducers/tracks";
import {initialState as albums} from "../reducers/albums";
import {initialState as sections} from "../reducers/sections";
import {initialState as artists} from "../reducers/artists";
import {initialState as userPlaylist} from "../reducers/userPlaylist";
import {initialState as player} from "../reducers/player";
import {initialState as notifications} from "../reducers/notifications";
import {initialState as userPodcasts} from "../reducers/userPodcasts";

const reducers = {
    auth,
    general,
    utils,
    tracks,
    albums,
    sections,
    artists,
    userPlaylist,
    player,
    notifications,
    userPodcasts,
};

export default state => {
    const sData = {};
    Object.keys(reducers).forEach(selectorKey => {
        Object.keys(reducers[selectorKey]).forEach(key => {
            sData[key] = state[selectorKey][key];
        });
    });
    return sData;
};





