import {combineReducers} from "redux";

// import reducers
import auth from "./auth";
import general from './general';
import utils from "./utils";
import tracks from "./tracks";
import albums from "./albums";
import sections from "./sections";
import artists from "./artists";
import userPlaylist from "./userPlaylist";
import player from "./player";
import notifications from "./notifications";
import userPodcasts from "./userPodcasts";

export default combineReducers({
    auth,
    general,
    utils,
    tracks,
    albums,
    sections,
    artists,
    userPlaylist,
    player,
    notifications,
    userPodcasts,
});
