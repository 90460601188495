import {ARTISTS_CONSTS} from "../constants";

export const initialState = {
    artists: {
        list: [],
        text: '',
        hasMore: false,
        loading: false
    },
    userFavoriteArtists: {
        list: [],
        text: '',
        hasMore: false,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ARTISTS_CONSTS.GET_ARTISTS:
            const newArtistsList = {
                list: action.payload.reset ? action.payload.data : [...state.artists.list, ...action.payload.data],
                text: action.payload.text,
                hasMore: action.payload.hasMore
            };
            return {
                ...state,
                artists: newArtistsList
            };

        case ARTISTS_CONSTS.CLEAR_ARTISTS:
            return {
                ...state,
                artists: {...initialState.artists},
            };

        case ARTISTS_CONSTS.GET_FAVORITE_ARTISTS:
            return {
                ...state,
                userFavoriteArtists: {
                    list: action.payload.reset ? action.payload.data : [...state.userFavoriteArtists.list, ...action.payload.data],
                    hasMore: action.payload.hasMore
                },
            };

        case ARTISTS_CONSTS.TOGGLE_ARTIST_FAVORITE:
            state.artists.list.map(item => item.id === action.payload.id ? console.log("item?.likeCount", item?.likeCount) : console.log("item?.likeCountno"));
            return {
                ...state,
                artists: {
                    ...state.artists,
                    list: state.artists.list.map(item => item.id === action.payload.id ?
                        // {
                        //     ...item,
                        //     isFavorite: action.payload.likeState,
                        //     likeCount: item?.likeCount + 1
                        // }
                        console.log("item?.likeCount", item?.likeCount)
                        : item
                    )
                },
                userFavoriteArtists: {
                    ...state.userFavoriteArtists,
                    list: state.userFavoriteArtists.list.filter(item => item.id !== action.payload.id),
                    text: action.payload.text,
                    hasMore: action.payload.hasMore
                },
            };

        default:
            return state;
    }
}
