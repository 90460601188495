// import packages
import React from "react";
import {Link} from "react-router-dom";
import {history} from "../../configs/history";

// import assets
import '../../assets/styles/components/cards/base-card.scss'
import {ReactComponent as ContentWarning} from "../../assets/images/icons/ic_content_warning.svg";

//import utils
import {Image} from "../uIElements/Image";


function navigateToPage(e, route) {
    e.preventDefault();
    e.stopPropagation();
    history.push({
        pathname: route,
    })
}

export class CardComponents extends React.Component {
    static AlbumCard({album}) {
        return <Link to={{pathname: `/album/${album?.slug}`, state: {album}}}
                     className="card-link"
                     key={album.id}>
            <div className="card-item album-card">
                <Image url={album?.coverImage?.path}/>
                <div className="card-item-content">
                    <div className="card-title-wrapper">
                        <div className="card-item-title">{album?.title}</div>
                        <div className="e-icon">{album?.contentWarning && <ContentWarning/>}</div>
                    </div>
                    <div className="card-item-description ">
                        {!!album?.artists?.length && album?.artists?.map((item, index) => {
                            return !item?.isHidden ?
                                <span onClick={(e) => navigateToPage(e, `/artist/${item?.slug}/songs`)}
                                      className="card-item-description card-artist"
                                      key={item.id}>{item?.fullName}{index + 1 < album?.artists?.length &&
                                    <span>,&nbsp;</span>}
                                </span>
                                : <span onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault()
                                }}
                                        className="card-item-description"
                                        key={item.id}>{item?.fullName}{index + 1 < album?.artists?.length &&
                                    <span>,&nbsp;</span>}
                                </span>
                        })}
                    </div>
                </div>
            </div>
        </Link>
    }

    static ArtistCard({artist, blured, onClick, checkedArtists}) {
        return <>
            {!onClick ?
                <Link to={{pathname: `/artist/${artist?.slug}/songs`, state: {artist}}}
                      className="card-link"
                      key={artist.id}>
                    <div className="card-item artist-card">
                        <Image url={artist?.profileImage?.path} type={"user"} blured={blured}/>
                        <div className="card-item-content">
                            <div className="card-title-wrapper">
                                <div className="card-item-title">{artist?.fullName}</div>
                            </div>
                        </div>
                    </div>
                </Link> :
                <div className="card-link" key={artist.id} onClick={onClick}>
                    <div className="card-item artist-card">
                        <Image url={artist?.profileImage?.path} type={"user"} blured={blured}/>
                        <div className="card-item-content">
                            <div className="card-title-wrapper">
                                <div className="card-item-title">{artist?.fullName}</div>
                            </div>

                        </div>
                        {checkedArtists.includes(artist.id) &&
                            <img className="check-icon" src={require("../../assets/images/icons/ic_check_artist.svg")}
                                 alt={""}/>}
                    </div>
                </div>}
        </>

    }

    static PodcastCard({podcast}) {
        return <Link to={{pathname: `/podcast/${podcast?.id}`, state: {podcast}}}
                     className="card-link"
                     key={podcast.id}>
            <div className="card-item album-card">
                <Image url={podcast?.coverImage?.path}/>
                <div className="card-item-content">
                    <div className="card-title-wrapper">
                        <div className="card-item-title">{podcast?.name}</div>
                        <div className="e-icon">{podcast?.contentWarning && <ContentWarning/>}</div>
                    </div>
                    <div className="card-item-description ">
                        <span className="card-item-description card-artist" key={podcast.id}>
                            {podcast?.owner?.firstName || ''}&nbsp;{podcast?.owner?.lastName || ''}
                                </span>
                    </div>
                </div>
            </div>
        </Link>
    }
}
