// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useLocation, useParams} from 'react-router-dom';
import {history} from "../configs/history";
import Nestable from "react-nestable";

//import Assets
import '../assets/styles/containers/track-details.scss'
import {ReactComponent as PlayIcon} from "../assets/images/playerBar-icons/ic_play_white.svg"

//import components
import TrackCard from "../components/cards/TrackCard";
import MetaTags from "../components/MetaTags";

//import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {
    GetTracks,
    ClearTracks,
    GetPlaylistTracks,
    UpdatePlaylistTracksPositions,
    RemoveTrackFromPlaylist,
    GetPlaylists,
    setCurrentPlayedTrack, togglePlayState
} from "../redux/actions";
import {SkeletonItem} from "../components/uIElements/SkelatonItem";
import SkeletonTrackCard from "../components/skeletonCards/SkeletonTrackCard";
import {PageSkeleton} from "./PageSkeleton";
import {Image} from "../components/uIElements/Image";
import {ReactComponent as PauseIcon} from "../assets/images/playerBar-icons/ic_pause_white.svg";

function UserPlaylistTracks(props) {
    const [tracksList, setTracksList] = useState([]);
    const [loading, setLoading] = useState(true);

    const params = useParams();
    const location = useLocation();
    const {userPlaylists} = props

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        props.GetPlaylists().then(() => {
            props.GetPlaylistTracks(params.id).then(res => {
                setTracksList(res.data);
                res?.data[0]?.id && history.push(`/profile/playlist/${params?.id}`)
                setLoading(false)
            });
        })


    }, [params.id]);
    const playlist = userPlaylists?.list.find(item => item?.id === params?.id)

    const toggleFavoriteState = (id, favorite) => {
        setTracksList(tracksList.map(track => track.id === id ?
            {
                ...track,
                isFavorite: favorite
            } : track
        ))
    }
    const removeFromPlaylist = (trackId, index) => {
        props.RemoveTrackFromPlaylist(params.id, trackId, index)
        setTracksList(tracksList.filter((track, i) => i !== index))
    }
    const addToPlaylist = (id, track) => {
        tracksList.unshift(track)
        console.log("newTracksList", tracksList)
        // setTracksList(newTracksList)
    }

    const onItemsPositionsChange = (nestableItems, item) => {
        const movedItemIndex = item.index;
        const positionedItems = nestableItems.map((item, index) => {
            return {track: item.data.id, position: index}
        });
        if (nestableItems.findIndex(m => m.index === movedItemIndex) !== item?.index) {
            // first level menu was re-positioned in first level
            props.UpdatePlaylistTracksPositions(params.id, positionedItems);
            const itemsList = nestableItems.map(item => item?.data);
            setTracksList(itemsList)

        }
    }
    const getNestableItems = (list) => {

        const items = [];
        list && !!list.length && list.forEach((item, index) => {
            items.push({
                id: item.id + index,
                data: item,
                index: index,
            })
        });
        return items;
    }

    function setTrack() {
        if (location?.pathname.includes(props?.type)) {
            togglePlayState(!props.playState)
        } else {
            setCurrentPlayedTrack(tracksList[0], tracksList, location?.pathname, 0)
            togglePlayState(true)
        }
    }

    let nestableItems = getNestableItems(tracksList);
    const renderItem = ({item}) => {
        return <TrackCard track={item.data}
                          playerTracksList={tracksList}
                          indexInPlayerList={item.index}
                          key={item.index}
                          toggleFavoriteState={toggleFavoriteState}
                          removeFromPlaylist={removeFromPlaylist}
                          addToPlaylist={addToPlaylist}
                          // index={item.index + 1}
        />
    }
    return <PageSkeleton withPadding>
        <MetaTags title={playlist?.name}/>
        <div className="playlist-header">
            <div className="img-wrapper">
                <Image url={playlist?.firstTrackImage?.path} loading={loading}/>
            </div>
            <div className="playlist-description-content">
                <div style={{width: "100%"}}>
                    <div className="page-name">PLAYLIST</div>
                    <div className="playlist-name">{!loading ? playlist?.name :
                        <div style={{width: "100%", height: "35px", marginTop: "10px"}}>
                            <SkeletonItem/>
                        </div>}
                    </div>
                    <div
                        className="playlist-description">{!loading ? (tracksList?.length ? tracksList?.length : "no") + " songs" :
                        <div style={{width: "50%", height: "25px", marginTop: "15px", marginBottom: "5px"}}>
                            <SkeletonItem/>
                        </div>}
                    </div>
                </div>
                <div className="btns-wrapper">
                    <span className="play-all" onClick={setTrack}>
                       {location.pathname.includes(props?.type) && props?.playState ?
                           <PauseIcon title={''} className="play-icon"/> : <PlayIcon title={''} className="play-icon"/>}
                        Play
                    </span>
                </div>

            </div>
        </div>
        <div className="section-header"><span>All songs</span></div>
        {!loading ?
            (!!tracksList?.length && <div className="artist-top-song-list">

                <Nestable
                    items={nestableItems}
                    maxDepth={1}
                    onChange={onItemsPositionsChange}
                    renderItem={renderItem}/>
            </div>) :
            <SkeletonTrackCard count={3}/>
        }
    </PageSkeleton>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'tracks',
        'userPlaylists',
        'requestLoading',
        'type',
        'playState'
    ])
};

const mapDispatchToProps = {
    GetTracks,
    ClearTracks,
    GetPlaylistTracks,
    UpdatePlaylistTracksPositions,
    RemoveTrackFromPlaylist,
    GetPlaylists,
    setCurrentPlayedTrack,
    togglePlayState
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPlaylistTracks);