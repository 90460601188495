// import packages
import React, {useEffect} from "react";

// Import assets
import '../assets/styles/containers/about.scss'


function About() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

    }, []);
    return <div className="about-wrapper">
        <div className="about-banner">
            <img className={"circle-bg"} src={require("../assets/images/circle_bg.png")} alt={""}/>
            <div>
                <h1 className="banner-title">About Us</h1>
                <div className="banner-description">Follow us online to find out when we launch.</div>
            </div>
        </div>
        <div className="about-content">
            <div className="info-wrapper">
                <div className="about-title">How can we help you?</div>
                <div className="about-subtitle">YouTop</div>
                <div className="description">
                    You Top gives you instant access to millions of songs
                    from old favorites to the latest hits. It's an amazing app,
                    available now in the Apple and Android stores.
                    And we're always looking for new and innovative ways
                    to delight our users.
                </div>
            </div>
            <div className="img-wrapper">
                <div className="img-content">
                    <picture>
                        <source media="(max-width:600px)" srcSet={require("../assets/images/login_sm.webp")}/>
                        <img src={require("../assets/images/login_lg.webp")} alt={""}
                             className="login-bg"/>
                    </picture>
                </div>
            </div>
        </div>
    </div>
}

export default About;
