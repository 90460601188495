// import packages
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";

//Import components
import {SeeAll} from "../../components/uIElements/buttons";
import PlayListCard from "../../components/cards/PlayListCard";
import SquareSkeletonCard from "../../components/skeletonCards/SquareSkeletonCard";

//import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {CardComponents} from "../../components/cards/BaseCard";


function Podcasts(props) {

    useEffect(() => {
        // console.log('aaaaaa');
    }, []);

    const setSkeletonCount = () => {
        if (window.innerWidth > 800) {
            return 4
        } else {
            return 3
        }
    }

    const {data, loading} = props;
    const playlistsList = window.innerWidth > 800 ? data?.slice(0, 4) : data?.slice(0, 3)

    return <div className="homepage-albums-wrapper">
        <div className='section-title'>
            <span>Popular Podcasts</span>
            {data?.length > 4 && <SeeAll link={`home/podcasts`}/>}
        </div>
        {!loading ? (!!playlistsList?.length && <div className="base-cards-list">
            {
                playlistsList?.map(item => {
                    return <CardComponents.PodcastCard podcast={item} key={item.id}/>

                })
            }
        </div>) : <div className={'base-cards-list'}>
            <SquareSkeletonCard count={setSkeletonCount()} withoutDescription/>
        </div>}
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [])
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Podcasts);