import React, {useState} from "react";
import Modal from 'react-modal';
import {connect} from "react-redux";

import '../../assets/styles/components/uiElements/report-modal.scss';
import {ReactComponent as CloseIcon} from '../../assets/images/icons/ic_close_gray.svg';

import {InputGroup} from "./inputGroup";

import {REPORT_TYPES} from "../../constants/constTypes";
import {AddTrackReport} from "../../redux/actions";
import {mapStateToProps} from "../../redux/mapStateToProps";

Modal.setAppElement(document.getElementById('root'))

function ReportModal({isReportVisible, toggleReport, trackId, AddTrackReport}) {
    const [options, setOptions] = useState([
        {id: REPORT_TYPES.INCONSISTENT_ARTIST, value: "This track wasn't recorded by the artist shown", checked: false},
        {id: REPORT_TYPES.AUDIO_PROBLEM, value: "There's an audio problem", checked: false},
        {id: REPORT_TYPES.UNDESIRABLE_CONTENT, value: "Undesirable content", checked: false},
        {id: REPORT_TYPES.OTHER, value: "Other", checked: false}
    ]);
    const [report, setReport] = useState({
        title: '',
        description: ''
    })
    const [error, setError] = useState({
        title: false,
        description: false
    })

    const handleChange = (value) => {
        let checkedOptions = options.map(option => {
            return option.id === value ? {...option, checked: !option.checked} : option
        })
        if (value === REPORT_TYPES.OTHER) {
            setReport({
                title: '',
                description: ''
            })
        }
        setOptions(checkedOptions)
    }
    const handleChangeInput = (e) => {
        const {name, value} = e.target
        setReport({
            ...report,
            [name]: value
        })
        setError({
            ...error,
            [name]: false
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let types = [];
        options.forEach(item => {
            if (item.checked) {
                types.push(item.id)
            }
        })
        let reqData = {types}
        if (types.includes(REPORT_TYPES.OTHER)){
            setError({
                title: !report.title,
                description: !report.description
            })
        }
        report.title && (reqData.title = report.title)
        report.description && (reqData.description = report.description)
        AddTrackReport(trackId, reqData).then(()=>{
            toggleReport()
            setReport({
                title: '',
                description: ''
            })
            setOptions(options.map(option => {
                return option.checked ? {...option, checked: false} : option
            }))
        })
    }

    return <Modal isOpen={isReportVisible}
                  onRequestClose={toggleReport}
                  className={'modal-wrapper'}
                  overlayClassName={'modal-overlay'}>
        <header className={'modal-header'}>
            <span>Report problem</span>
            <CloseIcon onClick={toggleReport}/>
        </header>
        <div className="modal-content">
            <p className="modal-title">
                Report a bug or request a feature
            </p>
            {
                options?.map(item => {
                    return <div key={item.id}
                                className={'check-item'}>
                        <span className={'check-item-content'}
                              onClick={() => handleChange(item.id)}>
                            <span
                                className={`checkbox ${item?.checked ? 'checked' : ''}`}><small>&#10003;</small></span>
                            {item.value}
                        </span>
                    </div>
                })
            }
            {options.find(item => item.id === REPORT_TYPES.OTHER)?.checked && <>
                <InputGroup inputType={'input'}
                            type={'text'}
                            value={report.title}
                            placeholder={"Title"}
                            label={"Title"}
                            name={'title'}
                            error={error.title}
                            maxLength={50}
                    // className={'report-title'}
                            onChange={handleChangeInput}/>

                <InputGroup inputType={'textarea'}
                            type={'text'}
                            value={report.description}
                            placeholder={"Enter a Description"}
                            label={"Description"}
                            name={'description'}
                            error={error.description}
                    // className={'report-title'}
                            onChange={handleChangeInput}/></>}

            <input type="submit" value="Submit" onClick={handleSubmit} className={'link-button'}/>
        </div>
    </Modal>
}

const mapDispatchToProps = {
    AddTrackReport
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportModal);