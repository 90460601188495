// import packages
import React from "react";
import {Link, useHistory, useParams} from 'react-router-dom';
//import assets
import "../assets/styles/containers/search.scss";

// import Components
import PageWrapper from "./PageWrapper";
import All from "../components/searchTabs/All";
import Tracks from "../components/searchTabs/Tracks";
import Artists from "../components/searchTabs/Artists";
import PlayLists from "../components/searchTabs/PlayList";
import Albums from "../components/searchTabs/Albums";

//import utils
import {searchTabs} from "../constants/constants";
import {connect} from "react-redux";
import {getPropsFromState} from "../redux/mapStateToProps";
import Podcasts from "../components/searchTabs/Podcasts";

function Search(props) {
    const params = useParams();
    // console.log("params", params)
    let {tab: currentTab} = params;
    const {albums, tracks, sections, artists, podcasts} = props?.suggestions;


    return <PageWrapper>
            <div className="tabs-wrapper">
                <ul className="tabs-list">
                    {
                        Object.keys(searchTabs).map((key, index) => {
                            const item = searchTabs[key];
                            return <Link to={`/search/${key}/${params.text}`}
                                         className={`tab-item ${currentTab === key ? 'active' : ''}`}
                                         key={index}>
                                {item.name}
                            </Link>
                        })
                    }
                </ul>
            </div>

            <All tracks={tracks}
                 albums={albums}
                 sections={sections}
                 artists={artists}
                 podcasts={podcasts}
                 text={params.text}
                 className={currentTab === searchTabs.all.id ? 'show' : 'hide'}
            />
            <Tracks text={params.text}
                    className={currentTab === searchTabs.songs.id ? 'show' : 'hide'}/>
            <Artists text={params.text}
                     className={currentTab === searchTabs.artists.id ? 'show' : 'hide'}/>
            <PlayLists text={params.text}
                       className={currentTab === searchTabs.playLists.id ? 'show' : 'hide'}/>
            <Albums text={params.text}
                    className={currentTab === searchTabs.albums.id ? 'show' : 'hide'}/>
            <Podcasts text={params.text}
                    className={currentTab === searchTabs.podcasts.id ? 'show' : 'hide'}/>
    </PageWrapper>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'suggestions',
    ])
};

export default connect(mapStateToProps)(Search);
