// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link, useParams, useLocation} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {FacebookShareButton} from "react-share";

//import Assets
import '../assets/styles/containers/album-details.scss';
import {ReactComponent as PlayIcon} from "../assets/images/playerBar-icons/ic_play_white.svg"
import {ReactComponent as FacebookIcon} from "../assets/images/icons/ic_facebook_share.svg";
import {ReactComponent as ShareSongIcon} from "../assets/images/playerBar-icons/ic_share.svg";
import {ReactComponent as FillHeart} from "../assets/images/playerBar-icons/ic_heart_fill.svg";
import {ReactComponent as EmptyHeart} from "../assets/images/playerBar-icons/ic_heart_empty.svg";
import {ReactComponent as CopyIcon} from "../assets/images/icons/ic_copy_link.svg";

//import components
import TrackCard from "../components/cards/TrackCard";
import MetaTags from "../components/MetaTags";
import {LinkButton} from "../components/uIElements/buttons";
import {SkeletonItem} from "../components/uIElements/SkelatonItem";
import SkeletonTrackCard from "../components/skeletonCards/SkeletonTrackCard";
import {PageSkeleton} from "./PageSkeleton";

//import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetTracks, ClearTracks, setCurrentPlayedTrack, togglePlayState, GetTrackById} from "../redux/actions";
import {RemoveAlbumFromFavorites, AddAlbumToFavorites} from "../redux/actions/albumsActions";
import {GetAlbumById} from "../redux/actions/albumsActions";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import {Image} from "../components/uIElements/Image";
import {ReactComponent as PauseIcon} from "../assets/images/playerBar-icons/ic_pause_white.svg";
import {ALBUM_TYPES} from "../constants/constants";
import {shuffle} from "../utils/helperFunctions";
import {history} from "../configs/history";


function AlbumDetails(props) {
    const params = useParams();
    const location = useLocation();
    const {isLoggedIn} = props;
    const [shareWrapperIsOpen, setShareWrapperOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [album, setAlbum] = useState({
        ...location.state?.album || {},
    });
    const [track, setTrack] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        // console.log(location?.state?.fromNotification, 'LOCATION');
        props.GetAlbumById(params?.slug).then((album) => {
            setAlbum(album.data);
            if(location?.state?.fromNotification){
                history.push({
                    pathname: `/album/${album?.data?.slug}`,
                })
            }
            props.GetTracks({album: album?.data?.id}).then((tracks) => {
                setLoading(false);
                params.trackSlug && props.GetTrackById(params.trackSlug).then((res) => {
                    setTrack(res.data);
                    // if (tracks.find(item => item.slug === params.trackSlug)) {
                    setCurrentPlayedTrack(res.data, tracks, location?.pathname, tracks?.findIndex((el) => el.id === res.data.id))
                    // } else {
                    //     tracks.unshift(res.data)
                    //     setCurrentPlayedTrack(res.data, tracks, location?.pathname, 0)
                    // }
                })
            });

        });

        window.addEventListener('click', onClicked);
        return () => {
            window.removeEventListener('click', onClicked)
        }

        return function cleanup() {
            props.ClearTracks();
        };
    }, [params?.slug]);

    function onClicked() {
        setShareWrapperOpen(false);
    }

    function toggleFavorite() {
        if (isLoggedIn) {
            if (!isFetching) {
                setFetching(true);
                const favoritePromise = album.isFavorite ? props.RemoveAlbumFromFavorites(album.id) : props.AddAlbumToFavorites(album.id);
                favoritePromise.finally(() => {
                    setFetching(false);
                    setAlbum({
                        ...album,
                        isFavorite: !album.isFavorite,
                        likeCount: album.isFavorite ? album.likeCount - 1 : album.likeCount + 1
                    })
                })
            }
        }
    }

    const loadMoreItems = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetTracks({
                reset: false,
                offset: tracks?.list?.length,
                album: album?.id
            }).finally(() => {
                setFetching(false);
            });
        }
    };

    function toggleShareWrapper(e) {
        e && e.preventDefault();
        e && e.stopPropagation();
        setShareWrapperOpen(!shareWrapperIsOpen);

    }

    function setCurrentTrack() {
        if (tracks?.list?.length) {
            if (location?.pathname.includes(props?.type)) {
                togglePlayState(!props.playState)
            } else {
                setCurrentPlayedTrack(tracks?.list[0], tracks?.list, location?.pathname, 0, shuffle(tracks?.list));
                togglePlayState(true)
            }
        }
    }

    const {tracks} = props;

    return <PageSkeleton withPadding>
        <MetaTags title={album?.title}
                  image={generateImageMediaUrl(album?.coverImage?.path)}/>
        <div className="album-header">
            <div className="img-wrapper">
                <Image url={album?.coverImage?.path} loading={loading}/>
            </div>
            <div className="album-description-content">
                <div style={{width: "100%"}}>
                    <div
                        className="page-name">{album.type == ALBUM_TYPES.PLAYLIST.key ? "PLAYLIST" : (album.type == ALBUM_TYPES.MAIN.key ? "ALBUM" : "SINGLE")}</div>
                    <div className="album-name">{!loading ? album?.title :
                        <div style={{width: "100%", height: "30px", marginTop: "15px"}}>
                            <SkeletonItem/>
                        </div>}
                    </div>
                    {/*<div*/}
                    {/*    className="album-description">{!loading ? (album?.tracksCount ? album?.tracksCount + " songs" : "0 songs") :*/}
                    {/*    <div style={{width: "50%", height: "20px", marginTop: "5px", marginBottom: "5px"}}>*/}
                    {/*        <SkeletonItem/>*/}
                    {/*    </div>}*/}
                    {/*</div>*/}
                    <div className="album-description artists">
                        {!loading ? (!!album?.artists?.length && album?.artists?.map((item, index) => {
                                return !item?.isHidden ? <Link to={`/artist/${item?.slug}/songs`}
                                                               className="artists-item active-artist"
                                                               key={item.id}>
                                        {item?.fullName}
                                        {index + 1 < album?.artists?.length && <span>,&nbsp;</span>}
                                    </Link>
                                    : <span className="artists-item "
                                            key={item.id}>
                            {item?.fullName}
                                        {index + 1 < album?.artists?.length && <span>,&nbsp;</span>}
                        </span>
                            })) :
                            <div style={{width: "50%", height: "20px", marginTop: "0", marginBottom: "5px"}}>
                                <SkeletonItem/>
                            </div>
                        }</div>
                </div>
                <div className="btns-wrapper">
                    <span className="play-all" onClick={setCurrentTrack}>
                        {location.pathname.includes(props?.type) && props?.playState ?
                            <PauseIcon title={''} className="play-icon"/> :
                            <PlayIcon title={''} className="play-icon"/>}
                        Play
                    </span>
                    <div className="heart-dropdown-wrapper" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleFavorite()
                    }
                    }>
                        <div className={`heart-wrapper ${album?.likeCount > 0 ? "have-likes" : ""}`}>
                            {album?.isFavorite ?
                                <FillHeart className='card-heart fill-heart'/>
                                : <EmptyHeart className='card-heart' title=''/>}
                            {album?.likeCount !== 0 && <div className="like-count">{album?.likeCount}</div>}
                        </div>
                        {!isLoggedIn && <div className={`heart-dropdown-content`}>
                            <span>Please sign in to save your favorites</span>
                            <LinkButton title="Sign in"
                                        link={"/sign-in"}
                            />
                        </div>
                        }
                    </div>
                    <div className="share-btn" onClick={toggleShareWrapper}>
                        <ShareSongIcon title=''/>
                        {shareWrapperIsOpen && <div className="dropdown-content">
                            <div className="share-wrapper">

                                <CopyToClipboard text={`http://youtop.app/album/${album?.slug}`}
                                                 className={"copy-wrapper"}
                                                 onCopy={() => setCopied(true)}
                                                 onMouseLeave={() => setCopied(false)}>
                                    <button onClick={e => {
                                        e.stopPropagation()
                                    }}>
                                        <CopyIcon/> Copy url
                                        <div className="copy-text">{copied ? "Copied" : 'Copy'}</div>
                                    </button>
                                </CopyToClipboard>

                                <FacebookShareButton url={`http://youtop.app/album/${album?.slug}`}
                                    // quote={`${album.title}`}
                                >
                                    <FacebookIcon/> Facebook
                                </FacebookShareButton>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        <div className="section-header"><span>All songs</span></div>
        {!loading ?
            (!!tracks?.list?.length ? <div className="artist-top-song-list">
                    <InfiniteScroll
                        hasMore={tracks?.hasMore}
                        loadMore={loadMoreItems}
                        className={'tracks-list'}
                        pageStart={0}
                        useWindow={true}
                        initialLoad={false}>
                        {
                            tracks?.list?.map((item, index) => {
                                return <TrackCard key={item.id}
                                                  track={item}
                                                  playerTracksList={tracks?.list}
                                                  indexInPlayerList={index}
                                                  path={album?.type !== ALBUM_TYPES.PLAYLIST.key && album?.coverImage?.path}
                                                  index={index + 1}
                                />
                            })
                        }
                    </InfiniteScroll>
                </div> :
                <div className="no-result">
                    <span>This album have no tracks</span>
                </div>) :
            <SkeletonTrackCard count={3}/>

        }
    </PageSkeleton>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isLoggedIn',
        'tracks',
        'requestLoading',
        'type',
        'playState',
        'shuffled',
        'shuffledTracksList',
    ])
};

const mapDispatchToProps = {
    GetTracks,
    ClearTracks,
    GetAlbumById,
    AddAlbumToFavorites,
    RemoveAlbumFromFavorites,
    togglePlayState,
    GetTrackById
};

export default connect(mapStateToProps, mapDispatchToProps)(AlbumDetails);
