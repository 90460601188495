// import packages
import React from "react";
import {useHistory} from "react-router-dom";

// import assets
import '../../assets/styles/components/cards/genre-card.scss'

//import utils
import {generateFileMediaUrl} from "../../utils/generateMediaUrl";
import {Image} from "../uIElements/Image";


function GenreCard(props) {
    const {data} = props;
    const history = useHistory();

    const onClickGenre = (genreId) => {
        history.push(`/genres/${genreId}`)
    };

    return <div className="genre-card-item" key={data.id}
                onClick={() => onClickGenre(data.id)}>
        <Image url={data?.icon?.path}/>
        <div className="genre-item-title">{data?.title}</div>
    </div>
}

export default GenreCard;