// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';

//Import assets

//import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetTracks, ClearTracks} from "../../redux/actions";
import TrackCard from "../cards/TrackCard";
import SquareSkeletonCard from "../skeletonCards/SquareSkeletonCard";
import SkeletonTrackCard from "../skeletonCards/SkeletonTrackCard";


function Tracks(props) {
    const [isFetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(true);
    const {tracks, artistId, className} = props;
    const params = useParams();
    useEffect(() => {
        (props.text || params.text) && props.GetTracks({
            text: props.text || params.text,
        }).then(() => {
            setLoading(false)
        });
        // console.log(params.id === artistId, 'TEXT');
        artistId && props.GetTracks({
            artist: artistId,
        }).then(() => {
            setLoading(false)
        });

        return function cleanup() {
            !props.text && props.ClearTracks();
        };
    }, [props.text, params.text]);

    const loadMoreItems = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetTracks({
                text: props.text,
                artist: artistId,
                reset: false,
                offset: tracks?.list?.length
            }).finally(() => {
                setFetching(false);
            });
        }
    };
    return <section className={className}>
        {loading && <div className={'section-header'}>
            <span>Songs</span>
        </div>}
        {!loading ?
            (!!tracks?.list?.length ? <div className="search-tracks-wrapper">
                    {!artistId && <div className={'section-header'}>
                        <span>Songs</span>
                    </div>}
                    <InfiniteScroll
                        hasMore={tracks?.hasMore}
                        loadMore={loadMoreItems}
                        className={'tracks-list'}
                        pageStart={0}
                        useWindow={true}
                        initialLoad={false}>
                        {
                            tracks?.list?.map((track, index) => {
                                return <TrackCard track={track}
                                                  playerTracksList={tracks?.list}
                                                  indexInPlayerList={index}
                                                  key={track.id}/>
                            })
                        }
                    </InfiniteScroll>
                </div>
                : <div className="no-search-result">
                    There are no tracks with this name in the TouTop
                </div>) :
            <SkeletonTrackCard count={3}/>
        }

    </section>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'tracks',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    GetTracks,
    ClearTracks
};

export default connect(mapStateToProps, mapDispatchToProps)(Tracks);
