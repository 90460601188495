// import packages
import React, {Component, useEffect, useState} from "react";
import {connect} from 'react-redux';

//Import Images
import '../assets/styles/containers/preferred-genres.scss';
import {ReactComponent as BackIcon} from "../assets/images/icons/ic_prev.svg";
import {ReactComponent as SearchIcon} from "../assets/images/icons/ic_search.svg";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {SocialSignIn, GetArtists, UpdateCurrentUser} from "../redux/actions";
import {LinkButton} from "../components/uIElements/buttons";
import {CardComponents} from "../components/cards/BaseCard";
import {history} from "../configs/history";
import {InputGroup} from "../components/uIElements/inputGroup";
import InfiniteScroll from "react-infinite-scroller";

const contentTypes = ["genres", "artists"]

function PreferredGenres(props) {
    const [currentType, setCurrentType] = useState(contentTypes[0])
    const [searchValue, setSearchValue] = useState("")
    const [isFetching, setFetching] = useState(false)
    const [itemsList, setItemsList] = useState({
        genres: [],
        artists: []
    });

    const onClickItem = (id, type) => {
        setItemsList((itemsList) => {
            return {
                ...itemsList,
                [type]: itemsList[type].includes(id) ? itemsList[type].filter(item => item !== id) : itemsList[type].concat(id)
            }

        })

    };

    const clearItems = (type) => {
        setItemsList((itemsList) => {
            return {
                ...itemsList,
                [type]: []
            }
        })

    };

    function goToArtists() {
        props.GetArtists({genreIds: itemsList.genres.join(",")}).then(() => {
            setCurrentType(contentTypes[1])
        })
    }

    function goBack() {
        setCurrentType(contentTypes[0])
    }

    function setUserPreferred() {
        console.log("ok")
        let reqData = {};
        itemsList.genres && (reqData.preferredGenres = itemsList.genres);
        itemsList.artists && (reqData.preferredArtists = itemsList.artists);
        props.UpdateCurrentUser(reqData).then(() => {
            history.push("/")
        })
    }

    function getSearchValue(e) {
        let {value} = e.target;
        setSearchValue(value);
            !value && searchItems()
    }

    function searchItems() {
        searchValue?.length !== 1 && props.GetArtists({
            text: searchValue,
            genreIds: itemsList.genres.join(",")
        });
    }

    const loadMoreArtists = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetArtists({
                reset: false,
                offset: artists?.list?.length,
                text: searchValue,
                genreIds: itemsList.genres.join(",")
            }).finally(() => {
                setFetching(false);
            });
        }
    };


    const {genres, artists, currentPlayedTrack} = props;

    return <div className="preferred-genres-wrapper">
        <div className="left-side">
            <picture>
                <source media="(max-width:600px)" srcSet={require("../assets/images/login_sm.webp")}/>
                <img src={require("../assets/images/login_lg.webp")} alt={""}
                     className="login-bg"/>
            </picture>
        </div>
        <div className="right-side">
            <div className="preferred-list-header">
                <h1 className="form-title">{currentType === contentTypes[0] ? "Select Genres" : "Select Artists"}</h1>
                {!!itemsList[currentType].length &&
                <span className="clear-items" onClick={() => clearItems(currentType)}>Clear</span>}
            </div>
            {currentType === contentTypes[1] && <div className="search-wrapper">
                <div className="back-btn" onClick={goBack}><BackIcon/> <span>Back</span></div>
                <InputGroup inputType="input"
                            type="text"
                            placeholder="Որոնել"
                            name="text"
                            maxLength={256}
                            onKeyDown={(e) => e && e.key === 'Enter' && searchItems?.()}
                            value={searchValue || ''}
                            onChange={(e) => getSearchValue(e)}
                />
                <SearchIcon className="search-icon"/>
            </div>
            }
            <div className="right-side-main-content">
                <div className={`preferred-list-wrapper ${!currentPlayedTrack ? "long-content" : ""}`}>
                    {currentType === contentTypes[0] ?
                        genres && !!genres?.length && genres?.map((genre) =>
                            <div className="genre-content" key={genre?.id}>
                                <div className={`genre-item ${itemsList.genres.includes(genre.id) ? "active-genre" : ""}`}
                                     key={genre.id}
                                     onClick={() => onClickItem(genre.id, currentType)}>
                                    {genre?.title}
                                </div>
                            </div>
                        )
                        :
                        <InfiniteScroll
                            hasMore={artists?.hasMore}
                            loadMore={loadMoreArtists}
                            className={'base-cards-list'}
                            pageStart={0}
                            useWindow={false}
                            initialLoad={false}>
                            {artists && !!artists?.list?.length && artists?.list?.map((artist) => {
                            return <CardComponents.ArtistCard blured artist={artist}
                                                              checkedArtists={itemsList.artists}
                                                              key={artist.id}
                                                              onClick={() => onClickItem(artist.id, currentType)}/>
                        })}
                            </InfiniteScroll>
                    }

                </div>
                <div className={`right-btn ${!itemsList[currentType].length ? "disabled-btn" : ""} ${!currentPlayedTrack ? "fixed-bottom" : ""}`}>
                    <LinkButton title={"Continue"}
                                cb={currentType === contentTypes[0] ? goToArtists : setUserPreferred}/>
                </div>
            </div>
        </div>
    </div>
}


const mapDispatchToProps = {
    SocialSignIn,
    GetArtists,
    UpdateCurrentUser
};

export default connect(mapStateToProps, mapDispatchToProps)(PreferredGenres);
