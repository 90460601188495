import {socketNotificationConnection} from "../api/socketNotificationConnection";
import {NOTIFICATION_TYPES} from "../constants";
import {store} from "../../redux/store";
import {isNotificationsConnected} from "../api/isConnected";
import {toggleNotificationRow} from "../../redux/actions";
import {NOTIFICATION_ROW_MODES} from "../../constants/constTypes";
// console.log(store, 'emmiter');
export const GetNonOpenedNotificationsCount = () => {
    isNotificationsConnected() &&
    socketNotificationConnection.instance.emit(NOTIFICATION_TYPES.GET_NON_OPENED_NOTIFICATIONS_COUNT,
        (error, data) => {
            data && store.dispatch({
                type: NOTIFICATION_TYPES.GET_NON_OPENED_NOTIFICATIONS_COUNT,
                payload: data.count
            });
        })
};

export const SetAllNotificationsAsOpened = () => {
    isNotificationsConnected() &&
    socketNotificationConnection.instance.emit(NOTIFICATION_TYPES.SET_ALL_NOTIFICATIONS_OPENED,
        (error, data) => {
          //  console.log('set-notificationes-opened...', error, data.success)
            data && store.dispatch({
                type: NOTIFICATION_TYPES.SET_ALL_NOTIFICATIONS_OPENED
            })
        })
};

export const DeleteAllNotifications = () => {
    console.log('DeleteAllNotifications');
    isNotificationsConnected() &&
    socketNotificationConnection.instance.emit(NOTIFICATION_TYPES.REMOVE_ALL_NOTIFICATIONS,
        (error, data) => {
            // console.log('Delete-all-notifications...', error, data);

            let text = 'All notification has been successfully deleted';
            let errorText = 'Deleting notifications failed';

            data && store.dispatch({
                type: NOTIFICATION_TYPES.REMOVE_ALL_NOTIFICATIONS,
            });

            data && toggleNotificationRow({
                visible: true,
                text,
                disappear: 2000,
                mode: NOTIFICATION_ROW_MODES.SUCCESS
            })(store.dispatch);
            error && toggleNotificationRow({
                visible: true,
                text: errorText,
                disappear: 2000,
                mode: NOTIFICATION_ROW_MODES.SUCCESS
            })(store.dispatch)
        })
};

// console.log(store.getState().general?.staticTexts?.notification_delete_success, 'STORE');
export const DeleteNotification = (notificationId) => {
    const payload = {notificationId};
    let text = 'Notification has been successfully deleted';
    let errorText = 'Deleting notifications failed';

    isNotificationsConnected() &&
    socketNotificationConnection.instance.emit(NOTIFICATION_TYPES.REMOVE_NOTIFICATION, payload,
        (error, data) => {
            console.log('Delete-notification...', error, data);
            data && store.dispatch({
                type: NOTIFICATION_TYPES.REMOVE_NOTIFICATION,
                payload: notificationId
            });
            data && toggleNotificationRow({
                visible: true,
                text,
                disappear: 2000,
                mode: NOTIFICATION_ROW_MODES.SUCCESS
            })(store.dispatch);
            error && toggleNotificationRow({
                visible: true,
                text: errorText,
                disappear: 2000,
                mode: NOTIFICATION_ROW_MODES.ERROR
            })(store.dispatch)
        })
};
