import {PLAYER_CONSTS, TRACKS_CONSTS} from "../constants";
import {shuffle} from "../../utils/helperFunctions";

export const initialState = {
    currentPlayedTrack: null,
    playerTracksList: [],
    shuffledTracksList: [],
    type: null,
    currentTrackIndex: null,
    playState: false,
    shuffled: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PLAYER_CONSTS.SET_CURRENT_PLAYED_TRACK:
            return {
                ...state,
                currentPlayedTrack: action.payload.track,
                playerTracksList: action.payload.tracksList,
                shuffledTracksList: action.payload.shuffledList,
                type: action.payload.type,
                currentTrackIndex: action.payload.index,
            };
        case PLAYER_CONSTS.TOGGLE_PLAY_STATE:
            return {
                ...state,
                playState: action.payload,
            };
        case TRACKS_CONSTS.TOGGLE_TRACK_FAVORITE:
            return {
                ...state,
                currentPlayedTrack: {
                    ...state.currentPlayedTrack,
                    isFavorite: state.currentPlayedTrack?.id === action.payload.id ? action.payload.likeState : state.currentPlayedTrack?.isFavorite
                },
            };
        case PLAYER_CONSTS.TOGGLE_SHUFFLED:
            let currentIndex = state.currentTrackIndex;
            state.playerTracksList.find((item, index) => {
                if(state.currentPlayedTrack?.id === item.id ){
                    currentIndex = index
                }
            });

            return {
                ...state,
                currentTrackIndex: currentIndex,
                shuffled: action.payload,
                shuffledTracksList: shuffle(state.playerTracksList),
            };
        case PLAYER_CONSTS.GET_SHUFFLED_LIST:
            return {
                ...state,
                shuffledTracksList: shuffle(state.playerTracksList),
            };
        case TRACKS_CONSTS.CLEAR_CURRENT_TRACK:
            return {
                ...state,
                currentPlayedTrack: null,
            };
        default:
            return state;
    }
}
