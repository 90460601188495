import {_hostMedia} from "../redux/api";
import defaultUserLogo from '../assets/images/default_user_avatar.png';
import defaultPlaylistLogo from '../assets/images/default_playlist.jpg';
import defaultFileLogo from '../assets/images/default_empty.png';

export function generateImageMediaUrl(path, type) {
    if (path?.startsWith('http')) {
        return path;
    }
    let defaultLogo = "";
    switch (type) {
        case "playlist":
            defaultLogo = defaultPlaylistLogo;
            break
        case "user":
            defaultLogo = defaultUserLogo;
            break
        default:
            defaultLogo = defaultFileLogo;
    }
    return path ? `${_hostMedia}/images${path}` : defaultLogo
}

export function generateFileMediaUrl(path) {
    if (path?.startsWith('http')) {
        return path;
    }
    return path ? `${_hostMedia}/files${path}` : defaultFileLogo;
}