export const PODCAST_CONSTS = {
    GET_PODCASTS: "GET_PODCASTS",
    ADD_PODCAST: "ADD_PODCAST",
    ADD_TRACK: "ADD_TRACK",
    REMOVE_TRACK_FROM_PODCAST: "REMOVE_TRACK_FROM_PODCAST",
    GET_PODCAST_TRACKS: "GET_PODCAST_TRACKS",
    DELETE_PODCAST: "DELETE_PODCAST",
    UPDATE_PODCAST: "UPDATE_PODCAST",

    GET_PODCASTS_LIST: "GET_PODCASTS_LIST",
    GET_PODCASTS_LIST_TRACK: "GET_PODCASTS_LIST_TRACK",
};
