// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';

//Import assets

//import utils
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetArtists, CleaArtists} from "../../redux/actions/artistsActions";
import SquareSkeletonCard from "../skeletonCards/SquareSkeletonCard";
import {CardComponents} from "../cards/BaseCard";


function Artists(props) {
    const [isFetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(true);
    const params = useParams();

    useEffect(() => {
        (props.text || params.text) && props.GetArtists({
            text: props.text || params.text,
        }).then(() => {
            setLoading(false)
        })
        return function cleanup() {
            // props.CleaArtists()
        };
    }, [props.text]);

    const {artists, className} = props;

    const loadMoreItems = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetArtists({
                reset: false,
                offset: artists?.list?.length
            }).finally(() => {
                setFetching(false);
            });
        }
    };

    const setSkeletonCount = () => {
        if (window.innerWidth > 550) {
            return 5
        } else if (window.innerWidth > 400) {
            return 4
        } else {
            return 3
        }
    }

    return <section className={className}>
        {loading && <div className={'section-header'}>
            <span>Artists</span>
        </div>}
        {!loading ? (!!artists?.list?.length ? <div className="search-artists-wrapper">
                <div className={'section-header'}>
                    <span>Artists</span>
                </div>
                <InfiniteScroll
                    hasMore={artists?.hasMore}
                    loadMore={loadMoreItems}
                    className={'base-cards-list'}
                    pageStart={0}
                    useWindow={true}
                    initialLoad={false}>
                    {
                        artists?.list?.map(artist => {
                            return <CardComponents.ArtistCard artist={artist} key={artist.id}/>
                        })
                    }
                </InfiniteScroll>
            </div>
            : <div className="no-search-result">
                There are no artists with this name in the TouTop
            </div>) : <div className={'base-cards-list circle-cards'}>
            <SquareSkeletonCard count={setSkeletonCount()}/>
        </div>}

    </section>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'artists',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    GetArtists,
    CleaArtists
};

export default connect(mapStateToProps, mapDispatchToProps)(Artists);