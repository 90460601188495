// import packages
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, Link } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';

//import Assets
import './podcast-details.scss';
import { ReactComponent as ArrowIcon } from "../../../assets/images/icons/ic_arrow_down_dark.svg";

//import components
import TrackCard from "../../../components/cards/TrackCard";
import MetaTags from "../../../components/MetaTags";
import SkeletonTrackCard from "../../../components/skeletonCards/SkeletonTrackCard";
import { PageSkeleton } from "../../PageSkeleton";
import SongUploader from "../upload/songUploader";
import { InputGroup } from "../../../components/uIElements/inputGroup";
import { LinkButton } from "../../../components/uIElements/buttons";

//import utils
import { getPropsFromState } from "../../../redux/mapStateToProps";
import {
    UploadTrackSong,
    AddTrack,
    GetUserPodcastTracks,
    GetUserPodcasts
} from "../../../redux/actions";
import { generateImageMediaUrl } from "../../../utils/generateMediaUrl";


const initialData = {
    name: "",
    song: null
}

function PodcastDetails (props) {
    const params = useParams();
    const { podcastTracks, userPodcasts, selectedLanguage } = props;
    const [errors, setErrors] = useState({});
    const [progress, setProgress] = useState(0);

    const [trackData, setTrackData] = useState(initialData);
    const [podcast, setPodcast] = useState(null);
    const [isFetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
        props.GetUserPodcastTracks(params?.id).finally(() => {
            setLoading(false)
        });
    }, [params?.id]);

    useEffect(()=>{
        if (userPodcasts?.list?.length) {
            setPodcast(userPodcasts?.list.find(p => p.id === params.id))
        }
        else {
            props.GetUserPodcasts().then(podcasts => {
                setPodcast(podcasts?.find(p => p.id === params.id))
            });
        }
    }, [params?.id, userPodcasts])
    const loadMoreItems = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetTracks({
                reset: false,
                offset: podcastTracks?.list?.length,
            }).finally(() => {
                setFetching(false);
            });
        }
    };

    function getSongFile (file) {
        setTrackData({
            ...trackData,
            song: file
        })
        setErrors({
            ...errors,
            song: false
        })
    }

    const addTrack = async() => {
        const requiredFields = ['name', "song"];
        const reqData = {}
        let isValid = true;
        let _errors = {}
        requiredFields.forEach(key => {
            if (!trackData?.[key]) {
                isValid = false;
                _errors = {
                    ...errors,
                    [key]: true,
                }
            }
        });

        setErrors(_errors)
        if (isValid) {
            let formData = new FormData();
            formData.append('song', trackData.song);
            let uploadedSong = await UploadTrackSong(podcast.id, formData, onUploadProgress);
            reqData.song = uploadedSong?.id;
            reqData.translations = [{
                title: trackData?.name,
                languageId: selectedLanguage.id
            }]
            await props.AddTrack(podcast.id, reqData).then(() => {
                setTrackData(initialData)
            })
            setProgress(0);
        }
    }

    const onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.round((progressEvent?.loaded * 100) / progressEvent?.total)
        setProgress(percentCompleted !== 100 ? percentCompleted : 99)
    };

    const getInputValues = (e) => {
        const { value } = e.target;
        setTrackData({
            ...trackData,
            name: value
        })
    };

    return <PageSkeleton withPadding>
        <MetaTags title={podcast?.name}
                  image={generateImageMediaUrl(podcast?.coverImage?.path)}/>
        <div className="podcast-header">
            <Link className={'back'} to={'/podcasts'}>
                <ArrowIcon/>
            </Link>
            <div className={'info'}>
                <div className={'podcast-title'}>{podcast?.name}</div>
                <div className={'podcast-count'}>{podcast?.podcastsCount} podcasts</div>
            </div>
        </div>
        <div className={'create-track'}>
            <InputGroup
                inputType={'input'}
                type={"text"}
                name={'Name'}
                value={trackData.name}
                error={errors.name}
                placeholder={"Name"}
                disabled={!!progress}
                maxLength={100}
                onChange={getInputValues}
            />
            <div className={'action-buttons'}>
                <SongUploader onChange={getSongFile} songError={errors.song}
                              progress={progress}
                              songFile={trackData.song} disabled={!!progress}/>
                <LinkButton title={`Save`}
                            disabled={ progress}
                            cb={addTrack}
                />
            </div>
            {/* <div className={`progress-wrapper ${progress? 'show': ""}`  }>*/}
            {/*    <div className="progress">*/}
            {/*        <div className="progress-bar progress-bar-animated" style={{ "width": progress + "%" }}*/}
            {/*             role="progressbar"/>*/}
            {/*    </div>*/}
            {/*    <div className={'percent'}>*/}
            {/*        {progress} %*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
        {!loading ?
            (!!podcastTracks?.list?.length ? <div className="podcasts-track-list">
                    <InfiniteScroll
                        hasMore={podcastTracks?.hasMore}
                        loadMore={loadMoreItems}
                        className={'tracks-list'}
                        pageStart={0}
                        useWindow={true}
                        initialLoad={false}>
                        {
                            podcastTracks?.list?.map((item, index) => {
                                return <TrackCard key={item.id}
                                                  track={item}
                                                  playerTracksList={podcastTracks?.list}
                                                  indexInPlayerList={index}
                                                  index={index + 1}
                                                  modifyActions={true}
                                                  podcastId={params.id}
                                />
                            })
                        }
                    </InfiniteScroll>
                </div> :
                <div className="no-result">
                    <span>This have no podcasts</span>
                </div>) :
            <SkeletonTrackCard count={3}/>

        }
    </PageSkeleton>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isLoggedIn',
        'requestLoading',
        'podcastTracks',
        'userPodcasts',
        'selectedLanguage',
    ])
};

const mapDispatchToProps = {
    GetUserPodcastTracks,
    GetUserPodcasts,
    AddTrack,
};

export default connect(mapStateToProps, mapDispatchToProps)(PodcastDetails);
