// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link, useParams} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';

//import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetAdminPlaylists} from "../../redux/actions/albumsActions";
import SquareSkeletonCard from "../skeletonCards/SquareSkeletonCard";
import {CardComponents} from "../cards/BaseCard";
import {ALBUM_TYPES} from "../../constants/constants";


function Playlists(props) {
    const [isFetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [albumsList, setAlbumsList] = useState({});
    const {adminPlaylists, artistAlbums, className, artistId} = props;
    const params = useParams();

    useEffect(() => {
        (props.text || params.text) && props.GetAdminPlaylists({
            text: props.text || params.text
        }).then(() => {
            setLoading(false)
        });

        // let data = artistAlbums?.list?.length ? artistAlbums : adminPlaylists;
        // setAlbumsList(data);
        // artistId && props.GetAlbums({
        //     artist: artistId,
        // }).then(() => {
        //     setLoading(false)
        //
        // });
    }, [props.text]);

    const loadMoreItems = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetAdminPlaylists({
                artist: artistId,
                reset: false,
                offset: adminPlaylists?.list?.length
            }).finally(() => {
                setFetching(false);
            });
        }
    };

    const setSkeletonCount = () => {
        if (window.innerWidth > 550) {
            return 5
        } else if (window.innerWidth > 400) {
            return 4
        } else {
            return 3
        }
    }

    return <section className={className}>
        {loading && <div className={'section-header'}>
            <span>Albums</span>
        </div>}
        {!loading ?
            (!!adminPlaylists?.list?.length ? <div className="search-albums-wrapper">
                    <div className={'section-header'}>
                        <span>Albums</span>
                    </div>
                    <InfiniteScroll
                        hasMore={adminPlaylists?.hasMore}
                        loadMore={loadMoreItems}
                        className={'base-cards-list'}
                        pageStart={0}
                        useWindow={true}
                        initialLoad={false}>
                        {
                            adminPlaylists?.list?.map(album => {
                                return <CardComponents.AlbumCard album={album} key={album.id}/>
                            })
                        }
                    </InfiniteScroll>

                </div>
                : <div className="no-search-result">
                    There are no playlists with this name in the TouTop
                </div>) :
            <div className={'base-cards-list'}>
                <SquareSkeletonCard count={setSkeletonCount()}/>
            </div>
        }

    </section>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'adminPlaylists',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    GetAdminPlaylists
};

export default connect(mapStateToProps, mapDispatchToProps)(Playlists);