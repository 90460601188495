// import packages
import React, {Component} from "react";

//import styles
import "../assets/styles/containers/main.scss";


//import utils
import {androidUrl, iosUrl} from "../configs/appUrls";
import {getMobileOS} from "../utils/getMobileOS";
import {getBrowserType} from "../utils/getBrowserType";

export default class Main extends Component {
    getAppUrl() {
        let url = androidUrl;
        const mobileOs = getMobileOS();
        if (mobileOs === 'unknown') {
            const browserType = getBrowserType();
            if (browserType === 'Safari') {
                url = iosUrl;
            }
        } else if (mobileOs === 'iOS') {
            url = iosUrl;
        }
        return url;
    }

    render() {
        return <div className='main-section-wrapper'>
            <img className={"circle-bg"} src={require("../assets/images/circle_bg.png")} alt={""}/>
            <div className={"left-side"}>
                <picture className={"main-left-img"}>
                    <source media="(max-width:600px)" srcSet={require("../assets/images/main_sm.webp")}/>
                    <img src={require("../assets/images/main_lg.webp")} alt={""}
                         style={{width: "100%", height: "100%"}}/>
                </picture>
            </div>
            <div className={"right-side"}>
                <div className={"main-section-title"}>
                    Find and listen top songs in YouTop
                </div>
                <div className={"main-section-text"}>
                    Listen top music and crate your playlists.
                </div>
                <a href={this.getAppUrl()}
                   target={'_blank'}
                   rel="noopener noreferrer">
                    <button className={"download-btn"}>
                        Download
                    </button>
                </a>
            </div>
        </div>
    }
}
