import React, {useEffect, useRef, useState} from "react";

// import assets
import '../../assets/styles/components/uiElements/image.scss'

// import utils
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";
import {resizeImage} from "../../utils/resizeImage";
import {SkeletonItem} from "./SkelatonItem";

export function Image(props) {
    const [sizes, setSizes] = useState({
        width: 0,
        height: 0,
    })
    const [show, setShow] = useState(false)
    const ref = useRef();
    useEffect(() => {
        setSizes({
            width: ref.current.clientWidth * 2,
            height: ref.current.clientHeight * 2
        })

    }, [])

    const {url, loading, circle, type, blured} = props;
    return <div className="image-element-wrapper" ref={ref}>
        <div className={`image-inner-content ${circle ? "circle" : ""}`}
             style={{
                 backgroundImage: `url(${resizeImage(generateImageMediaUrl(url, type), blured ? 15 : 20 , blured ? 15 : 20)})`,
                 backgroundSize: "cover"
             }}>
            {loading ? <SkeletonItem/> : <>
                {!!sizes?.width && <img className={`${show ? "show" : ""}`}
                                      onLoad={() => setShow(true)}
                                      src={resizeImage(generateImageMediaUrl(url, type), sizes?.width, sizes?.height)}
                                      alt=""/>}
            </>}
        </div>
    </div>
}