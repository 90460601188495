// import packages
import React, {Component} from "react";
import {connect} from 'react-redux';
import {history} from "../configs/history";

//Import assets
import '../assets/styles/containers/contacts.scss'
import {ReactComponent as PhoneIcon} from '../assets/images/icons/ic_phone.svg';
import {ReactComponent as MailIcon} from '../assets/images/icons/ic_mail.svg';
import {ReactComponent as TickIcon} from '../assets/images/icons/ic_tick.svg';
import {ReactComponent as FacebookIcon} from "../assets/images/facebook.svg";
import {ReactComponent as TwitterIcon} from "../assets/images/twitter.svg";
import {ReactComponent as InstaIcon} from "../assets/images/insta.svg";
import {ReactComponent as SnapIcon} from "../assets/images/snapchat.svg";

//Import Components
import {InputGroup} from "../components/uIElements/inputGroup";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";


class Contacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: {
                firstName: '',
                lastName: '',
                email: '',
                message: '',
            },
            errors: {
                email: false,
                firstName: '',
                lastName: '',
                message: false,
            },
            tick: false,
        };
        this.requestLoading = false
        this.getInputValues = this.getInputValues.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.showTick = this.showTick.bind(this);
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        if (this.props.loggedInUser) {
            this.setState({
                contact: {
                    ...this.state.contact,
                    firstName: this.props.loggedInUser?.firstName,
                    lastName: this.props.loggedInUser?.lastName,
                    email: this.props.loggedInUser?.username
                }
            });
            history.replace();
        }
    }

    getInputValues(e) {
        const {name, value} = e.target;
        this.setState({
            contact: {
                ...this.state.contact,
                [name]: value
            }
        })
    }

    validate() {
        const mailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const {contact} = this.state;
        const errors = {};
        let result = true;
        Object.keys(contact).forEach(key => {
            if (!contact[key] || (key === 'email' && !mailRegExp.test(contact?.email))
            ) {
                errors[key] = true;
                result = false;
            }

        })
        this.setState({
            errors
        });
        return result;
    }

    sendMessage() {
        const {contact} = this.state;
        if (this.validate()) {
            // this.props.SendEmail(contact).then(() => {
            this.setState({
                contact: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    message: '',
                },
                tick: true,
            });
            this.showTick()
            // })
            this.requestLoading = false
        }
    }

    showTick() {
        setTimeout(
            () => this.setState({
                tick: false
            }),
            2000
        )
    }

    render() {
        const {contacts} = this.props;
        const {contact, errors, tick} = this.state;


        return <div className="contacts-wrapper">
            <div className="contact-banner">
                <img className={"circle-bg"} src={require("../assets/images/circle_bg.png")} alt={""}/>
                <div>
                    <h1 className="banner-title">Contact Us</h1>
                    <div className="banner-description">Follow us online to find out when we launch.</div>
                    <div className="social-networks-icons">
                        <a href={"https://www.facebook.com/youtopmusic"} target={'_blank'}
                           rel="noopener noreferrer"><FacebookIcon title=''/></a>
                        <a href={"/"} target={'_blank'} rel="noopener noreferrer"><TwitterIcon title=''/></a>
                        <a href={"/"} target={'_blank'} rel="noopener noreferrer"><InstaIcon title=''/></a>
                        <a href={"/"} target={'_blank'} rel="noopener noreferrer"><SnapIcon title=''/></a>
                    </div>
                </div>
            </div>
            <div className="form-title">How can we help you?</div>
            <div className="contact-content">
                <div className="send-message-wrapper">
                    <div className="sub-title">Send us your message</div>
                    <div className="form-content">
                        <div className="name-wrapper">
                            <InputGroup
                                inputType={'input'}
                                type={"text"}
                                name={'firstName'}
                                placeholder={"First name"}
                                value={contact.firstName}
                                error={errors.firstName}
                                maxLength={100}
                                onKeyDown={(e) => {
                                    e.key === 'Enter' && this.sendMessage()
                                }}
                                onChange={this.getInputValues}
                            />
                            <InputGroup
                                inputType={'input'}
                                type={"text"}
                                name={'lastName'}
                                placeholder={"Last name"}
                                value={contact.lastName}
                                error={errors.lastName}
                                maxLength={100}
                                onKeyDown={(e) => {
                                    e.key === 'Enter' && this.sendMessage()
                                }}
                                onChange={this.getInputValues}
                            />
                        </div>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            name={'email'}
                            value={contact.email}
                            error={errors.email}
                            placeholder={"Email address"}
                            maxLength={50}
                            onKeyDown={(e) => {
                                e.key === 'Enter' && this.sendMessage()
                            }}
                            onChange={this.getInputValues}
                        />
                        <InputGroup
                            inputType={'textarea'}
                            type={"text"}
                            name={'message'}
                            value={contact.message}
                            error={errors.message}
                            placeholder={"Type message"}
                            maxLength={20}
                            onKeyDown={(e) => {
                                e.key === 'Enter' && this.sendMessage()
                            }}
                            onChange={this.getInputValues}
                        />
                        <button className='link-button'
                                onClick={() => {
                                    if (!this.requestLoading && this.sendMessage) {
                                        this.sendMessage();
                                    }
                                }}>
                            Send

                                {/*<div className={`${tick ? "load-complete load-success": ""} circle-loader`}>*/}
                                {/*    <div className={`${tick ? "checkmark": ""} draw`}></div>*/}
                                {/*</div>*/}


                        </button>
                    </div>
                </div>
                <div className="info-content">
                    <div className="sub-title">Contact details</div>
                    <p className="description">
                        Dear customers, for all questions you can contact the center
                        by phone during working hours Dear customers, for all
                        questions you can contact the center by phone during
                        working hours 10:00 to 22:00
                    </p>
                    <div className="sub-title">Contact</div>
                    <div className="contact-items">
                        <div className="contact-item">
                            <PhoneIcon title={''}/>
                            {/*<a href={`tel:${contacts?.phoneNumber}`}><span>{contacts?.phoneNumber}</span></ a>*/}
                            <a href="tel:+37498970000"><span>+374 (98) 97-00 00</span></ a>
                        </div>
                        <div className="contact-item">
                            <MailIcon title={''}/>
                            {/*<a href={`mailto:${contacts?.email}`}><span><span>{contacts?.email}</span></span></ a>*/}
                            <a href="mailto:youtopclub@gmail.com"><span>youtopclub@gmail.com</span></ a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    }
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'loggedInUser',
        'contacts',
        'requestLoading',
    ])
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
