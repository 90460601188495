// Import packages
import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation, useParams} from 'react-router-dom';
import {connect} from 'react-redux';

// Import assets
import '../../assets/styles/components/uiElements/searchSuggestions.scss'
import {ReactComponent as SearchIcon} from "../../assets/images/icons/ic_search.svg";
import {ReactComponent as ArrowIcon} from "../../assets/images/icons/ic_arrow_down_dark.svg";

// Import utils
import {history} from "../../configs/history";
import {
    GetAlbumsSuggestions,
    GetArtistSuggestions,
    GetPlaylistsSuggestions,
    GetTracksSuggestions,
    ClearSuggestions, setCurrentPlayedTrack, GetTracks, GetPodcastsSuggestions
} from "../../redux/actions";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {Image} from "./Image";

function SearchSuggestions(props) {
    const location = useLocation();
    const params = useParams();
    const {inMobileMenu, toggleMobileMenu, className, suggestions} = props;
    const [text, setText] = useState('');
    const inputEl = useRef(null);
    const intervalRef = useRef(null);

    useEffect(
        () => {
            if (text) {

                intervalRef.current = setTimeout(() => {
                    onSearch(text)
                }, 500);
            } else {
                clearTimeout(intervalRef.current);
            }

            return () => clearTimeout(intervalRef.current);
        },
        [text] // Only call effect if debounced search term changes
    );

    useEffect(() => {
        if (!location?.pathname?.startsWith('/search')) {
            setText('');
            props.ClearSuggestions();
        } else {
            setText(location?.state?.text);
        }
    }, [location]);

    function onEnter(e) {
        if (e.key === 'Enter') {
            text && search()
        }
    }

    function onSearch(value) {
        setText(value);
        if (value && value.length > 0) {
            props.GetTracksSuggestions(value);

            props.GetArtistSuggestions(value);

            props.GetAlbumsSuggestions(value);

            props.GetPlaylistsSuggestions(value);

            props.GetPodcastsSuggestions(value);

        } else {
            props.ClearSuggestions();
        }
        !value && location?.pathname?.startsWith('/search') && search('');
    }

    function search(value) {
        const currentTab = location.pathname.startsWith('/search') && location.pathname.split('/')?.[2];
        //todo mobile menu in seggesions
        // inMobileMenu && toggleMobileMenu();
        const searchText = typeof value === 'string' ? value : text;
        searchText?.length > 0 && inputEl.current.blur();
        searchText?.length > 0 && history.push({
            pathname: `/search/${currentTab || 'all'}/${searchText}`,
            state: {
                text: searchText,
                suggestions,
            }
        })
    }

    function selectSuggestion(text) {
        props.ClearSuggestions();
        setText(text);
        inMobileMenu && toggleMobileMenu();
        search(text);
    }

    function setTrack(track, index) {
        props.GetTracks({getResponse: true, album: track.albums?.[0]?.id}).then((res) => {
            setCurrentPlayedTrack(track, res.data, location?.pathname, index)
        });

    }

    function navigateToSearch(route) {
        history.push({
            pathname: route,
        });
    }

    return <div className={`search_box ${className}`}>
        <div className="input-group-append">
            <button type="button" className="search_btn" onClick={search}>
                <SearchIcon/>
            </button>
        </div>
        <input
            value={text}
               onChange={e => setText(e.target.value)}
               onKeyDown={onEnter}
               ref={inputEl}
               type="text"
               className="search"
               placeholder={'Search for top songs or artist'}
        />
        {!!(suggestions?.tracks?.length || suggestions?.artists?.length
            || suggestions?.albums?.length || suggestions?.sections?.length) &&
        <ul className={`suggestions-wrapper ${(suggestions?.tracks?.length || suggestions?.artists?.length
            || suggestions?.albums?.length || suggestions?.sections?.length) ? 'active' : ''}`}>
            <div className="suggestions-list">
                {!!suggestions?.podcasts?.length && <>
                    <div className="section-title"
                         onClick={() => navigateToSearch(`/search/songs/${text || params?.text}`)}>PODCASTS
                    </div>
                    {suggestions?.podcasts?.map((item, index) => {
                        return <Link to={{
                            pathname: `/podcast/${item?.id}`,
                            state: {track: item,}
                        }}
                                     key={item?.id}
                                     onClick={setTrack.bind(null, item, index)}>
                            <li className={'item'}
                                onClick={selectSuggestion.bind(null, item.title)}>
                                <div className="img-wrapper">
                                    <Image url={item?.coverImage?.path}/>
                                </div>
                                <div className="suggestion-content">
                                    <span className="suggestion-title">{item?.name}</span>
                                    <span className="suggestion-description">{item?.description}</span>
                                </div>
                            </li>
                        </Link>
                    })}
                </>}

                {!!suggestions?.tracks?.length && <>
                    <div className="section-title"
                         onClick={() => navigateToSearch(`/search/songs/${text || params?.text}`)}>SONGS
                    </div>
                    {suggestions?.tracks?.map((item, index) => {
                        return <Link to={{
                            pathname: `/album/${item.albums[0]?.slug}`,
                            state: {track: item,}
                        }}
                                     key={item?.id}
                                     onClick={setTrack.bind(null, item, index)}>
                            <li className={'item'}
                                onClick={selectSuggestion.bind(null, item.title)}>
                                <div className="img-wrapper">
                                    <Image url={item?.coverImage?.path}/>
                                </div>
                                <div className="suggestion-content">
                                    <span className="suggestion-title">{item?.title} {item?.subTitle}</span>
                                    <span className="suggestion-description">{item?.description}</span>
                                </div>
                            </li>
                        </Link>
                    })}
                </>}

                {!!suggestions?.artists?.length && <>
                    <div className="section-title" onClick={() => navigateToSearch(`/search/artists/${text}`)}>ARTISTS
                    </div>
                    {suggestions?.artists?.map(item => {
                        return <Link to={{
                            pathname: `/artist/${item?.slug}/songs`,
                            state: {artist: item}
                        }}
                                     key={item.id}>
                            <li key={item.id}
                                className={'item'}
                                onClick={selectSuggestion.bind(null, item.fullName)}>
                                <div className="img-wrapper">
                                    <Image url={item?.profileImage?.path} type={"user"}/>
                                </div>
                                <div className="suggestion-content">
                                    <span className="suggestion-title">{item?.fullName}</span>
                                    <span className="suggestion-description">{item?.tracksCount} tracks</span>
                                </div>
                            </li>
                        </Link>
                    })}
                </>}

                {!!suggestions?.albums?.length && <>
                    <div className="section-title" onClick={() => navigateToSearch(`/search/albums/${text}`)}>ALBUMS
                    </div>
                    {suggestions?.albums?.map(item => {
                        return <Link to={{
                            pathname: `/album/${item.slug}`,
                            state: {album: item}
                        }}
                                     key={item.id}>
                            <li
                                className={'item'}
                                onClick={selectSuggestion.bind(null, item.title)}>
                                <div className="img-wrapper">
                                    <Image url={item?.coverImage?.path}/>
                                </div>
                                <div className="suggestion-content">
                                    <span className="suggestion-title">{item?.title}</span>
                                    <span
                                        className="suggestion-description">{!!item?.artists?.length && item?.artists?.map((artist, index) => {
                                        return <span
                                            key={artist.id}>{artist?.fullName}{index + 1 < item.artists?.length &&
                                        <span>,&nbsp;</span>}</span>
                                    })}</span>

                                </div>
                            </li>
                        </Link>
                    })}
                </>}

                {!!suggestions?.sections?.length && <>
                    <div className="section-title"
                         onClick={() => navigateToSearch(`/search/playLists/${text}`)}>PLAYLISTS
                    </div>

                    {suggestions?.sections?.map(item => {
                        return <Link to={{
                            pathname: `/album/${item.slug}`,
                            state: {section: item}
                        }}
                                     key={item?.id}>
                            <li className={'item'}
                                onClick={selectSuggestion.bind(null, item.title)}>
                                <div className="img-wrapper">
                                    <Image url={item?.coverImage?.path} type={"playlist"}/>
                                </div>
                                <div className="suggestion-content">
                                    <span className="suggestion-title">{item?.title}</span>
                                    <span className="suggestion-description">{item?.tracksCount} tracks</span>
                                </div>
                            </li>
                        </Link>
                    })}
                </>}

            </div>

            {text?.length > 0 && <div className="bottom-side" onClick={search}>
                See all result for «{text}»
                <ArrowIcon title={''} className={'arrow-icon'}/>
            </div>}
        </ul>}
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'suggestions',
    ])
};

const mapDispatchToProps = {
    GetTracksSuggestions,
    GetAlbumsSuggestions,
    GetArtistSuggestions,
    GetPlaylistsSuggestions,
    GetPodcastsSuggestions,
    ClearSuggestions,
    setCurrentPlayedTrack,
    GetTracks
};


export default connect(mapStateToProps, mapDispatchToProps)(SearchSuggestions);

