// import packages
import React from "react";

// import assets
import '../../assets/styles/components/cards/track-card.scss'
import {SkeletonGroup, SkeletonItem} from "../uIElements/SkelatonItem";

function SkeletonTrackCard(props) {
    const {count} = props;
    return <SkeletonGroup count={count}>
        <div className="track-item">
            <div className="img-wrapper">
                <div style={{width: "100%", height: "100%"}}><SkeletonItem/></div>
            </div>
            <div className="track-item-content">
                <div className="track-title-wrapper">
                    <SkeletonItem/>
                </div>
                <div style={{display: "flex", height: "26px"}}>
                    <SkeletonItem half/>
                </div>

            </div>
        </div>
    </SkeletonGroup>
}

export default SkeletonTrackCard;