// Import packages
import React, {Component} from "react";

// Import assets
import '../assets/styles/containers/page-wrapper.scss'

// Import components
import AppBannerCard from "../components/cards/AppBannerCard";


class PageWrapper extends Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        const {children} = this.props;
        return <div className="page-wrapper">
            <div className="left-side">
                {children}
            </div>

            <div className="right-side">
                <AppBannerCard/>
            </div>
        </div>
    }
}

export default PageWrapper;