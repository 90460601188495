// import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";

// Import styles
import "../assets/styles/containers/genres.scss"

// Import Components
import GenreCard from "../components/cards/GenreCard";
import {PageSkeleton} from "./PageSkeleton";
import SquareSkeletonCard from "../components/skeletonCards/SquareSkeletonCard";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";

function Genres(props) {
    const {genres, requestLoading} = props

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    const setSkeletonCount = () => {
        if (window.innerWidth > 800) {
            return 16
        } else {
            return 12
        }
    }
    return <PageSkeleton>
        <div className='section-title'>
            <span>Music Box</span>
        </div>
        <div className="genres-list">
            {!requestLoading ? (!!genres?.length && genres?.map(item => {
                return <GenreCard data={item} key={item?.id}/>
            })) : <div className={'base-cards-list'}>
                <SquareSkeletonCard count={setSkeletonCount()}
                                    withoutTitle/>
            </div>}
        </div>
    </PageSkeleton>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'genres',
    'requestLoading'
]);

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Genres);