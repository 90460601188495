import {_urlSections, request,} from "../api";
import {SECTIONS_CONSTS, UTIL_CONSTS} from "../constants";

export const GetSections = ({
                                offset = 0,
                                sectionsLimit = 20,
                                limit = 6,
                                text = '',
                                reset = true,
                                getResponse = false,
                            } = {}) => {

    const requestData = {
        url: `${_urlSections}?offset=${offset}&sectionsLimit=${sectionsLimit}&&limit=${limit}`,
        method: "GET",
        languageFlag: true,
    };
    text && (requestData.url += `&text=${text}`);

    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then((res) => {
                const data = res && res.data;

                if (!getResponse) {
                data && dispatch({
                    type: SECTIONS_CONSTS.GET_SECTIONS,
                    payload: {
                        data: data,
                        reset,
                        text,
                        hasMore: data?.length === sectionsLimit
                    }
                });
                } else {
                    return {
                        data,
                        hasMore: data?.length === limit,
                    }
                }
                dispatch({type: UTIL_CONSTS.END_LOADING});
                return data
            })
    };
};


export const GetSectionTracks = ({
                                     offset = 0,
                                     limit = 20,
                                     reset = true,
                                     getResponse = false,
                                     id
                                 } = {}) => {
    const requestData = {
        url: `${_urlSections}/${id}/tracks?offset=${offset}&limit=${limit}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };
    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                if(!getResponse){
                    data && dispatch({
                        type: SECTIONS_CONSTS.GET_SECTION_TRACKS,
                        payload: {
                            data: data,
                            reset,
                            hasMore: data?.length === limit
                        }
                    });
                }else {
                    const data = res && res.data;
                    return {
                        data,
                        hasMore: data?.length === limit,
                    }
                }
                dispatch({type: UTIL_CONSTS.END_LOADING});
            })
            .catch(err => {
            })
    };
};

export const CleaSections = () => {
    return async dispatch => {
        await dispatch({
            type: SECTIONS_CONSTS.CLEAR_SECTIONS
        })
    };
};

export const GetSectionById = (id) => {
    const requestData = {
        url: `${_urlSections}/${id}`,
        method: "GET",
        languageFlag: true,
        // token: true
    };
    return () => {
        return request(requestData)
    };
};
