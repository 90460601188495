import {_urlAlbums, request,} from "../api";
import {ALBUMS_CONSTS} from "../constants";
import {ALBUM_TYPES} from "../../constants/constants";

export const GetAlbums = ({
                              offset = 0,
                              limit = 20,
                              text = '',
                              artist = null,
                              newReleased = false,
                              reset = true,
                              getResponse = false,
                              genreIds = null,
                          } = {}) => {

    const requestData = {
        url: `${_urlAlbums}?offset=${offset}&limit=${limit}&types=${ALBUM_TYPES.MAIN.key},${ALBUM_TYPES.SINGLE.key}`,
        method: "GET",
        languageFlag: true,
    };
    text && (requestData.url += `&text=${text}`);
    artist && (requestData.url += `&artist=${artist}`);
    genreIds && (requestData.url += `&genreIds=${genreIds}`);
    newReleased && (requestData.url += `&newReleased=${newReleased}`);

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                // console.log(data, 'DATA');
                if (!getResponse) {
                    data && dispatch({
                        type: ALBUMS_CONSTS.GET_ALBUMS,
                        payload: {
                            data: data,
                            reset,
                            text,
                            artist,
                            hasMore: data?.length === limit
                        }
                    })
                } else {
                    return {
                        data,
                        hasMore: data?.length === limit,
                        reset,
                    }
                }

            })
            .catch(err => {
            })
    };
};
export const GetAdminPlaylists = ({
                              offset = 0,
                              limit = 20,
                              text = '',
                              artist = null,
                              newReleased = false,
                              reset = true,
                              getResponse = false,
                              genreIds = null,
                          } = {}) => {

    const requestData = {
        url: `${_urlAlbums}?offset=${offset}&limit=${limit}&types=${ALBUM_TYPES.PLAYLIST.key}`,
        method: "GET",
        languageFlag: true,
    };
    text && (requestData.url += `&text=${text}`);
    artist && (requestData.url += `&artist=${artist}`);
    genreIds && (requestData.url += `&genreIds=${genreIds}`);
    newReleased && (requestData.url += `&newReleased=${newReleased}`);

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                if (!getResponse) {
                    data && dispatch({
                        type: ALBUMS_CONSTS.GET_ADMIN_PLAYLISTS,
                        payload: {
                            data: data,
                            reset,
                            text,
                            artist,
                            hasMore: data?.length === limit
                        }
                    })
                } else {
                    return {
                        data,
                        hasMore: data?.length === limit,
                    }
                }
            })
            .catch(err => {
            })
    };
};

export const CleaAlbums = () => {
    return async dispatch => {
        await dispatch({
            type: ALBUMS_CONSTS.CLEAR_ALBUMS
        })
    };
};

export const GetAlbumById = (id) => {
    const requestData = {
        url: `${_urlAlbums}/${id}`,
        method: "GET",
        languageFlag: true,
        token: true
    };
    return () => {
        return request(requestData)
    };
};

export const GetFavoriteAlbums = ({
                                      offset = 0,
                                      limit = 10,
                                      reset = true,
                                      types = '',
                                      getResponse = false,
                                  } = {}) => {
    const requestData = {
        url: `${_urlAlbums}/favorites?offset=${offset}&limit=${limit}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };

    types && (requestData.url += `&types=${types}`);

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                if (!getResponse) {
                    data && dispatch({
                        type: ALBUMS_CONSTS.GET_FAVORITE_ALBUMS,
                        payload: {
                            data,
                            reset,
                            hasMore: data?.length === limit
                        }
                    })
                } else {
                    const data = res && res.data;
                    return {
                        data,
                        hasMore: data?.length === limit,
                    }
                }
            })
    };
};

export const AddAlbumToFavorites = (id) => {
    const requestData = {
        url: `${_urlAlbums}/${id}/add-to-favorites`,
        method: "POST",
        token: true,
    };

    return (dispatch) => {
        return request(requestData).then(async () => {
            const likeState = true;
            await dispatch({
                type: ALBUMS_CONSTS.TOGGLE_ALBUM_FAVORITE,
                payload: {id, likeState}
            });
        })
    };
};

export const RemoveAlbumFromFavorites = (id) => {
    const requestData = {
        url: `${_urlAlbums}/${id}/remove-from-favorites`,
        method: "DELETE",
        token: true,
    };
    return (dispatch,) => {
        return request(requestData).then(async () => {
            const likeState = false;
            await dispatch({
                type: ALBUMS_CONSTS.TOGGLE_ALBUM_FAVORITE,
                payload: {id, likeState}
            });
        })
    };
};
