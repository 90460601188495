// import packages
import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

// import assets
import './podcast-card.scss'
import {ReactComponent as MoreIcon} from "../../../assets/images/icons/ic_more.svg";

//import components
import AddEditPodcastCard from "../addEditPodcast/AddEditPodcastCard";
import {Image} from "../../../components/uIElements/Image";

//import utils
import {DeletePodcast} from "../../../redux/actions";
import {getPropsFromState} from "../../../redux/mapStateToProps";

function PodcastCard(props) {
    const {podcast} = props;
    const [cardActionsOpen, setCardActionsOpen] = useState(false);
    const [podcastCardOpen, setPodcastCardOpen] = useState(false);

    const ref = useRef();

    useEffect(() => {
        if (cardActionsOpen) {
            window.addEventListener('click', onClicked)
        } else {
            window.removeEventListener('click', onClicked)
        }
        return () => {
            window.removeEventListener('click', onClicked)
        }
    }, [cardActionsOpen]);

    function onClicked(e) {
        if (!e?.path?.includes(ref?.current)) {
            setCardActionsOpen(false);
        }
    }

    function deletePodcast(e, id) {
        e && e.preventDefault();
        e && e.stopPropagation();
        props.DeletePodcast(id)
    }

    const togglePodcastCard = (e) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        setPodcastCardOpen(!podcastCardOpen);
        setCardActionsOpen(false)
    }

    function toggleCardActions(e) {
        e && e.preventDefault();
        e && e.stopPropagation();
        setCardActionsOpen(!cardActionsOpen);
        setPodcastCardOpen(false)
    }

    function checkCanRedirectPlayListPage(e) {
        !podcast?.tracksCount && e.preventDefault();
    }

    return <div className="user-podcast-item">
        <div className="podcast-img-wrapper">

            {podcastCardOpen && <AddEditPodcastCard togglePodcastCard={togglePodcastCard}
                                                    podcast={podcast}/>}
            <Link  to={{
                pathname:`/podcasts/${podcast.id}`,
                state: { podcast }
            }} className="img-content user-podcast-img-content">
                <Image url={podcast?.coverImage?.path} type={"podcast"}/>
            </Link>
            }
        </div>
        <div className="podcast-item-content">
            <div onClick={checkCanRedirectPlayListPage}>
                <div className="podcast-item-title">{podcast?.name}</div>
                <div className="items-count">{podcast?.podcastsCount} podcasts</div>
            </div>
            {!props.checkPodcast && <MoreIcon title={''} className={'more-icon'} onClick={toggleCardActions}/>}
            {cardActionsOpen &&
                <div className="card-actions" ref={ref}>
                    <div className="action" onClick={(e) => togglePodcastCard(e)}>Edit</div>
                    <div className="action" onClick={(e) => deletePodcast(e, podcast.id)}>Delete</div>
                </div>
            }
        </div>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'isLoggedIn',
    'userPodcasts'
]);

const mapDispatchToProps = {
    DeletePodcast
};

export default connect(mapStateToProps, mapDispatchToProps)(PodcastCard);
