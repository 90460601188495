// Import packages
import React, {PureComponent} from "react";

// Import components

// Import styles
import "./image-uploader.scss";
import {ReactComponent as MicrophoneIcon} from "../../../assets/images/icons/ic_microphone.svg";

//import utils
import {hasImageExtension} from "../../../utils/hasExtension";
import {IMAGE_ACCEPT_TYPES, ACCEPT_IMAGE_SIZE} from "../../../constants/acceptedConsts";


export default class ImageUploader extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            image: this.props.image,
            imageError: false,
            errorMessage: "",
            imageExist: true,
        };
        this.imageSelector = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.image && this.props.image) {
            this.setState({
                image: this.props.image
            })
        }
    }

    readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Read the image via FileReader API and save image result in state.
            reader.onload = function (e) {
                let dataURL = e.target.result;
                resolve(dataURL);
            };
            reader.onerror = function (e) {
                //console.log(e);
                reject(e);
            };
            reader.readAsDataURL(file);
        });
    }

    handleImageChange(e) {
        let file = e && e.target && e.target.files && e.target.files[0];

        if (file) {
            if (!hasImageExtension(file.name)) {
                this.setState({
                    imageError: true,
                    errorMessage: `Accepted: ${IMAGE_ACCEPT_TYPES.join('| ')}`
                });
                //this.props.onChange('');
                return;
            }
            if (file.size > ACCEPT_IMAGE_SIZE) {
                this.setState({
                    imageError: true,
                    errorMessage: `File size is too big ${Math.floor(ACCEPT_IMAGE_SIZE / 1048576)}mb`
                });
                //  this.props.onChange('');
                return;
            }
            //data.append('file', file, file.name);
            this.readFile(file)
                .then(dataURL => {
                    // console.log(res);
                    this.setState({
                        image: dataURL,
                        imageError: false,
                        errorMessage: "",
                        imageExist: true,
                    }, () => this.props.onChange(file))
                });
        }
    }

    render() {
        let {image} = this.state;

        return <div className={`podcast-image-uploader`}>
            <div className={`img-selector`}
                 onClick={() => this.imageSelector.current.click()}>
                {
                    image ? <img src={image} alt=" "/> : <div className="uploader-wrapper">
                        <MicrophoneIcon title={''}/>
                        <span>Choose photo</span>
                    </div>
                }
            </div>
            <input type="file" accept="image/*" ref={this.imageSelector}
                   onChange={(e) => this.handleImageChange(e)}/>
        </div>
    }
}
