// import packages
import React from "react";
import {connect} from 'react-redux';

// import assets
import '../../assets/styles/components/cards/app-banner-card.scss'

//import utils
import {androidUrl, iosUrl} from "../../configs/appUrls";
import {getMobileOS} from "../../utils/getMobileOS";
import {getBrowserType} from "../../utils/getBrowserType";
import {Link} from "react-router-dom";
import {getPropsFromState} from "../../redux/mapStateToProps";

function AppBannerCard(props) {

    const getAppUrl = () => {
        let url = androidUrl;
        const mobileOs = getMobileOS();
        if (mobileOs === 'unknown') {
            const browserType = getBrowserType();
            if (browserType === 'Safari') {
                url = iosUrl;
            }
        } else if (mobileOs === 'iOS') {
            url = iosUrl;
        }
        return url;
    };

    return <div className="app-banner-card">
        <div className="app-banner-content">
            <div>
                <div>
                    <div className="app-banner-title">
                        Discover top songs with YouTop
                    </div>
                    <div className="app-banner-description">Listen top music and crate your playlists</div>
                </div>
                <button className={"download-btn"}>
                    <a href={getAppUrl()}
                       target={'_blank'}
                       rel="noopener noreferrer">Download</a>
                </button>
            </div>
            {props.isLoggedIn ? <div className={'bottom-text'}>
                    Get YouTop for &nbsp;
                    <a href={iosUrl} target={'_blank'} rel="noopener noreferrer">iOS</a>
                    &nbsp;&&nbsp;
                    <a href={androidUrl} target={'_blank'} rel="noopener noreferrer">Android</a>
            </div> :
            <div className={'bottom-text'}>For more features
                <Link to={'/sign-in'}><span>CREATE ACCOUNT</span></Link>
            </div>}
            <img className={"circle-bg"} src={require("../../assets/images/circle_bg.png")} alt={""}/>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isLoggedIn',
    ])
};

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(AppBannerCard);