// import packages
import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";

//Import assets
import './add-poscast-card.scss'

// Import components
import ImageUploader from "../upload/imageUploader";
import {LinkButton} from "../../../components/uIElements/buttons";
import {InputGroup} from "../../../components/uIElements/inputGroup";

// Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {AddPodcast, UpdatePodcast, UploadPodcastPicture} from "../../../redux/actions";
import {generateFileMediaUrl} from "../../../utils/generateMediaUrl";

const initialData = {
    name: '',
    description: '',
}

function AddEditPodcastCard(props) {
    const isEditing = !!props.podcast;
    const editingData = props?.podcast;
    const [podcastData, setPodcastData] = useState(initialData);
    const [errors, setErrors] = useState({});
    const [podcastListFile, setPodcastListFile] = useState(null);
    const ref = useRef();
    useEffect(() => {
        if (isEditing) {
            setPodcastData({
                name: editingData?.name,
                description: editingData?.description,
            });
        }
        window.addEventListener('click', onClicked)
        return () => {
            window.removeEventListener('click', onClicked)
        }
    }, []);

    function onClicked(event) {
        const  path = event.composedPath ? event.composedPath() : event.path
        if (!path?.includes(ref?.current)) {
            props.togglePodcastCard();
        }
    }

    const AddEditPodcastList = async () => {
        const requiredFields = ['name'];
        const reqData = {}
        let isValid = true;

        requiredFields.forEach(key => {
            if (!podcastData?.name) {
                isValid = false;
                setErrors({
                    ...errors,
                    [key]: true,
                })
            }
        });
        if (isValid) {
            reqData.name = podcastData?.name
            podcastData?.description && (reqData.description = podcastData?.description)
            if (podcastListFile) {
                let formData = new FormData();
                formData.append('coverImage', podcastListFile);
                let uploadedMedia = await UploadPodcastPicture(formData);
                if (uploadedMedia) {
                    reqData.coverImage = uploadedMedia?.id;
                }
            }
            const promise= isEditing ? props.UpdatePodcast(editingData.id, reqData) : props.AddPodcast(reqData);
            promise.then(() => {
                props.togglePodcastCard()
                setPodcastData(initialData)
            })
        }
    }

    const getInputValues = (e) => {
        const {name, value} = e.target;
        setPodcastData({
            ...podcastData,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false,
        })
    };

    const getSelectedImageFile = (file) => {
        setPodcastListFile(file);
    }

    return <div className="add-podcast-dropdown-wrapper" ref={ref}>
        <div className="add-podcast-content">
            <div className="add-podcast-update">
                <ImageUploader noImageError={''}
                               image={isEditing ? generateFileMediaUrl(editingData?.coverImage?.path) : ''}
                               onChange={getSelectedImageFile}/>
            </div>
            <div className="podcast-form">
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    name={'name'}
                    value={podcastData.name}
                    error={errors.name}
                    placeholder={"Name"}
                    maxLength={100}
                    onChange={getInputValues}
                />

                <div className="description-wrapper">
                    <InputGroup
                        inputType={'textarea'}
                        type={"text"}
                        name={'description'}
                        value={podcastData.description}
                        error={errors.description}
                        placeholder={"Description"}
                        maxLength={165}
                        onChange={getInputValues}
                    />
                    <div className="description-length">{podcastData.description.length}/165</div>
                </div>
            </div>
        </div>
        <div className="btns-wrapper">
            <div onClick={props.togglePodcastCard}>Cancel</div>
            <LinkButton title="Save"
                        cb={AddEditPodcastList}
            />
        </div>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, []);

const mapDispatchToProps = {
    AddPodcast,
    UpdatePodcast
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditPodcastCard);


