import {SECTIONS_CONSTS, TRACKS_CONSTS} from "../constants";

export const initialState = {
    sections: {
        list: [],
        text: '',
        hasMore: false,
    },
    sectionTracks: {
        list: [],
        hasMore: false,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SECTIONS_CONSTS.GET_SECTIONS:
            const newPlayList = {
                list: action.payload.reset ? action.payload.data : [...state.sections.list, ...action.payload.data],
                text: action.payload.text,
                hasMore: action.payload.hasMore
            };
            return {
                ...state,
                sections: newPlayList
            };

        case SECTIONS_CONSTS.GET_SECTION_TRACKS:
            const newSection = {
                list: action.payload.reset ? action.payload.data : [...state.sectionTracks.list, ...action.payload.data],
                hasMore: action.payload.hasMore
            };
            return {
                ...state,
                sectionTracks: newSection,
            };

        case TRACKS_CONSTS.TOGGLE_TRACK_FAVORITE:
            return {
                ...state,
                sectionTracks: {
                    ...state.sectionTracks,
                    list: state.sectionTracks.list.map(item => item.id === action.payload.id ?
                        {
                            ...item,
                            isFavorite: action.payload.likeState
                        } : item
                    )
                },
            };

        case SECTIONS_CONSTS.CLEAR_SECTIONS:
            return {
                ...state,
                sections: {...initialState.sections},
            };

        default:
            return state;
    }
}
