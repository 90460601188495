// import packages
import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";

//Import assets
import '../../assets/styles/components/cards/add-playlist-card.scss';

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {LinkButton} from "../uIElements/buttons";
import {InputGroup} from "../uIElements/inputGroup";
import {AddPlaylist, UpdatePlaylist} from "../../redux/actions";


function AddEditPlaylistCard(props) {
    const [playlist, setPlaylist] = useState("");
    const [isEditing, setEditing] = useState(props.playlist);
    const [errors, setErrors] = useState({});

    const ref = useRef();
    useEffect(() => {
        if (isEditing) {
            setPlaylist(isEditing.name);
        }
        window.addEventListener('click', onClicked)
        return () => {
            window.removeEventListener('click', onClicked)
        }
    }, []);

    function onClicked(e) {
        // console.log("current", ref.current)
        if (!e.path.includes(ref.current)) {
            props.togglePlaylistCard();
        }
    }

    const AddPlaylist = () => {
        const requiredFields = ['playlist'];
        const reqData = {}
        let isValid = true;

        requiredFields.forEach(key => {
            if (!playlist) {
                isValid = false;
                setErrors({
                    ...errors,
                    [key]: true,
                })
            }
        });
        if (isValid) {
            reqData.name = playlist;
            if (!isEditing) {
                reqData.name && props.AddPlaylist(reqData).then(() => {
                    props.togglePlaylistCard()
                    setPlaylist("")
                })
            } else {
                reqData.name && props.UpdatePlaylist(isEditing.id, reqData).then(() => {
                    props.togglePlaylistCard()
                    setPlaylist("")
                })
            }
        }
    }

    const getInputValues = (e) => {
        setPlaylist(e.target.value)
    };


    return <div className="add-playlist-dropdown-content" ref={ref}>
        <div>
            <div className="card-title">Add new playlist</div>
            <InputGroup
                inputType={'input'}
                type={"text"}
                name={'playlist'}
                value={playlist}
                error={errors.playlist}
                placeholder={"Type playlist name"}
                maxLength={100}
                onChange={getInputValues}
            />
        </div>
        <div className="btns-wrapper">
            <div onClick={props.togglePlaylistCard}>Cancel</div>
            <LinkButton title="Save"
                        cb={AddPlaylist}
            />
        </div>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, []);

const mapDispatchToProps = {
    AddPlaylist,
    UpdatePlaylist
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditPlaylistCard);


