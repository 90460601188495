// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link, useParams, useLocation} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';

//import Assets
import '../assets/styles/containers/playlist-details.scss';
import {ReactComponent as PlayIcon} from "../assets/images/playerBar-icons/ic_play_white.svg"

//import components
import TrackCard from "../components/cards/TrackCard";

//import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetSectionTracks, ClearTracks, setCurrentPlayedTrack, togglePlayState} from "../redux/actions";
import {GetSectionById} from "../redux/actions";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import MetaTags from "../components/MetaTags";
import {SkeletonItem} from "../components/uIElements/SkelatonItem";
import SkeletonTrackCard from "../components/skeletonCards/SkeletonTrackCard";
import {PageSkeleton} from "./PageSkeleton";
import {ReactComponent as FillHeart} from "../assets/images/playerBar-icons/ic_heart_fill.svg";
import {ReactComponent as EmptyHeart} from "../assets/images/playerBar-icons/ic_heart_empty.svg";
import {LinkButton} from "../components/uIElements/buttons";
import {ReactComponent as ShareSongIcon} from "../assets/images/playerBar-icons/ic_share.svg";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ReactComponent as CopyIcon} from "../assets/images/icons/ic_copy_link.svg";
import {FacebookShareButton} from "react-share";
import {ReactComponent as FacebookIcon} from "../assets/images/icons/ic_facebook_share.svg";
import {Image} from "../components/uIElements/Image";
import {ReactComponent as PauseIcon} from "../assets/images/playerBar-icons/ic_pause_white.svg";


function SectionDetails(props) {
    const params = useParams();
    const location = useLocation();
    const [isFetching, setFetching] = useState(false);
    const [section, setSection] = useState({
        ...location.state?.section || {},
    });
    const [loading, setLoading] = useState(true);
    const [shareWrapperIsOpen, setShareWrapperOpen] = useState(false);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        props.GetSectionById(params?.slug).then((res) => {
            setSection(res?.data);
            props.GetSectionTracks({id: res?.data?.id}).then(() => {
                setLoading(false)
            });
        });

        window.addEventListener('click', onClicked)
        return () => {
            window.removeEventListener('click', onClicked)
        }
        return function cleanup() {
            props.ClearTracks();
        };


    }, [params?.slug]);

    useEffect(() => {
        // console.log(props.shuffledTracksList, 'shuffledTracksList');
    }, [props.shuffledTracksList]);

    function onClicked() {
        setShareWrapperOpen(false);
    }

    function toggleShareWrapper(e) {
        e && e.preventDefault();
        e && e.stopPropagation();
        setShareWrapperOpen(!shareWrapperIsOpen);

    }

    function toggleFavorite() {
        // if (isLoggedIn) {
        //     if (!isFetching) {
        //         setFetching(true);
        //         const favoritePromise = section.isFavorite ? props.RemoveArtistFromFavorites(section.id) : props.AddArtistToFavorites(section.id);
        //         favoritePromise.finally(() => {
        //             setFetching(false);
        //             setSection({
        //                 ...section,
        //                 isFavorite: !section.isFavorite
        //             })
        //         })
        //     }
        // }
    }

    const loadMoreItems = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetSectionTracks({
                reset: false,
                offset: sectionTracks?.list?.length,
                id: section?.id,
            }).finally(() => {
                setFetching(false);
            });
        }
    };

    function setTrack() {
        if(location?.pathname.includes(props?.type)) {
            togglePlayState(!props.playState)
        }else {
            setCurrentPlayedTrack(sectionTracks?.list[0], sectionTracks?.list, location?.pathname, 0)
            togglePlayState(true)
        }
    }

    const {sectionTracks, isLoggedIn} = props;

    return <PageSkeleton withPadding>
        <MetaTags title={section?.title}
                  image={generateImageMediaUrl(sectionTracks?.list?.[0]?.coverImage?.path)}/>
       {/* <div className="playlist-header">
            <div className="img-wrapper">
                <Image url={section?.coverImage?.path} loading={loading} type={"playlist"}/>
            </div>
            <div className="playlist-description-content">
                <div style={{width: "100%"}}>
                    <div className="page-name">PLAYLIST</div>
                    <div className="playlist-name">{!loading ? section?.title :
                        <div style={{width: "100%", height: "35px", marginTop: "10px"}}>
                            <SkeletonItem/>
                        </div>}
                    </div>
                    <div
                        className="playlist-description">{!loading ? (section.tracksCount ? section.tracksCount : "no") + " songs" :
                        <div style={{width: "50%", height: "25px", marginTop: "15px", marginBottom: "5px"}}>
                            <SkeletonItem/>
                        </div>}
                    </div>
                </div>
                <div className="btns-wrapper">
                    <span className="play-all" onClick={setTrack}>
                        {location.pathname.includes(props?.type) && props?.playState ? <PauseIcon title={''} className="play-icon"/> : <PlayIcon title={''} className="play-icon"/>}
                        Play
                    </span>
                    <div className="heart-dropdown-wrapper" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleFavorite()
                    }
                    }>
                        <div className="heart-wrapper">
                            {section?.isFavorite ?
                                <FillHeart className='card-heart fill-heart' title=''/>
                                : <EmptyHeart className='card-heart' title=''/>}
                            <div className="like-count">{section?.likeCount}</div>
                        </div>
                        {!isLoggedIn && <div className={`heart-dropdown-content`}>
                            <span>Please sign in to save your favorites</span>
                            <LinkButton title="Sign in"
                                        link={"/sign-in"}
                            />
                        </div>
                        }
                    </div>
                    <div className="share-btn" onClick={toggleShareWrapper}>
                        <ShareSongIcon title=''/>
                        {shareWrapperIsOpen && <div className="dropdown-content">
                            <div className="share-wrapper">

                                <CopyToClipboard text={`https://youtop.app/section/${section?.slug}`}
                                                 className={"copy-wrapper"}
                                                 onCopy={() => setCopied(true)}
                                                 onMouseLeave={() => setCopied(false)}>
                                    <button onClick={e => {
                                        e.stopPropagation()
                                    }}>
                                        <CopyIcon/> Copy url
                                        <div className="copy-text">{copied ? "Copied" : 'Copy'}</div>
                                    </button>
                                </CopyToClipboard>

                                <FacebookShareButton url={`https://youtop.app/section/${section?.slug}`}
                                    // quote={`${section.title}`}
                                >
                                    <FacebookIcon/> Facebook
                                </FacebookShareButton>
                            </div>
                        </div>}
                    </div>
                </div>

            </div>
        </div>*/}
        <div className="section-header"><span>{!loading ? section?.title :
            <div style={{width: "100%", height: "35px", marginTop: "10px"}}>
                <SkeletonItem/>
            </div>}</span></div>

        {!loading ?
            (!!sectionTracks?.list?.length && <div className="artist-top-song-list">

                <InfiniteScroll
                    hasMore={sectionTracks?.hasMore}
                    loadMore={loadMoreItems}
                    className={'tracks-list'}
                    pageStart={0}
                    useWindow={true}
                    initialLoad={false}>
                    {
                        sectionTracks?.list?.map((item, index) => {
                            return <TrackCard key={item.id}
                                              track={item}
                                              playerTracksList={sectionTracks?.list}
                                              indexInPlayerList={index}
                            />
                        })
                    }
                </InfiniteScroll>
            </div>) : <SkeletonTrackCard count={3}/>
        }
    </PageSkeleton>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'sectionTracks',
        'isLoggedIn',
        'requestLoading',
        'type',
        'playState',
        'shuffledTracksList',
        'shuffled'
    ])
};

const mapDispatchToProps = {
    GetSectionTracks,
    ClearTracks,
    GetSectionById,
    togglePlayState
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionDetails);
