// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";

//Import components
import {SeeAll} from "../../components/uIElements/buttons";
import SquareSkeletonCard from "../../components/skeletonCards/SquareSkeletonCard";
import {CardComponents} from "../../components/cards/BaseCard";

//import utils
import {GetArtists, CleaArtists} from "../../redux/actions/artistsActions";
import {getPropsFromState} from "../../redux/mapStateToProps";

function Artists(props) {
    const location = useLocation();
    const inGenres = location.pathname.includes('genres');
    const path = inGenres && location.pathname.replace('genres', 'artists/genres');
    useEffect(() => {

    }, []);

    const setSkeletonCount = () => {
        if (window.innerWidth > 800) {
            return 4
        } else {
            return 3
        }
    }
    const {data, loading} = props;
    const artistsList = window.innerWidth > 800 ? data?.slice(0, 4) :data?.slice(0, 3)

    return <div className="homepage-artists-wrapper">
        <div className='section-title'>
            <span>Favorite Artists</span>
            {data?.length > 4 && <SeeAll link={`${inGenres ? path : `/home/artists`}`}/>}
        </div>
        {!loading ? (!!artistsList?.length && <div className="base-cards-list">
            {
                artistsList?.map(artist => {
                    return <CardComponents.ArtistCard artist={artist} key={artist.id}/>
                })
            }
        </div>) : <div className={'base-cards-list circle-cards'}>
            <SquareSkeletonCard count={setSkeletonCount()}/>
        </div>
        }
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'artists',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    GetArtists,
    CleaArtists
};

export default connect(mapStateToProps, mapDispatchToProps)(Artists);
