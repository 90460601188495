export const FB_APP_ID = '289118912425233'


export const searchTabs = {
    all: {
        name: "All",
        id: 'all',
    },
    songs: {
        name: "Songs",
        id: 'songs',
    },
    artists: {
        name: "Artists",
        id: 'artists',
    },
    playLists: {
        name: "Playlists",
        id: 'playLists',
    },
    albums: {
        name: "Albums",
        id: 'albums',
    },
    podcasts: {
        name: "Podcasts",
        id: 'podcasts',
    },

}

export const artistsDetailsTabs = {
    songs: {
        name: "Songs",
        id: 'songs',
    },
    albums: {
        name: "Albums",
        id: 'albums',
    },
};

export const profileTabs = {
    songs: {
        name: "Songs",
        id: 'songs',
    },
    playlists: {
        name: "Playlists",
        id: 'playlists',
    },
    albums: {
        name: "Albums",
        id: 'albums',
    },
    artists: {
        name: "Artists",
        id: 'artists',
    },
    listened: {
        name: "Listened",
        id: 'listened',
    },
};
export const homepageTabs = {
    all: {
        name: "All",
        id: 'all',
    },
    playlists: {
        name: "Playlists",
        id: 'playlists',
    },
    tracks: {
        name: "Songs",
        id: 'tracks',
    },
    artists: {
        name: "Artists",
        id: 'artists',
    },
    albums: {
        name: "Albums",
        id: 'albums',
    },
    // podcasts: {
    //     name: "Podcasts",
    //     id: 'podcasts',
    // },
};

export const sectionIds = {
    youtube: "5f0dc0417a7b9e6d1e6baa0a",
    shazam: "5f07756bc87d4022e99b783c",
};

export const ALBUM_TYPES = {
    MAIN: {
        key: "MAIN",
        name: 'Ալբոմ'
    },
    SINGLE: {
        key: "SINGLE",
        name: 'Սինգլ'
    },
    PLAYLIST: {
        key: "PLAYLIST",
        name: 'Փլեյլիստ'
    },
};

export const TRACK_TYPES = Object.freeze({
    TRACK: "TRACK",
    PODCAST: "PODCAST",
})
