// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

//Import assets
import '../assets/styles/containers/edit-profile.scss';

//Import Components
import ImageUploader from "../components/uIElements/imageUploader";
import MetaTags from "../components/MetaTags";
import {LinkButton} from "../components/uIElements/buttons";
import {InputGroup} from "../components/uIElements/inputGroup";
import {PageSkeleton} from "./PageSkeleton";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {
    GetCurrentUser,
    GetFavoriteTracks,
    GetPlaylists,
    GetListenedTracks,
    UpdateCurrentUser,
    togglePlayState,
    UploadProfilePicture
} from "../redux/actions";
import {generateFileMediaUrl} from "../utils/generateMediaUrl";
import {history} from "../configs/history";


const ProfileEdit = (props) => {
    const [profilePictureFile, setProfilePictureFile] = useState(null);
    const [errors, setErrors] = useState({});
    const [updatedDataMap, setUpdatedDataMap] = useState(new Map());
    const [userInfo, setUserInfo] = useState({
            firstName: props.loggedInUser?.firstName || "",
            lastName: props.loggedInUser?.lastName || "",
            username: props.loggedInUser?.username || "",
            phoneNumber: props.loggedInUser?.phoneNumber || ""
        }
    )
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, []);

    function setMap(entries) {
        setUpdatedDataMap(new Map(entries))
    }

    const getSelectedImageFile = (file) => {
        updatedDataMap.set('profilePicturePath', '');
        setMap(updatedDataMap);
        setProfilePictureFile(file);
    }

    const getInputValues = (e) => {
        const {name, value} = e.target;
        value !== user?.[name] ? updatedDataMap.set(name, value) :
            updatedDataMap.delete(name);
        setMap(updatedDataMap);
        setUserInfo({
            ...userInfo,
            [name]: value
        })
    }
    const SaveAccount = async () => {
        const requiredFields = ['firstName', "lastName"];
        let reqData = {};
        let isValid = true;
        updatedDataMap.forEach((value, key) => {
            reqData = {
                ...reqData,
                [key]: value
            }
        });
        const regExp = /^[0-9,.()-_+<>\\s]{9,20}$/
        if (userInfo.phoneNumber && !regExp.test(userInfo.phoneNumber)) {
            isValid = false;
            setErrors({
                ...errors,
                phoneNumber: true,
            })
        }
        requiredFields.forEach(key => {
            if (!userInfo[key]) {
                isValid = false;
                setErrors({
                    ...errors,
                    [key]: true,
                })
            }
        });
        if (isValid) {
            if (userInfo["firstName"] === props.loggedInUser?.firstName &&
                userInfo["lastName"] === props.loggedInUser?.lastName &&
                userInfo["phoneNumber"] === props.loggedInUser?.phoneNumber) {

            } else {
                props.UpdateCurrentUser(reqData).then(() => {
                    updatedDataMap.clear();
                    setMap(updatedDataMap);
                    setErrors({});
                })
            }
            if (profilePictureFile) {
                let formData = new FormData();
                formData.append('profilePicture', profilePictureFile);
                let newProfilePicture = await props.UploadProfilePicture(formData, 'profile-picture');
                if (newProfilePicture) {
                    reqData.profilePicturePath = newProfilePicture?.id;
                }
            }
            history.push("/profile/songs")
        }

    }

    const {loggedInUser} = props;

    const user = loggedInUser ?? {};
    return <PageSkeleton withPadding>
        <MetaTags title={`${props?.loggedInUser?.firstName || ''} ${props?.loggedInUser?.lastName || ''}`}
                  image={generateFileMediaUrl(user?.profilePicturePath?.path)}/>
        <div className="edit-account">
            <div className="edit-account-title">Your name and profile picture  in YouTop</div>
            {user?.profilePicturePath && <div className="uploader-wrapper">
                <ImageUploader noImageError={''}
                               image={generateFileMediaUrl(user?.profilePicturePath?.path)}
                               onChange={getSelectedImageFile}/>
            </div>}
            <div className="user-description-content">
                <div className={"fields-wrapper"}>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'firstName'}
                        value={userInfo.firstName}
                        placeholder={"Firstname"}
                        maxLength={100}
                        error={errors.firstName}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'lastName'}
                        value={userInfo.lastName}
                        placeholder={"Lastname"}
                        maxLength={100}
                        error={errors.lastName}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        disabled
                        name={'username'}
                        value={userInfo.username}
                        placeholder={"Username"}
                        maxLength={100}
                        error={errors.username}
                        onChange={getInputValues}
                    />
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'phoneNumber'}
                        value={userInfo.phoneNumber}
                        placeholder={"Phone number"}
                        maxLength={100}
                        regExp={"/^[0-9,.()-_+<>\\s]{9,20}$/"}
                        error={errors.phoneNumber}
                        onChange={getInputValues}
                    />
                </div>

                <div className="btns-wrapper">
                    <div className={`heart-wrapper save-wrapper`}>
                        <LinkButton title={<div className='card-heart save-btn'>Save</div>} cb={SaveAccount}/>
                    </div>
                </div>
            </div>
        </div>
    </PageSkeleton>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'loggedInUser',
]);

const mapDispatchToProps = {
    GetCurrentUser,
    GetFavoriteTracks,
    GetPlaylists,
    GetListenedTracks,
    UpdateCurrentUser,
    togglePlayState,
    UploadProfilePicture
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);


