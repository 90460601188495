// import packages
import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

// import assets
import '../../assets/styles/components/cards/user-playlist-card.scss'
import {ReactComponent as MoreIcon} from "../../assets/images/icons/ic_more.svg";

//import components
import AddEditPlaylistCard from "./AddEditPlaylistCard";

//import utils
import {AddTrackToPlaylist, DeletePlaylist} from "../../redux/actions";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {Image} from "../uIElements/Image";

function UserPlayListCard(props) {
    const {playlist} = props;
    const [selectedPlaylist, setSelectedPlaylist] = useState('');
    const [cardActionsOpen, setCardActionsOpen] = useState(false);
    const [playlistCardOpen, setPlaylistCardOpen] = useState(false);

    const ref = useRef();

    useEffect(() => {
        if (cardActionsOpen) {
            window.addEventListener('click', onClicked)
        } else {
            window.removeEventListener('click', onClicked)
        }
        return () => {
            window.removeEventListener('click', onClicked)
        }
    }, [cardActionsOpen]);

    function onClicked(e) {
        if (!e.path.includes(ref.current)) {
            setCardActionsOpen(false);
        }
    }

    function selectPlaylist(e, id) {

        e.preventDefault();
        e.stopPropagation();
        props.AddTrackToPlaylist(id, props.track.id, props.trackImage).then(() => {
            setSelectedPlaylist(id)
            props.toggleTrackMoreCard();
        })
        props.addToPlaylist && props.addToPlaylist(id, props.track)

    }

    function deletePlaylist(e, id) {
        e && e.preventDefault();
        e && e.stopPropagation();
        props.DeletePlaylist(id)
    }

    const togglePlaylistCard = (e) => {
        e && e.preventDefault();
        e && e.stopPropagation();
        setPlaylistCardOpen(!playlistCardOpen);
        setCardActionsOpen(false)
        // toggleCardActions();
    }

    function toggleCardActions(e) {
        setCardActionsOpen(!cardActionsOpen);
        setPlaylistCardOpen(false)
    }

    function checkCanRedirectPlayListPage(e) {
        !playlist?.tracksCount && e.preventDefault();
    }

    return <div className="user-playlist-item" key={playlist.id} onClick={(e) => {
        props.checkPlaylist && selectPlaylist(e, playlist.id)
    }}>
        <div className="playlist-img-wrapper">
            {!props.checkPlaylist && <MoreIcon title={''} className={'more-icon'} onClick={toggleCardActions}/>}
            {cardActionsOpen &&
            <div className="card-actions" ref={ref}>
                <div className="action" onClick={(e) => togglePlaylistCard(e)}>Edit</div>
                <div className="action" onClick={(e) => deletePlaylist(e, playlist.id)}>Delete</div>
            </div>
            }
            {playlistCardOpen && <AddEditPlaylistCard togglePlaylistCard={togglePlaylistCard}
                                                      playlist={playlist}/>}
            {selectedPlaylist &&
            <img className="selected-playlist" src={require("../../assets/images/icons/ic_check.svg")} alt=""/>}
            {!props.checkPlaylist ? <Link to={{pathname: `/profile/playlist/${playlist.id}`, state: {playlist}}}
                                          className="img-content user-playlist-img-content"
                                          onClick={checkCanRedirectPlayListPage}>
                <Image url={playlist?.firstTrackImage?.path} type={"playlist"}/>
            </Link> : <div className="img-content user-playlist-img-content">
                <Image url={playlist?.firstTrackImage?.path} type={"playlist"}/>
            </div>
            }
        </div>
        <div className="playlist-item-content">
            {!props.checkPlaylist ? <Link to={{pathname: `/profile/playlist/${playlist.id}`, state: {playlist}}}
                                          className="playlist-item-title"
                                          onClick={checkCanRedirectPlayListPage}>
                {playlist?.name}
            </Link> : <div className="playlist-item-title"
                           onClick={checkCanRedirectPlayListPage}>
                <div>{playlist?.name}</div>
                <div className="items-count">{playlist?.tracksCount ? playlist?.tracksCount : "No"} tracks</div>
            </div>
            }
        </div>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'isLoggedIn',
    'userPlaylists'
]);

const mapDispatchToProps = {
    AddTrackToPlaylist,
    DeletePlaylist
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPlayListCard);
