// Import packages
import React, {PureComponent} from "react";

// Import components

// Import styles
import "../../assets/styles/components/uiElements/image-uploader.scss";

//import utils
import {hasImageExtension} from "../../utils/hasExtension";
import {IMAGE_ACCEPT_TYPES, ACCEPT_IMAGE_SIZE} from "../../constants/acceptedConsts";


export default class ImageUploader extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            image:  this.props.image,
            imageError: false,
            errorMessage: "",
            imageExist: true,
        };
        this.imageSelector = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.image && this.props.image) {
            this.setState({
                image: this.props.image
            })
        }
    }

    readFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Read the image via FileReader API and save image result in state.
            reader.onload = function (e) {
                let dataURL = e.target.result;
                resolve( dataURL);
            };
            reader.onerror = function (e) {
                //console.log(e);
                reject(e);
            };
            reader.readAsDataURL(file);
        });
    }

    handleImageChange(e) {
        let file = e && e.target && e.target.files && e.target.files[0];

        if (file) {
            if (!hasImageExtension(file.name)) {
                this.setState({
                    imageError: true,
                    errorMessage: `Accepted: ${IMAGE_ACCEPT_TYPES.join('| ')}`
                });
                //this.props.onChange('');
                return;
            }
            if (file.size > ACCEPT_IMAGE_SIZE) {
                this.setState({
                    imageError: true,
                    errorMessage: `File size is too big ${Math.floor(ACCEPT_IMAGE_SIZE / 1048576)}mb`
                });
                //  this.props.onChange('');
                return;
            }
            //data.append('file', file, file.name);
            this.readFile(file)
                .then(dataURL => {
                    // console.log(res);
                    this.setState({
                        image: dataURL,
                        imageError: false,
                        errorMessage: "",
                        imageExist: true,
                    }, () => this.props.onChange(file))
                });
        }
    }

    render() {
        let {image} = this.state;
        return <div className={`image-uploader`}>
            <div className={'image-wrapper'}>
                <div className="img-content">
                    {
                        image ? <img src={image} alt=" "/> : ''
                    }
                </div>
            </div>
            <div className="img-description">
                <div><div className="img-description-text">
                    Upload a photo for your responses,
                </div>
                    <div className="img-description-text">
                        comments and reviews
                    </div></div>
                <div className={`img-selector`}
                     onClick={() => this.imageSelector.current.click()}>
                    Add Photo
                </div>
                <input type="file" accept="image/*" ref={this.imageSelector}
                       onChange={(e) => this.handleImageChange(e)}/>
            </div>
        </div>
    }
}
