// import packages
import React, {useEffect, useRef, useState} from "react";
import {connect} from 'react-redux';
import {Link, NavLink, useLocation} from "react-router-dom";

//import styles
import "../assets/styles/components/header.scss";
import {ReactComponent as SearchIcon} from "../assets/images/icons/ic_search.svg";
import {ReactComponent as EmptyHeart} from "../assets/images/icons/ic_heart.svg";
import {ReactComponent as NotificationIcon} from "../assets/images/icons/ic_notification.svg";

import SearchSuggestions from "./uIElements/SearchSuggestions";
import {LinkButton} from "./uIElements/buttons";
import {generateFileMediaUrl} from "../utils/generateMediaUrl";
import {SocialSignIn, LogOut} from "../redux/actions";
import {mapStateToProps} from "../redux/mapStateToProps";


function Header(props) {
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [showProfileMenu, setProfileMenu] = useState(false);

    const ref = useRef();
    const location = useLocation();

    useEffect(() => {
        if (showProfileMenu) {
            window.addEventListener('click', onClicked)
        } else {
            window.removeEventListener('click', onClicked)
        }
        return () => {
            window.removeEventListener('click', onClicked)
        }
    }, [showProfileMenu]);

    function onClicked(e) {
        if (!e.path?.includes(ref.current)) {
            setProfileMenu(false);
        }
    }

    const toggleProfileMenu = () => {
        setProfileMenu(!showProfileMenu);
    };
    const {loggedInUser, nonOpenedNotificationCount} = props;

    return <>
        <div className="header-wrapper">
            <div className="left-side">
                <Link to={'/home'} className="logo-wrapper"
                      onClick={() => setShowSuggestions(false)}>
                    <img className={"header-logo"} src={require('../assets/images/logo.svg')} alt=""/>
                    <span className={"brand-name"}>YouTop</span>
                </Link>
                <SearchSuggestions className={'dropdown'}/>
            </div>
            <div className="right-side">
                <div className="menu-wrapper">
                    <ul className="menu-items-list">
                        <li>
                            <NavLink to={`/home`} className="menu-item" activeClassName='active'>Home</NavLink>
                        </li>
                        {/*<li>*/}
                        {/*    <NavLink to={`/genres-list`} className="menu-item" activeClassName='active'>Genres</NavLink>*/}
                        {/*</li>*/}
                        <li>
                            <NavLink to={`/apps`} className="menu-item" activeClassName='active'>Apps</NavLink>
                        </li>
                    </ul>
                    {props.isLoggedIn &&
                        <div className="menu-right-items">
                            <ul className="menu-items-list">
                                <li>
                                    <Link to={`/profile/songs`} className="menu-item">
                                        <EmptyHeart className='card-heart' title=''/>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/notifications`} className="menu-item notifications">
                                        <NotificationIcon className='card-heart' title=''/>
                                        {!!nonOpenedNotificationCount && <div className="badge"/>}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    }
                </div>
                {props.isLoggedIn ?
                    <div>
                        <div className="profile-dropdown-wrapper"
                             onClick={location.pathname.startsWith('/profile') && !showSuggestions ?
                                 toggleProfileMenu :
                                 () => setProfileMenu(false)}>
                            <Link to={"/profile/songs"} onClick={() => setShowSuggestions(false)}>
                                <img className="user-avatar"
                                     src={generateFileMediaUrl(loggedInUser?.profilePicturePath?.path)} alt=""/>
                            </Link>

                        </div>
                        {showProfileMenu && <div className='profile-dropdown-menu' ref={ref}>
                            <Link to="/profile/songs" className={'menu-item'}
                                  onClick={
                                      () => {
                                          setShowSuggestions(false)
                                          setProfileMenu(false)
                                      }
                                  }>
                                <span>Favorite songs</span>
                            </Link>
                            <Link to="/profile/playlists" className={'menu-item'}
                                  onClick={
                                      () => {
                                          setShowSuggestions(false)
                                          setProfileMenu(false)
                                      }
                                  }>
                                <span>My playlists</span>
                            </Link>
                            <Link to="/profile/albums" className={'menu-item'}
                                  onClick={
                                      () => {
                                          setShowSuggestions(false)
                                          setProfileMenu(false)
                                      }
                                  }>
                                <span>Saved albums</span>
                            </Link>
                            <Link to="/profile/artists" className={'menu-item'}
                                  onClick={
                                      () => {
                                          setShowSuggestions(false)
                                          setProfileMenu(false)
                                      }
                                  }>
                                <span>Favorite artists</span>
                            </Link>
                            <Link to="/profile/listened" className={'menu-item'}
                                  onClick={
                                      () => {
                                          setShowSuggestions(false)
                                          setProfileMenu(false)
                                      }
                                  }>
                                <span>Listened history</span>
                            </Link>
                            <div className={'menu-item'} onClick={props?.LogOut}>
                                <span>Log out</span>
                            </div>
                        </div>}
                    </div> :
                    <LinkButton title="Sign in"
                                link="/sign-in"
                                className={'sign-in-btn'}
                    />
                }
            </div>
        </div>
    </>
}

const mapDispatchToProps = {
    SocialSignIn,
    LogOut
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);