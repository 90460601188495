// import packages
import React, {Component, useEffect, useState} from "react";
import {useHistory, useParams, useLocation} from "react-router-dom";
import {connect} from "react-redux";

//import styles
import "../assets/styles/containers/homepage/categories.scss";

//import Components
import CustomTabs from "../components/uIElements/Tabs";
import {LinkButton} from "../components/uIElements/buttons";

//import utils
import {homepageTabs} from "../constants/constants";
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetGenreById} from "../redux/actions";

function Categories(props) {
    const [categoriesListIsOpen, setCategoriesListOpen] = useState(false);
    const history = useHistory();
    const params = useParams();
    const location = useLocation();
    let currentTab = params?.tab ? params?.tab : "all";
    const {genres} = props;
    // let genreId = params?.slug;
    let slug = params?.slug;
    let subGenreId = params?.subGenreId;
    let genreById = genres?.length && genres.find(item => item.slug === slug);

    useEffect(() => {
    }, [params.id]);

    // console.log(location.pathname, 'PARAMS');
    const changeTab = (tab) => {
        history.push(`/home/${tab}`);
        if (tab === "all") history.push(`/home`)
    };

    const changeGenreTab = (tab) => {
        let genreId = genres.find(item => item.slug === slug).id;

        history.push(`/genres/${slug}/subGenre/${genreById?.subGenres[tab]?.id}`);
        if (!tab) history.push(`/genres/${slug}`);
        props.getGenreItems(genreById?.subGenres[tab]?.id || genreId)
    };

    const toggleCategoriesList = () => {
        setCategoriesListOpen(!categoriesListIsOpen)
    };

    const onClickGenre = (slug, subGenreId) => {
        let genreId = genres.find(item => item.slug === slug).id;

        history.push(`/genres/${slug}${subGenreId !== undefined ? "/genre/" + subGenreId : ""}`);
        setCategoriesListOpen(false);
        props.getGenreItems && props.getGenreItems(subGenreId ? subGenreId : genreId);
    };

    return !categoriesListIsOpen ? <div className='top-banner-wrapper'>
        <div className="top-banner">
            <LinkButton title={<>
                <div className={`nav-bar-menu ${categoriesListIsOpen ? 'active' : ''}`}>
                    <span className="line line-1"/>
                    <span className="line line-2"/>
                    <span className="line line-3"/>
                </div>
                <span onClick={(e) => {
                    e.stopPropagation();
                    location.pathname !== 'home' && history.push('/')
                }
                }>All Categories</span>
            </>}
                        cb={toggleCategoriesList}/>
            <div className="homepage-tabs-wrapper">
                <h1 className="banner-title">{`${slug ? genreById?.title : "All songs in one place"}`}</h1>
                <CustomTabs tabsList={slug ? (genreById?.subGenres ?? []) : homepageTabs}
                            changeTab={slug ? changeGenreTab : changeTab}
                            currentTab={currentTab && !slug ? currentTab : (!subGenreId ? slug : subGenreId)}
                            allTab={slug}
                            allTabActive={slug && !subGenreId}
                />
            </div>
        </div>
    </div> : <div className="top-banner-wrapper categories">
        <div className="top-banner">
            <LinkButton cb={toggleCategoriesList}
                        title={<>
                            <div className={`nav-bar-menu ${categoriesListIsOpen ? 'active' : ''}`}>
                                <span className="line line-1"/>
                                <span className="line line-2"/>
                                <span className="line line-3"/>
                            </div>
                            All Categories</>}
            />
            {genres && !!genres?.length && genres?.map((genre) =>
                <div className="genre-content" key={genre?.id}>
                    {slug === genre?.slug ?
                        <div className="genre-item active-genre" key={genre.id}>{genre?.title}</div>
                        : <div className="genre-item" key={genre.id}
                               onClick={() => onClickGenre(genre?.slug)}>{genre?.title}</div>
                    }
                    {genre?.subGenres && !!genre?.subGenres?.length && <ul className="subGenre-list">
                        {
                            genre?.subGenres.map((subGenre) =>
                                subGenreId === subGenre.id ?
                                    <li key={subGenre.id} className="active-subGenre">{subGenre?.title}</li>
                                    : <li key={subGenre.id}
                                          onClick={() => onClickGenre(genre?.slug, subGenre.id)}>{subGenre?.title}</li>
                            )
                        }
                    </ul>}
                </div>
            )}

        </div>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'genres',
]);

const mapDispatchToProps = {
    GetGenreById
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
