export const NOTIFICATION_ROW_MODES = {
    SUCCESS: 'success',
    ERROR: 'error',
    INFO: 'info',
};

export const NOTIFICATION_TYPES = {
    INFORMATION: {
        key: "INFORMATION",
        value: "info",
        name: 'Ինֆորմացիոն'
    },
    ARTIST: {
        key: "ARTIST",
        value: "artist",
        name: 'Արտիստ'
    },
    TRACK: {
        key: "TRACK",
        value: "album",
        name: 'Երգ'
    },
    ALBUM: {
        key: "ALBUM",
        // value: "SINGLE,MAIN",
        value: "album",
        name: 'Ալբոմ'
    },
    // SINGLE: {
    //     key: "SINGLE",
    //     name: 'Սինգլ'
    // },
    PLAYLIST: {
        key: "PLAYLIST",
        value: "album",
        name: 'Փլեյլիստ'
    },
};

export const REPORT_TYPES = {
    INCONSISTENT_ARTIST: "INCONSISTENT_ARTIST", //անհամապատասխան արտիստ
    AUDIO_PROBLEM: "AUDIO_PROBLEM",
    UNDESIRABLE_CONTENT: "UNDESIRABLE_CONTENT", //Անցանկալի բովանդակություն
    OTHER: "OTHER"
}

export const TRACK_TYPES = {
    TRACK: "TRACK",
    PODCAST: "PODCAST",
}