import {PLAYLIST_CONSTS} from "../constants";

export const initialState = {
    userPlaylists: {
        list: [],
        text: '',
        hasMore: false,
    }
};
export default (state = initialState, action) => {
    switch (action.type) {
        case PLAYLIST_CONSTS.GET_PLAYLISTS:

            return {
                ...state,
                userPlaylists: {
                    list: action.payload,
                }
            };
        case PLAYLIST_CONSTS.ADD_PLAYLIST:
            const updatedTracksList = {
                list: [action.payload].concat(state.userPlaylists.list)
            };
            return {
                ...state,
                userPlaylists: updatedTracksList,
            };
        case PLAYLIST_CONSTS.ADD_TRACK_TO_PLAYLIST:
            const updatedPlaylist = {
                list: state.userPlaylists.list.map(item => item.id === action.payload?.id ?
                    {
                        ...item,
                        firstTrackImage: item.firstTrackImage ?  item.firstTrackImage : action.payload.trackImage,
                        tracksCount: item.tracksCount + 1
                    } : item)
            };
            return {
                ...state,
                userPlaylists: updatedPlaylist,
            };
        case PLAYLIST_CONSTS.UPDATE_PLAYLIST:
            const updatedTracksLists = {
                list: state.userPlaylists.list.map(item => item.id === action.payload?.id ? action.payload.data : item)
            };
            return {
                ...state,
                userPlaylists: updatedTracksLists,
            };
        case PLAYLIST_CONSTS.DELETE_PLAYLIST:
            const updatedPlaylists = {
                list: state.userPlaylists.list.filter(playlist => playlist.id !== action.payload)
            };
            return {
                ...state,
                userPlaylists: updatedPlaylists,
            };
        default:
            return state;
    }
}