// import packages
import React from "react";

// import assets
import '../../assets/styles/components/cards/playlist-card.scss'

//import utils
import {Image} from "../uIElements/Image";

function PlayListCard(props) {
    const {section, tracksCount} = props;
    return <div className="playlist-item" key={section.id}>
        <Image url={section?.coverImage?.path}/>
        <div className="playlist-item-content">
            <div className="playlist-item-title">{section?.title}</div>
            {tracksCount && <div className="playlist-item-description">{`${section?.tracksCount} songs`}</div>}
        </div>
    </div>
}

export default PlayListCard;