// Import packages
import React, {Component} from "react";
import {Route, Switch, Router, Redirect} from "react-router-dom";

// Import utils
import {history} from "../configs/history";

// Import pages
import Layout from "../containers/Layout";
import Homepage from "../containers/Homepage";
import Search from "../containers/Search";
import TrackDetails from "../containers/TrackDetails";
import SignIn from "../containers/SignIn";
import AlbumDetails from "../containers/AlbumDetails";
import ArtistDetails from "../containers/ArtistDetails";
import PlaylistDetails from "../containers/SectionDetails";
import Profile from "../containers/Profile";
import ProfileEdit from "../containers/ProfileEdit";
import AlbumTracks from "../containers/AlbumTracks";
import SectionTracks from "../containers/SectionTracks";
import {ProtectedRoute} from "./protectedRoute";
import UserPlaylistTracks from "../containers/UserPlaylistTracks";
import NotFound from "../containers/NotFound";
import PrivacyPolicy from "../containers/PrivacyPolicy";
import Contacts from "../containers/Contacts";
import About from "../containers/About";
import Apps from "../containers/Apps";
import GenreDetails from "../containers/GenreDetails";
import Genres from "../containers/Genres";
import Notifications from "../containers/Notifications";
import FavoriteGenres from "../containers/PreferredGenres";
import UploadApp from "../containers/UploadApp";
import GenreTypes from "../containers/GenreTypes";
import PodcastList from "../containers/podcast/PodcastList";
import UserPodcastDetails from "../containers/podcast/podcastDetails/PodcastDetails";
import PodcastDetails from "../containers/PodcastDetails";


export default class Routes extends Component {
    render() {
        return <Router history={history}>
            <Layout>
                {window.innerWidth > 600 ?
                    <Switch>
                        <Redirect exact from="/" to="/home"/>
                        <Route exact path="/home/:tab?" component={Homepage}/>
                        <Route path="/search/:tab/:text?" component={Search}/>
                        <Route exact path="/sign-in" component={SignIn}/>
                        <Route exact path="/genres/:slug/:subGenre?/:subGenreId?" component={GenreDetails}/>
                        <Route exact path="/:type/genres/:slug/:subGenre?/:subGenreId?" component={GenreTypes}/>
                        <Route exact path="/genres-list" component={Genres}/>
                        <Route exact path="/track/:slug/:top?" component={TrackDetails}/>
                        <Route exact path="/album/:slug" component={AlbumDetails}/>
                        <Route exact path="/album/:slug/track/:trackSlug" component={AlbumDetails}/>
                        {/*<Route exact path="/album/:slug/track/:trackId" component={AlbumTracks}/>*/}
                        <Route exact path="/artist/:slug/:tab" component={ArtistDetails}/>
                        <Route exact path="/section/:slug" component={PlaylistDetails}/>
                        <Route exact path="/section/:slug/track/:trackId" component={SectionTracks}/>
                        <Route exact path="/podcast/:slug" component={PodcastDetails}/>
                        <ProtectedRoute exact path="/profile/:tab" component={Profile}/>
                        <ProtectedRoute exact path="/edit-account" component={ProfileEdit}/>
                        <ProtectedRoute exact path="/profile/playlist/:id" component={UserPlaylistTracks}/>
                        <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
                        <Route exact path="/contacts" component={Contacts}/>
                        <Route exact path="/preferred-genres" component={FavoriteGenres}/>
                        <Route exact path="/about" component={About}/>
                        <Route exact path="/apps" component={Apps}/>
                        <Route exact path="/notifications" component={Notifications}/>
                        <Route exact path="/podcasts" component={PodcastList}/>
                        <Route exact path="/podcasts/:id" component={UserPodcastDetails}/>
                        <Route exact path="*" component={NotFound}/>
                    </Switch>
                    :
                    <>
                        <Switch>
                        {/*    <Redirect to="/upload-app"/>*/}
                            <Route exact path="/about" component={About}/>
                            <Route exact path="/contacts" component={Contacts}/>
                            <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
                            <Route exact path="/upload-app" component={UploadApp}/>
                            <Route exact path="*" component={UploadApp}/>
                        </Switch>
                    </>
                }
            </Layout>
        </Router>
    }
}
