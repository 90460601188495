// import packages
import React from "react";
import {Link} from "react-router-dom";

//import styles
import "../assets/styles/components/footer.scss";
import {ReactComponent as AppStore} from '../assets/images/download-app-store-badge.svg';
import {ReactComponent as GooglePlay} from '../assets/images/download-google-play-badge.svg';
import {ReactComponent as InstaIcon} from '../assets/images/insta.svg';
import {ReactComponent as FacebookIcon} from '../assets/images/facebook.svg';
import {ReactComponent as TwitterIcon} from '../assets/images/twitter.svg';
import {ReactComponent as SnapIcon} from '../assets/images/snapchat.svg';

//import utils
import {androidUrl, iosUrl} from "../configs/appUrls";


function Footer() {
    return <footer className='footer-wrapper'>
        <img className={"circle-bg"} src={require("../assets/images/circle_bg.png")} alt={""}/>
        <div className={"footer-main-content"}>
            <div className={"top-part"}>
                <div className={"left-side"}>
                    <div className={"footer-brand"}>
                        <img className={"header-logo"} src={require('../assets/images/logo_white.svg')} alt=""/>
                        <span className={"brand-name"}>YouTop</span>
                    </div>
                    <div className="app-wrapper">
                        <a href={androidUrl} target={'_blank'} rel="noopener noreferrer"><GooglePlay title=''/></a>
                        <a href={iosUrl} target={'_blank'} rel="noopener noreferrer"><AppStore title=''/></a>
                    </div>
                </div>
                <div className={"right-side"}>
                    <div className={"links-wrapper"}>
                        <div className={"links-title"}>Company</div>
                        <div className={"links"}>
                            <Link to={"/about"}>About Us</Link>
                            <Link to={"/careers"}>Careers</Link>
                            <Link to={"/help"}>Help</Link>
                        </div>
                    </div>
                    <div className={"links-wrapper"}>
                        <div className={"links-title"}>Legal</div>
                        <div className={"links"}>
                            <Link to={"/contacts"}>Contact</Link>
                            <Link to={"/privacy-policy"}>Terms</Link>
                            <Link to={"/privacy-policy"}>Privacy</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"bottom-part"}>
                <div className={"social-networks-title"}>Follow Us</div>
                <div className={"social-networks-icons"}>
                    <a href={"https://www.facebook.com/youtopmusic"} target={'_blank'} rel="noopener noreferrer"><FacebookIcon title=''/></a>
                    <a href={"/"} target={'_blank'} rel="noopener noreferrer"><TwitterIcon title=''/></a>
                    <a href={"/"} target={'_blank'} rel="noopener noreferrer"><InstaIcon title=''/></a>
                    <a href={"/"} target={'_blank'} rel="noopener noreferrer"><SnapIcon title=''/></a>
                </div>
                <div>© Copyright 2020 YouTop All rights reserved</div>
            </div>
        </div>
    </footer>
}

export default Footer
