// Import packages
import React from "react";

// Import assets
import '../assets/styles/containers/page-skeleton.scss'

// Import components


export function PageSkeleton(props) {

    const {children, rightContent, withPadding} = props;
    return <div className="page-skeleton-wrapper">
        <div className={`left-part-wrapper ${withPadding ? "paddingY" : ""}`}>
            {children}
        </div>

        <div className={`right-part-wrapper ${withPadding ? "paddingY" : ""}`}>
            {rightContent}
        </div>
    </div>
}