// Import packages
import React, {useEffect} from "react";
import {connect} from 'react-redux';

// Import styles
import '../assets/styles/containers/privacy-policy.scss';

// Import components

//import utils
import {getPropsFromState} from "../redux/mapStateToProps";

function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

    }, []);
    return <section className='privacy-policy-wrapper'>
        <div className='privacy-policy-title'>
            YouTop Terms and Condition
        </div>
        <div className="privacy-policy-text">
            <p> These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with
                https://youtop.app service operated by 4Steps Development LLC ("us", "we", or "our").</p>
            <p>Please read these Terms and Conditions carefully before using the Service.</p>
            <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these
                Terms.
                These Terms apply to all visitors, users and others who access or use the Service.</p>
            <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of
                the terms then you may not access the Service.</p>
        </div>
        <div className='privacy-policy-subtitle'>
            Content
        </div>
        <div className="privacy-policy-text">
            <p> Our Service allows you to post, link, store, share and otherwise make available certain information,
                text, graphics, videos,
                or other material ("Content"). You are responsible for the Content that you post to the Service,
                including its legality, reliability, and appropriateness.</p>
            <p>By posting Content to the Service, you grant us the right and license to use, modify, publicly perform,
                publicly display,
                reproduce, and distribute such Content on and through the Service. You retain any and all of your rights
                to any Content you submit, post or display on or through the Service and you are responsible for
                protecting those rights. You agree that this license includes the right for us to make your Content
                available to other users of the Service, who may also use your Content subject to these Terms.</p>
            <p>You represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and
                grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on or
                through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights
                or any other rights of any person.</p>
        </div>
        <div className='privacy-policy-subtitle'>
            Accounts
        </div>
        <div className="privacy-policy-text">
            <p> When you create an account with us, you must provide us information that is accurate, complete, and
                current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate
                termination of your account on our Service.</p>
            <p>You are responsible for safeguarding the password that you use to access the Service and for any
                activities or actions under your password, whether your password is with our Service or a third-party
                service.</p>
            <p>You agree not to disclose your password to any third party. You must notify us immediately upon becoming
                aware of any breach of security or unauthorized use of your account.</p>
            <p>You may not use as a username the name of another person or entity or that is not lawfully available for
                use, a name or trade mark that is subject to any rights of another person or entity other than you
                without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>
        </div>
        <div className='privacy-policy-subtitle'>
            Copyright Policy
        </div>
        <div className="privacy-policy-text">
            <p> We respect the intellectual property rights of others. It is our policy to respond to any claim that
                Content posted on the Service infringes the copyright or other intellectual property infringement
                ("Infringement") of any person.</p>
            <p>If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work
                has been copied in a way that constitutes copyright infringement that is taking place through the
                Service, you must submit your notice in writing to the attention of "Copyright Infringement" of
                youtopclub@gmail.com and include in your notice a detailed description of the alleged
                Infringement.</p>
            <p>You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that
                any Content is infringing your copyright.</p>
        </div>
        <div className='privacy-policy-subtitle'>
            Intellectual Property
        </div>
        <div className="privacy-policy-text">
            <p> The Service and its original content (excluding Content provided by users), features and functionality
                are and will remain the exclusive property of 4Steps Development LLC and its licensors. The Service
                is protected by copyright, trademark, and other laws of both the Armenia and foreign countries. Our
                trademarks and trade dress may not be used in connection with any product or service without the prior
                written consent of 4Steps Development LLC.</p>
        </div>
        <div className='privacy-policy-subtitle'>
            Links To Other Web Sites
        </div>
        <div className="privacy-policy-text">
            <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by
                4Steps Development LLC.</p>
            <p> 4Steps Development LLC has no control over, and assumes no responsibility for, the content, privacy
                policies, or practices of any third party web sites or services. You further acknowledge and agree that
                4Steps Development LLC shall not be responsible or liable, directly or indirectly, for any damage or
                loss caused or alleged to be caused by or in connection with use of or reliance on any such content,
                goods or services available on or through any such web sites or services.</p>
            <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites
                or services that you visit.</p>
        </div>
        <div className='privacy-policy-subtitle'>
            Termination
        </div>
        <div className="privacy-policy-text">
            <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason
                whatsoever, including without limitation if you breach the Terms.</p>
            <p> Upon termination, your right to use the Service will immediately cease. If you wish to terminate your
                account, you may simply discontinue using the Service.</p>
        </div>
        <div className='privacy-policy-subtitle'>
            Limitation Of Liability
        </div>
        <div className="privacy-policy-text">
            <p>In no event shall 4Steps Development LLC, nor its directors, employees, partners, agents, suppliers,
                or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages,
                including without limitation, loss of profits, data, use, goodwill, or other intangible losses,
                resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct
                or content of any third party on the Service; (iii) any content obtained from the Service; and (iv)
                unauthorized access, use or alteration of your transmissions or content, whether based on warranty,
                contract, tort (including negligence) or any other legal theory, whether or not we have been informed of
                the possibility of such damage, and even if a remedy set forth herein is found to have failed of its
                essential purpose.</p>
        </div>
        <div className='privacy-policy-subtitle'>
            Disclaimer
        </div>
        <div className="privacy-policy-text">
            <p>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE"
                basis. The Service is provided without warranties of any kind, whether express or implied, including,
                but not limited to, implied warranties of merchantability, fitness for a particular purpose,
                4Steps Development LLC its subsidiaries, affiliates, and its licensors do not warrant that a) the
                Service will function uninterrupted, secure or available at any particular time or location; b) any
                errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or
                d) the results of using the Service will meet your requirements.</p>
        </div>
        <div className='privacy-policy-subtitle'>
            Governing Law
        </div>
        <div className="privacy-policy-text">
            <p>These Terms shall be governed and construed in accordance with the laws of Armenia, without regard to its
                conflict of law provisions.</p>
            <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those
                rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining
                provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us
                regarding our Service, and supersede and replace any prior agreements we might have between us regarding
                the Service.</p>
        </div>
        <div className='privacy-policy-subtitle'>
            Changes
        </div>
        <div className="privacy-policy-text">
            <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision
                is material we will try to provide at least 15 days notice prior to any new terms taking effect. What
                constitutes a material change will be determined at our sole discretion.</p>
            <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound
                by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>
        </div>
        <div className='privacy-policy-subtitle'>
            Contact Us
        </div>
        <div className="privacy-policy-text">
            <p>If you have any questions about these Terms, please contact us <span>youtopclub@gmail.com</span></p>
        </div>
    </section>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'contacts',
]);

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
