export const ACTION_TYPES = {
    AUTHENTICATION: "authentication",
    AUTHENTICATED: "authenticated",
    UNAUTHORIZED: "unauthorized",
    M_AUTHENTICATED: "m_authenticated",
    M_UNAUTHORIZED: "m_unauthorized",
    N_AUTHENTICATED: "n_authenticated",
    N_UNAUTHORIZED: "n_unauthorized",

    //Notification action types
    NEW_NOTIFICATION: "new-notification",
};