
import {stringify} from "query-string";
import {_urlMedia, _urlOauth, _urlUsers, request} from "../api";
import {store} from "../store";
import {history} from "../../configs/history";
import {AUTH_CONSTS, GENERAL_CONSTS, UTIL_CONSTS} from "../constants";


export const SocialSignIn = (socialToken, socialName, redirectTo = '/') => {
    const requestData = {
        url: `${_urlOauth}/${socialName}`,
        method: "POST",
        data: stringify({"socialToken": socialToken}),
        customHeaders: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    };

    return dispatch => {
        return request(requestData).then(async (res) => {
             // console.log(`logged-in user by ${socialName}`, res.data);
            GetLoggedInUser(res.data.accessToken).then(async (loggedInUser) => {
                 console.log('user', res.data);
                await dispatch({
                    type: AUTH_CONSTS.LOG_IN,
                    payload: {
                        ...res.data,
                        user: loggedInUser,
                    }
                });
                history.push(loggedInUser.isFirstTime ?  "/preferred-genres" : "/");
            });

        })
    }
};

export const GetLoggedInUser = async (newToken) => {
    // console.log('newToken', newToken);
    const requestData = {
        url: `${_urlUsers}/current`,
        token: true,
        newToken
    };
    return request(requestData).then((res) => {
        return res?.data
    })
};

export const RefreshToken = () => {

    const refToken = store.getState().auth.refreshToken;
    //console.log('start-refreshing-function');
    const requestData = {
        url: `${_urlOauth}/refresh-token`,
        method: "POST",
        data: stringify({
            grant_type: "refresh_token",
            refresh_token: refToken
        }),
        customHeaders: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    };
    //console.log(RefreshToken.startRefreshing);
    if (!RefreshToken.startRefreshing) {
        RefreshToken.startRefreshing = true;
         //console.log('start-refreshing-request');
        RefreshToken.promise = request(requestData).then(async (response) => {
            //    console.log(response);
            response && await store.dispatch({
                type: AUTH_CONSTS.REFRESH_TOKEN,
                payload: response.data
            });
            return response && response.data && response.data.accessToken;
        }).finally(() => {
               //console.log('end-refreshing-request-finally');
            RefreshToken.startRefreshing = false;
            RefreshToken.promise = null;
        });
    }
     //console.log('end-refreshing-function');
    return RefreshToken.promise;
};
RefreshToken.startRefreshing = false;
RefreshToken.promise = null;

export const TokenValidation = () => {
    const AccessToken = store.getState().auth.accessToken;
    const requestData = {
        url: `${_urlOauth}/authenticate`,
        method: "GET",
        customHeaders: {
            "accept": "application/json",
            "x-access-token": AccessToken,
        }
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                // console.log('token-validation-success', res);
                return true;
            })
            .catch(() => {
                //console.log('error in auth-validation');
                dispatch({
                    type: AUTH_CONSTS.LOG_OUT
                });
                return false;
            })
    }
};

export const UploadProfilePicture = (formData, fileType) => {
    const requestData = {
        url: `${_urlMedia}/${fileType}`,
        token: true,
        method: "POST",
        data: formData,
        customHeaders: {
            "Content-Type": "multipart/form-data",
        }
    };

    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: AUTH_CONSTS.UPDATE_PROFILE_PICTURE,
                    payload: data
                });
                return data
            })
            .catch(() => null).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                });
            });
    }
};

export const LogOut = () => {
    //console.log("logout");
    return async dispatch => {
        await dispatch({
            type: AUTH_CONSTS.LOG_OUT
        });
        history.push('/');
    }
};

