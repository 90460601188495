//-------------------local--------------------
// const AUTH_URL ="http://localhost:3010/api/v2";
// const USERS_URL =  "http://localhost:3020/api/v2";
// const BASE_URL =  "http://localhost:3030/api/v2";
// const MEDIA_URL = "http://localhost:3040";
// const HOST_MEDIA_URL_V2 = "http://localhost:3040/api/v2";


//----------------------------------4Steps-server--------------------------------------------------

const AUTH_URL ="https://api.youtop.am/auth/api/v2";
const USERS_URL =  "https://api.youtop.am/users/api/v2";
const BASE_URL =  "https://api.youtop.am/tracks/api/v2";
const HOST_MEDIA_URL_V2 = "https://api.youtop.am/resources/v2";
const MEDIA_URL =  "https://api.youtop.am/resources/api/v2";
const NOTIFICATION_URL =  "https://api.youtop.am/notifications/api/v2";

const SOCKET_NOTIFICATION_URL = "https://api.youtop.am/";

// const HOST_MEDIA_URL_V1 = "https://api.youtop.app/resources";
// const ADMIN_URL = "http://173.249.20.192:2410/api/v2";
// const AUTH_URL ="http://173.249.20.192:8500/api/v2";
// const USERS_URL =  "http://173.249.20.192:8501/api/v2";
// const BASE_URL =  "http://173.249.20.192:8503/api/v2";
// const HOST_MEDIA_URL_V2 = "http://173.249.20.192:8502/v2";

export const _hostMedia = HOST_MEDIA_URL_V2;
export const _urlOauth = AUTH_URL + "/jwt";
export const _urlUsers = USERS_URL + "/users";
export const _urlLanguage = BASE_URL + "/languages";
export const _urlTracks = BASE_URL + "/tracks";
export const _urlArtists = BASE_URL + "/artists";
export const _urlAlbums = BASE_URL + "/albums";
export const _urlSections = BASE_URL + "/sections";
export const _urlPlaylists = BASE_URL + "/playlists";
export const _urlPodcasts = BASE_URL + "/podcasts-list";
export const _urlGenres = BASE_URL + "/genres";
export const _urlMedia = MEDIA_URL + "/media";
export const _urlFile = MEDIA_URL + "/user";
export const _urlReports = BASE_URL + "/reports";
export const _urlNotifications = NOTIFICATION_URL + "/notifications";

export const _urlSocketNotification = SOCKET_NOTIFICATION_URL;

//----------------------------------4Steps-server---------------------------------------------------



