// import packages
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { history } from "../configs/history";

//import Assets
import '../assets/styles/containers/track-details.scss'

//import components
import PageWrapper from "./PageWrapper";
import TrackCard from "../components/cards/TrackCard";
import YouTubePlayer from "../components/uIElements/YouTubePlayer";
import MetaTags from "../components/MetaTags";
import { Link, useLocation, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';

//import utils
import { getPropsFromState } from "../redux/mapStateToProps";
import { ClearTracks, GetTrackById, GetTracks } from "../redux/actions";
import { getYoutubeId } from "../utils/helperFunctions";
import {SkeletonItem} from "../components/uIElements/SkelatonItem";
import SkeletonTrackCard from "../components/skeletonCards/SkeletonTrackCard";
import { generateImageMediaUrl } from "../utils/generateMediaUrl";




function TrackDetails(props){
    const params = useParams();
    const location = useLocation();
    const [isFetching, setFetching] = useState(false);
    const [videoIdList, setVideoIdList] = useState([]);
    const [track, setTrack] = useState({
        ...location.state?.track || {},
    });
    const [currentArtist, setCurrentArtist] = useState({});
    const [tracksList, setTracksList] = useState([]);
    const [paused, setPaused] = useState(false);
    const [loading, setLoading] = useState(true);
    const {tracks} = props;

    useEffect(() => {
       console.log('mounted/updated-> ', params.slug)
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        const newTrackIsList = tracksList.find(tr => tr.id === params?.trackId);

        if ( newTrackIsList ) {
            // եթե երգը փոխում է թոփ երգերից ինչ-որ մեկը;
           console.log('newTrackIsList')
            setTrack(newTrackIsList);
        } else {
            props.GetTrackById(params.slug).then((res) => {
               // console.log('get Track By Id')
                const newTrack = res.data;
                setTrack(newTrack);
                if ( !currentArtist?.id ) {
                   console.log('no artist')
                    const artist = newTrack.artists && newTrack.artists.find(item => !item.isHidden);
                    artist && setCurrentArtist(artist)
                    getArtistTracks(artist, newTrack)
                } else {
                   console.log('current Artist -> ', currentArtist.id)
                    if ( newTrack.artists && newTrack.artists.find(item => item.id === currentArtist?.id) ) {
                        // երգը փոխվել է (search - անելով) ընթացիկ արտիստի ՈՉ ԹՈՓ այլ երգով
                       console.log('artist dont change')
                        setVideoIdList([...videoIdList, {
                            videoId: getYoutubeId(newTrack?.youtubeUrl),
                            trackId: newTrack?.id
                        }]);
                        setTracksList([...tracksList, newTrack]);
                    } else {
                        // երգը փոխվել է (search - անելով) այլ արտիստի երգով
                        const newArtist = newTrack.artists.find(item => !item.isHidden)
                        newArtist && setCurrentArtist(newArtist)
                       console.log('artist changed, new Artist -> ', newArtist?.id)
                        if ( newArtist ) {
                            getArtistTracks(newArtist, newTrack)
                        } else {
                            setTracksList([newTrack])
                            setVideoIdList([{
                                videoId: getYoutubeId(newTrack?.youtubeUrl),
                                trackId: newTrack?.id
                            }])
                        }
                    }
                }
            }).catch((err)=>{
                console.log('err',err)
                history.push('/404')
            });
        }

        return function cleanup(){
            props.ClearTracks();
        };


    }, [params.slug]);

    const getArtistTracks = (artist, currentTrack) => {
        props.GetTracks({
            artist: artist?.id,
            isTop: !!params.top,
            getResponse: true
        }).then((res) => {
            const resTracks = [...res.data];
            if ( !resTracks.find(tr => tr.id === currentTrack.id) ) {
                resTracks.push(currentTrack);
            }
            setTracksList(resTracks);

            let videoList = [];
            resTracks.forEach(item => {
                videoList.push({
                    videoId: getYoutubeId(item?.youtubeUrl),
                    trackId: item?.id
                });
            });
            setVideoIdList(videoList)
            setLoading(false)
        });
    }

    const toggleFavoriteState = (id, favorite) => {
        setTracksList(tracksList.map(track => track.id === id ?
            {
                ...track,
                isFavorite: favorite
            } : track
        ))
    };


    const loadMoreItems = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetTracks({
                reset: false,
                offset: tracks?.list?.length,
                artist: props?.location?.state?.track?.artistId,
                isTop: !!params.top,
            }).finally(() => {
                setFetching(false);
            });
        }
    };

    const setRedirectUrl = (id) => {
        return `/track/${id}/tops`
    };

    return <PageWrapper>
        <MetaTags title={track?.title}
                  image={generateImageMediaUrl(track?.coverImage?.path)}
                  youTubeUrl={track?.youtubeUrl}/>
        <div className="main-img-wrapper">
            {!!videoIdList?.length &&
            <YouTubePlayer videoIds={videoIdList}
                           setRedirectUrl={setRedirectUrl}
                           paused={paused}
                           pausedVideo={setPaused}/>}
        </div>
        <div className="track-name">{!loading ? track?.title : <SkeletonItem/>}</div>

        {!loading ? (!!track?.artists?.length ? track?.artists?.map((item, index) => {
            return !item?.isHidden ? <Link to={`/artist/${item?.slug}/songs`} key={item?.id}>
                <div className="track-artist-name track-artist">
                    {item?.fullName}
                    {(index + 1) < track?.artists?.length && <span>,</span>}
                </div>
            </Link> : <div key={item?.id} className="track-artist-name">
                {item?.fullName}
                {(index + 1) < track?.artists?.length && <span>,</span>}
            </div>
        }) : <div className="track-artist-name">{track?.description}</div>) :
            <div className="track-artist-name"><SkeletonItem/></div>
        }

        {!loading ?
            (!!tracksList?.length && <div className="artist-top-song-list">
            <div className="section-header">{!!params.top && <span>Top songs</span>}</div>
            <InfiniteScroll
                hasMore={tracks?.list?.hasMore}
                loadMore={loadMoreItems}
                className={'tracks-list'}
                pageStart={0}
                useWindow={true}
                initialLoad={false}>
                {
                    tracksList?.map(item => {
                        return <TrackCard key={item.id}
                                          isTop={!!params.top}
                                          redirectUrl={`/track/${item?.slug}/tops`}
                                          track={item}
                                          paused={paused}
                                          toggleFavoriteState={toggleFavoriteState}/>
                    })
                }
            </InfiniteScroll>
        </div>) : <SkeletonTrackCard count={3}/>
        }

    </PageWrapper>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'tracks',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    GetTracks,
    ClearTracks,
    GetTrackById,
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackDetails);
