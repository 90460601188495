// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";

//Import components
import {SeeAll} from "../../components/uIElements/buttons";
import TrackCard from "../../components/cards/TrackCard";
import SkeletonTrackCard from "../../components/skeletonCards/SkeletonTrackCard";

//import utils
import {GetTracks, ClearTracks} from "../../redux/actions/tracksActions";
import {getPropsFromState} from "../../redux/mapStateToProps";

function Tracks(props) {
    const location = useLocation();
    const inGenres = location.pathname.includes('genres');
    const path = inGenres && location.pathname.replace('genres', 'tracks/genres');
    useEffect(() => {

    }, []);

    const {data, loading} = props;
    // console.log("dataTracks", data)
    const tracksList = window.innerWidth > 1100 ? data?.slice(0, props.genreTracks ? 6 : 10) : data?.slice(0, props.genreTracks ? 3 : 5)

    return <div className="homepage-tracks-wrapper">
        <div className='section-title'>
            <span>New Songs</span>
            {data?.length > 10 && <SeeAll link={`${inGenres ? path : `/home/tracks`}`}/>}
        </div>
        {!loading ? (!!tracksList?.length && <div className="tracks-list">
            {
                tracksList?.map(track => {
                    return <TrackCard key={track.id}
                                      toggleFavoriteState={props.toggleFavoriteState}
                                      track={track}
                                      playerTracksList={tracksList}
                    />
                })
            }
        </div>) : <SkeletonTrackCard count={props.genreTracks ? 3 : 5}/>}
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'tracks',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    GetTracks,
    ClearTracks
};

export default connect(mapStateToProps, mapDispatchToProps)(Tracks);
