import {PLAYER_CONSTS, TRACKS_CONSTS} from "../constants";
import {store} from "../store";

export const setCurrentPlayedTrack = (track = null, tracksList, type, index, shuffledList = []) => {
    // console.log('setCurrentPlayedTrack');
    store.dispatch({
        type: PLAYER_CONSTS.SET_CURRENT_PLAYED_TRACK,
        payload: {
            track,
            tracksList,
            type,
            index,
            shuffledList
        }
    })
};

export const togglePlayState = (state) => {

    store.dispatch({
        type: PLAYER_CONSTS.TOGGLE_PLAY_STATE,
        payload: state
    })
};

export const getShufflesList = () => {
    return dispatch => {
        dispatch({
            type: PLAYER_CONSTS.GET_SHUFFLED_LIST,
            // payload: state
        })
    }
};

export const toggleShuffle = (state) => {

    // store.dispatch({
    //     type: PLAYER_CONSTS.TOGGLE_SHUFFLED,
    // })
    return dispatch => {
        dispatch({
            type: PLAYER_CONSTS.TOGGLE_SHUFFLED,
            payload: state
        })
    }
};

export const clearCurrentTrack = () => {
    return dispatch => {
        dispatch({
            type: TRACKS_CONSTS.CLEAR_CURRENT_TRACK,
        })
    }
};
