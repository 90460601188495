import React, { PureComponent } from 'react';

import { Helmet } from "react-helmet";

import { defaultTexts } from "../constants/defaultTexts";
import { FB_APP_ID } from "../constants/constants";

const logo = 'https://api.youtop.app/resources/images/youtop.png?height=200&width=200'
class MetaTags extends PureComponent {

    getYoutubeFirstSecondImage(url = '') {
        let regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        let match = url.match(regExp);
        if (match && match[2] && match[2].length === 11) {
            return `https://img.youtube.com/vi/${match[2]}/sddefault.jpg`;
        } else {
            return logo;
        }
    }

    render() {
        const {title, description, image, url, youTubeUrl} = this.props

        const _title = title ? (`${title} | ${defaultTexts.title}`) : defaultTexts.title;
        const _description = description || defaultTexts.description;
        let _image = logo;
        if ( image ) {
            _image = image;
        } else if ( youTubeUrl ) {
            _image = this.getYoutubeFirstSecondImage(youTubeUrl);
        }
        const _url = url || window.location.href;
        const {hostname, port, protocol, origin} = window.location;
        const BASE_URL=origin;
        return (<Helmet>
                <title>{_title}</title>
                <meta name="description" content={_description}/>
                <meta property="og:image" content={_image}/>

                <meta property="og:title" content={_title}/>
                <meta property="og:site_name" content='youtop.app'/>
                <meta property="og:description" content={_description}/>

                <meta property="og:url" content={_url}/>
                <meta property="og:image:width" content={600}/>
                <meta property="og:image:height" content={600}/>
                <meta property="og:type" content={'website'}/>
                <meta property="fb:app_id" content={FB_APP_ID}/>

                <link rel="manifest" href={`${BASE_URL}/manifest.json`}/>
                <link rel="shortcut icon" href={`${BASE_URL}/static/favicon.ico`}/>

            </Helmet>
        )
    }
}

export default MetaTags;
//  {newImage &&  <meta property="og:image" content={newImage}/>}
