// import packages
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { history } from "../../configs/history";

// import assets
import '../../assets/styles/components/cards/track-card.scss'
import { ReactComponent as MoreIcon } from "../../assets/images/icons/ic_more.svg";
import { ReactComponent as PlayIcon } from "../../assets/images/icons/ic_play.svg";
import { ReactComponent as EmptyHeart } from "../../assets/images/icons/ic_heart_empty.svg";
import { ReactComponent as FillHeart } from "../../assets/images/icons/ic_heart_fill.svg";
import { ReactComponent as ContentWarning } from "../../assets/images/icons/ic_content_warning.svg";

//import utils
import { AddToFavorites, RemoveFromFavorites, setCurrentPlayedTrack, togglePlayState } from "../../redux/actions";
import { getPropsFromState } from "../../redux/mapStateToProps";

//import components
import { LinkButton } from "../uIElements/buttons";
import TrackMoreCard from "./TrackMoreCard";
import { Image } from "../uIElements/Image";
import { TRACK_TYPES } from "../../constants/constants";
import moment from "moment";
import TrackOwnerActionsCard from "./TrackOwnerActionsCard";


function TrackCard (props) {
    const {
        track, playerTracksList, index, indexInPlayerList, isLoggedIn, shuffledTracksList, path,
        modifyActions, podcastId,
    } = props;
    const [isFetching, setFetching] = useState(false);
    const [trackActionsCardOpen, setTrackActionsCardOpen] = useState(false);
    const location = useLocation();

    function toggleFavorite (e) {
        e.preventDefault();
        e.stopPropagation();
        if (isLoggedIn) {
            if (!isFetching) {
                setFetching(true);
                const favoritePromise = track.isFavorite ? props.RemoveFromFavorites(track.id) : props.AddToFavorites(track);
                favoritePromise.finally(() => {
                    setFetching(false);
                    props.toggleFavoriteState && props.toggleFavoriteState(track.id, !track.isFavorite)
                })
            }
        }
    }

    const toggleTrackActionsCard = () => {
        setTrackActionsCardOpen(!trackActionsCardOpen)
    };

    const onClickTrack = () => {
        const { currentPlayedTrack } = props;

        if (currentPlayedTrack?.id !== track.song?.id) {
            setCurrentPlayedTrack(track, playerTracksList, location?.pathname, indexInPlayerList, shuffledTracksList);
            togglePlayState(true);
        }

    };

    function navigateToPage (e, route) {
        e.preventDefault();
        e.stopPropagation();
        history.push({
            pathname: route,
        })
    }

    function getDuration (duration) {
        if (track.type === TRACK_TYPES.TRACK) {
            let min = Math.floor(duration / 60);
            let sec = Math.floor(duration % 60) > 9
                ? Math.floor(duration % 60) : `0${Math.floor(duration % 60)}`;

            return min + ':' + sec
        } else {
            let hour = Math.floor(duration / 3600);
            let d = duration % 3600;
            let min = Math.floor(d / 60);
            if (min >= 60) {
                hour = Math.floor(min / 60);
                min = min % 60;
            }
            let sec = Math.floor(d % 60) > 9
                ? Math.floor(d % 60) : `0${Math.floor(d % 60)}`;
            return (!hour && !min) ? `${sec}s` : (hour ? `${hour}h ` : '') + `${min}m`;
        }
    }

    return <div className="track-item" key={track?.id}>
        {
            index ? <div className="track-index-wrapper">
                    <span onClick={onClickTrack}>
                        <button className="play-btn">
                            <span className={'index-item'}>{index < 10 ? "0" + index : index}</span>
                            <PlayIcon title={''} className="play-icon"/>
                        </button>
                    </span>
                </div>
                :
                <div className="img-wrapper" onClick={onClickTrack}>
                    <Image url={path ? path : track?.coverImage?.path}/>
                    {(props.currentPlayedTrack?.id === track?.id) && <>
                        {props?.playState ? <div className="bars">
                                <div className="bar"/>
                                <div className="bar"/>
                                <div className="bar"/>
                                <div className="bar"/>
                            </div>
                            : <div className="play-icon-wrapper" onClick={onClickTrack}>
                                <PlayIcon title={''} className="play-icon"/>
                            </div>}
                    </>}
                </div>
        }

        <div className="track-item-content"
             onClick={onClickTrack}>
            <div className="track-title-wrapper">
                <span className="track-item-title">{track?.title}<span
                    className="track-subTitle"> {track?.subTitle}</span></span>
                <div className="e-icon">{track?.contentWarning && <ContentWarning/>}</div>
            </div>
            <div className="track-item-description">

                {track.type === TRACK_TYPES.TRACK ?
                    <>
                        {!!track?.artists?.length && track?.artists?.map((item, index) => {
                            return !item?.isHidden ?
                                <span onClick={(e) => navigateToPage(e, `/artist/${item?.slug}/songs`)}
                                      className="track-artist track-item-description"
                                      key={item.id}>
                            {item?.fullName}
                                    {index + 1 < track?.artists?.length && <span>,&nbsp;</span>}
                    </span>

                                : <span className="track-item-description"
                                        key={item.id}>
                            {item?.fullName}
                                    {index + 1 < track?.artists?.length && <span>,&nbsp;</span>}
                        </span>
                        })}
                    </> : <span className={'podcast-duration'}>

                    {getDuration(track?.song?.duration)}
                        <span>●</span>
                        {moment(track?.createdAt).format("DD MMM YYYY")}
                    </span>
                }
            </div>

        </div>
        {track?.song?.duration && track.type === TRACK_TYPES.TRACK && <div className="track-duration">
            {getDuration(track?.song?.duration)}
        </div>}
        <div className="heart-dropdown-wrapper" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
        }
        }>
            <div className="heart-wrapper">
                {track?.isFavorite ?
                    <FillHeart className='card-heart fill-heart' title='' onClick={toggleFavorite}/>
                    : <EmptyHeart className='card-heart' title='' onClick={toggleFavorite}/>}
            </div>
            {!isLoggedIn && <div className={`heart-dropdown-content`}>
                <span>Please sign in to save your favorites</span>
                <LinkButton title="Sign in"
                            link={"/sign-in"}
                />
            </div>
            }
        </div>
        <div className="track-dropdown-wrapper">
            <MoreIcon title={''} className={'more-icon'} onClick={toggleTrackActionsCard}/>
            {trackActionsCardOpen && (modifyActions ? <TrackOwnerActionsCard
                        track={track}
                        podcastId={podcastId}
                        trackActionsCardOpen={trackActionsCardOpen}
                        toggleTrackActionsCard={toggleTrackActionsCard}
                    />
                    :
                    <TrackMoreCard toggleFavorite={toggleFavorite}
                                   track={track}
                                   trackMoreCardOpen={trackActionsCardOpen}
                                   toggleTrackMoreCard={toggleTrackActionsCard}
                                   removeFromPlaylist={props.removeFromPlaylist}
                                   addToPlaylist={props.addToPlaylist}
                                   podcastId={podcastId}
                                   indexInPlayerList={indexInPlayerList}
                    />
            )}
        </div>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'isLoggedIn',
    'type',
    'shuffledTracksList',
    'playState',
    'currentPlayedTrack'
]);

const mapDispatchToProps = {
    AddToFavorites,
    RemoveFromFavorites,
    setCurrentPlayedTrack,
    togglePlayState
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackCard);
