// Import packages
import React from "react";
import {store} from "../../redux/store";

// Import utils
import {history} from "../../configs/history";

// Import styles
import "../../assets/styles/components/uiElements/buttons.scss";


export function SeeAll(props) {
    const {className, link, cb, loading, disabled} = props;
    return <button className={`see-all-btn ${className ? className : ''}`}
                   disabled={disabled || false}
                   onClick={() => {
                       if (link) {
                           history.push(link)
                       } else {
                           if (!loading && cb) {
                               cb();
                           }
                       }
                   }}>
        See All
    </button>
}

export function LinkButton(props) {
    const {title, className, link, cb, loading, disabled, progress} = props;
    return <button className={`link-button ${className ? className : ''} ${progress ? 'show-progress' : ""}`}
                   disabled={disabled || false}
                   onClick={() => {
                       if (link) {
                           history.push(link)
                       } else {
                           if (!loading && cb) {
                               cb();
                           }
                       }
                   }}>
        {
            progress ? <div className={'percent'}>
                {progress} %
            </div> : <span className="title">{title}</span>
        }
    </button>
}

export function AutorisationButton(props) {
    const {title, className, onClick, disabled, imagePath, svg} = props;
    return <button className={`link-button ${className ? className : ''}`}
                   disabled={disabled || false}
                   onClick={onClick}>
        <>
            {imagePath && <img src={imagePath} className="autorisation-button-image" alt={'autorisation'}/>}
            {svg && svg}
            <span className="title">{title}</span>
        </>

    </button>
}

export function SliderButton(props) {
    const {className, cbPrev, cbNext, disabledPrev, disabledNext} = props;
    return <div className={`arrows ${className ? className : ""}`}>
        {!disabledPrev && <div className="prev-btn" onClick={cbPrev}>
            <img src={require('../../assets/images/icons/ic_prev.svg')}
                 className={'prev-icon'} alt=""/>
        </div>}
        {!disabledNext && <div className="next-btn" onClick={cbNext}>
            <img src={require('../../assets/images/icons/ic_prev.svg')}
                 className={'next-icon'} alt=""/>
        </div>}
    </div>
}