// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

//Import components
import SquareSkeletonCard from "../../components/skeletonCards/SquareSkeletonCard";
import {CardComponents} from "../../components/cards/BaseCard";

//import utils
import {GetArtists, CleaArtists} from "../../redux/actions";
import {getPropsFromState} from "../../redux/mapStateToProps";
import InfiniteScroll from "react-infinite-scroller";

function ArtistsTab(props) {
    const {data, loading} = props;
    const [isFetching, setFetching] = useState(false);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    const setSkeletonCount = () => {
        if (window.innerWidth > 800) {
            return 8
        } else {
            return 6
        }
    }
    const loadMoreArtists = () => {
        if (!isFetching) {
            setFetching(true);
            props.GetArtists({
                reset: false,
                offset: data?.list?.length
            })
                .finally(() => {
                setFetching(false);
            });
        }
    };

return <div className="homepage-artists-wrapper">
        <div className='section-title'>
            <span>Favorite Artists</span>
        </div>
        {!loading ? (!!data.list?.length &&
                <InfiniteScroll
                hasMore={data?.hasMore}
                loadMore={loadMoreArtists}
                className={'base-cards-list'}
                pageStart={0}
                useWindow={true}
                initialLoad={false}>
                {
                    data?.list?.map(artist => {
                        return <CardComponents.ArtistCard artist={artist} key={artist.id}/>
                    })
                }
            </InfiniteScroll>)
            : <div className={'base-cards-list circle-cards'}>
            <SquareSkeletonCard count={setSkeletonCount()}/>
        </div>}
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'artists',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    GetArtists,
    CleaArtists
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtistsTab);