import { IMAGE_ACCEPT_TYPES, SONG_ACCEPT_TYPES } from "../constants/acceptedConsts";

export const hasImageExtension = fileName => {
    const pattern = '(' + IMAGE_ACCEPT_TYPES.join('|').replace(/\./g, '\\.') + ')$';
    return new RegExp(pattern, 'i').test(fileName);
};

export const hasSongExtension = fileName => {
    const pattern = '(' + SONG_ACCEPT_TYPES.join('|').replace(/\./g, '\\.') + ')$';
    return new RegExp(pattern, 'i').test(fileName);
};
