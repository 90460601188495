// import packages
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { history } from "../../configs/history";

//import utils
import { AddToListened } from "../../redux/actions";
import { getPropsFromState } from "../../redux/mapStateToProps";


class YouTubePlayer extends Component {

    constructor(props){
        super(props);
        this.onPlayerStateChange = this.onPlayerStateChange.bind(this);
        this.timer = this.timer.bind(this);
        this.currentCount = 0
    }

    componentDidMount(){
        const { videoIds } = this.props;
        const params = this.props?.match?.params;
        let videoId = videoIds.find(item => item.trackId === (params.trackId || params.id))?.videoId;
       // console.group('player mounted');
       // console.log('trackId', params.trackId);
       // console.log('videoIds', videoIds);
       // console.log('videoId', videoId);
       console.groupEnd()

        if ( window.youTubePlayerIsLoaded ) {
            // console.log('youTubePlayerIsLoaded');
            this.intervalId = setInterval(this.timer, 1000);
            window.player = new window.YT.Player('player', {
                videoId: videoId,
                playerVars: {
                    autoplay: 1,
                    enablejsapi: 1,
                    origin: 'https://youtop.app'
                },
                events: {
                    onReady: this.onPlayerReady,
                    onStateChange: this.onPlayerStateChange,
                    onError: this.onError,
                }
            });
        } else {
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/player_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            window.onYouTubePlayerAPIReady = () => {
                window.player = new window.YT.Player('player', {
                    videoId: videoId,
                    playerVars: {
                        autoplay: 1,
                        enablejsapi: 1,
                        origin: 'https://youtop.app'
                    },
                    events: {
                        onReady: this.onPlayerReady,
                        onStateChange: this.onPlayerStateChange,
                    }
                });
                window.youTubePlayerIsLoaded = true;
            };
        }
    }

    onPlayerReady = (event) => {
        // const { videoIds } = this.props;
        // const params = this.props?.match?.params;
        // console.log(event.target.getPlayerState())
        // let videoId = videoIds.find(item => item.trackId === (params.trackId || params.id))?.videoId;
        // console.log('onPlayerReady', videoId);
        // videoId && window.player.loadVideoById({
        //     videoId: videoId,
        // });
    }

    onError(event){
        console.log('onError', event)
    }

    timer(){
        const params = this.props?.match?.params;
       // console.log('in timer, this.currentCount->', this.currentCount);
        if ( this.currentCount === 5) {
            clearInterval(this.intervalId);
            this.currentCount = -1;
            this.props.AddToListened(params.trackId);
        } else {
            this.currentCount = this.currentCount + 1
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        const params = this.props?.match?.params;
        // console.log('player updated, videoIds -> ', this.props.videoIds)
        if ( params.trackId !== prevProps.match.params.trackId ) {
          // console.group('player updated');
          // console.log('track Id changed', params?.trackId)
          // console.log('prev trackId', prevProps.match.params.trackId);

            // console.log(this.props.videoIds, "IDS")
            let videoId = this.props.videoIds.find(item => item.trackId === (params.trackId || params.id))?.videoId;
            videoId && window.player && window.player.loadVideoById && window.player.loadVideoById({
                videoId: videoId,
            });

            this.currentCount = 0;
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
        if ( this.props.videoIds !== prevProps?.videoIds ) {
            // console.log('video Ids changed')
            let videoId = this.props.videoIds.find(item => item.trackId === (params.trackId || params.id))?.videoId;
            videoId && window.player && window.player.loadVideoById && window.player.loadVideoById({
                videoId: videoId,
            });

            this.currentCount = 0;
            clearInterval(this.intervalId);
            this.intervalId = null;
        }

        // if(this.props.paused !== prevProps.paused){
        //     console.log('paused state changed')
        //     if(this.props.paused){
        //         // console.log('IFFF');
        //         window.player.pauseVideo();
        //         clearInterval(this.intervalId);
        //         // this.props.pausedVideo(true);
        //     }else {
        //         // console.log('else');
        //         window.player.playVideo();
        //         // this.props.pausedVideo(false);
        //     }
        // }
        console.groupEnd()
    }

    componentWillUnmount(){
        clearInterval(this.intervalId);
        this.currentCount = -1
    }

    onPlayerStateChange(event){
        const { videoIds, setRedirectUrl } = this.props;
        const { params } = this.props.match;

        // console.log(window.YT.PlayerState, "window.YT.PlayerState");
        // console.log('PlayerStateChange', event.data);

        if ( event.data === window.YT.PlayerState.ENDED ) {

            let videoIndex = videoIds.findIndex(item => item.trackId === params.trackId);
            const newIndex = (videoIndex + 1) % videoIds.length;

            let url = setRedirectUrl(videoIds[newIndex].trackId);
            videoIds.length > 1 && history.push({
                pathname: url,
            })
        }

        if ( event.data === window.YT.PlayerState.PAUSED ) {
            this.props.pausedVideo && this.props.pausedVideo(true)
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
        if ( event.data === window.YT.PlayerState.PLAYING ) {
            this.props.pausedVideo && this.props.pausedVideo(false);

            if ( this.currentCount <= 5 && this.currentCount !== -1 && !this.intervalId ) {
                this.intervalId = setInterval(this.timer, 1000);
            }
        }
    }


    render() {
        return <div id="player"/>
    }
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'isLoggedIn',
]);

const mapDispatchToProps = {
    AddToListened,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(YouTubePlayer));
