import { _urlFile, _urlMedia, _urlPodcasts, _urlTracks, request } from "../api";
import {PODCAST_CONSTS} from "../constants";

export const GetUserPodcasts = () => {
    const requestData = {
        url: `${_urlPodcasts}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: PODCAST_CONSTS.GET_PODCASTS,
                    payload: data,
                });
                return data;
            })
            .catch(err => {
            })
    };
};

export const AddPodcast = (data) => {
    const requestData = {
        url: `${_urlPodcasts}`,
        method: "POST",
        token: true,
        data
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: PODCAST_CONSTS.ADD_PODCAST,
                    payload: data,
                });
                return data
            })
    };
};

export const DeletePodcast = (id) => {
    const requestData = {
        url: `${_urlPodcasts}/${id}`,
        method: "DELETE",
        token: true,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                console.log("action", id)
                dispatch({
                    type: PODCAST_CONSTS.DELETE_PODCAST,
                    payload: id,
                });
            })
    };
};

export const GetUserPodcastTracks = (id) => {
    const requestData = {
        url: `${_urlPodcasts}/${id}/tracks`,
        method: "GET",
        token: true,
        languageFlag: true,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: PODCAST_CONSTS.GET_PODCAST_TRACKS,
                    payload: data,
                });
            })
            .catch(err => {
            })
    };
};

export const AddTrack = (podcastId, data ) => {
    const requestData = {
        url: `${_urlTracks}/podcast/${podcastId}`,
        method: "POST",
        token: true,
        data,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                dispatch({
                    type: PODCAST_CONSTS.ADD_TRACK,
                    payload: {podcastId, data }
                });
            })
    };
};

export const RemoveTrack = (podcastId, trackId) => {
    const requestData = {
        url: `${_urlPodcasts}/${podcastId}/tracks/${trackId}`,
        method: "DELETE",
        token: true,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                dispatch({
                    type: PODCAST_CONSTS.REMOVE_TRACK_FROM_PODCAST,
                    payload: {
                        podcastId, trackId
                    }
                });
            })
    };
};

export const UpdatePodcastTracksPositions = (id, data) => {
    const requestData = {
        url: `${_urlPodcasts}/${id}`,
        token: true,
        method: "PATCH",
        data: {
            tracks: data,
        },
    };
    return dispatch => {
        return request(requestData)
    }
};

export const UpdatePodcast = (id, data) => {
    const requestData = {
        url: `${_urlPodcasts}/${id}`,
        token: true,
        method: "PATCH",
        data
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: PODCAST_CONSTS.UPDATE_PODCAST,
                    payload: {
                        id,
                        data,
                    }
                });
            })
    }
};

export const UploadPodcastPicture = (formData) => {
    const requestData = {
        url: `${_urlMedia}/podcast-picture`,
        token: true,
        method: "POST",
        data: formData,
        customHeaders: {
            "Content-Type": "multipart/form-data",
        }
    };
    return request(requestData)
        .then((res) => {
            return res?.data;
        })
        .catch(() => null)

};

export const UploadTrackSong = (podcastListId, formData, onUploadProgress = null) => {
    const requestData = {
        url: `${_urlFile}/podcast/${podcastListId}`,
        token: true,
        method: "POST",
        data: formData,
        onUploadProgress: onUploadProgress,
        customHeaders: {
            "Content-Type": "multipart/form-data",
        }
    };
    return request(requestData)
        .then((res) => {
            return res?.data;
        })
        .catch(() => null)

};


export const GetPodcastsList = () => {
    const requestData = {
        url: `${_urlPodcasts}/all`,
        method: "GET",
        token: true,
        languageFlag: true,
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: PODCAST_CONSTS.GET_PODCASTS_LIST,
                    payload: data,
                });
            })
            .catch(err => {
            })
    };
};

export const GetPodcastsListTrack = (id) => {
    const requestData = {
        url: `${_urlPodcasts}/tracks/all/${id}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;

                data && dispatch({
                    type: PODCAST_CONSTS.GET_PODCASTS_LIST_TRACK,
                    payload: data,
                });
            })
            .catch(err => {
            })
    };
};

export const GetPodcastById = (id) => {
    const requestData = {
        url: `${_urlPodcasts}/all/${id}`,
        method: "GET",
        languageFlag: true,
        token: true
    };
    return () => {
        return request(requestData)
    };
};